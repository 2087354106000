// Получаем время приготовления всех блюд в заказе в пользовательском формате "ЧЧ ч ММ мин СС сек".
export function convertingSecondsToTimeInCustomFormat(seconds) {
  if (seconds === null) {
    return ''
  } else {
    let user_hours = Math.floor(seconds / 3600)
    let user_minutes = Math.floor((seconds % 3600) / 60)
    let user_seconds = Math.floor((seconds % 3600) % 60)

    let hoursDisplay =
      user_hours > 0 ? user_hours.toString().padStart(2, '0') + ' ч ' : ''
    let minutesDisplay =
      user_minutes > 0 ? user_minutes.toString().padStart(2, '0') + ' мин ' : ''
    let secondsDisplay =
      user_seconds > 0 ? user_seconds.toString().padStart(2, '0') + ' сек ' : ''
    return hoursDisplay + minutesDisplay + secondsDisplay
  }
}
