import { store } from '@/store'
import UsersDataService from '@/services/UsersDataService'

// access токен хранится в cookie через store.
const getAccessToken = () => store.getters.accessToken

// Флаг skipAuth понадобится нам позднее,
// чтобы интерцептор игнорировал запросы на получение токенов,
// иначе попадем в рекурсию.
async function login({ email, password }) {
  let data

  await UsersDataService.signIn(email, password).then(response => {
    data = response.data
  })

  return data
}

// refresh токен хранится в cookie через store.
async function refreshToken(refresh) {
  const { data } = await UsersDataService.refreshToken(refresh)

  return data
}

export { login, refreshToken, getAccessToken }
