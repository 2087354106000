import { createApp } from 'vue'
import routers from '@/_helpers/routes'
import App from '@/App.vue'

import exe_axios from '@/_helpers/axios'
import axiosSetUp from '@/_helpers/interceptors.js'
import { store } from '@/store'

import ElementPlus from 'element-plus'
import ru from 'element-plus/es/locale/lang/ru'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import messagePlugin from './utils/message.plugin'
import '@/assets/scss/main.scss'

// Регистрируем axios interseptors.
axiosSetUp()

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, {
  locale: ru
})
app.use(store)
app.use(routers)
app.use(messagePlugin)

app.config.globalProperties.$axios = exe_axios
app.mount('#app')
