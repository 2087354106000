import exe_axios from '@/_helpers/axios'

class DishIngredientDataService {
  create(data, params) {
    return exe_axios.post('v1/dish-ingredient/', data, params)
  }

  update(data, id) {
    return exe_axios.patch(`v1/dish-ingredient/${id}/`, data)
  }
}

export default new DishIngredientDataService()
