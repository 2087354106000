<template>
  <OrdersBlock
    :current_language="current_language"
    :localizationData="localizationData"
    @openBlockWithDetailedOrderData="openBlockWithDetailedOrderData"
  />
</template>

<script>
import OrdersBlock from './OrdersBlock'

export default {
  props: [
    'current_language',
    'localizationData',
    'nameActiveTheme',
    'isCollapse'
  ],

  components: {
    OrdersBlock: OrdersBlock
  },

  mounted() {},

  data() {
    return {}
  },

  methods: {
    openBlockWithDetailedOrderData(order) {
      console.log('openBlockWithDetailedOrderData order', order)
    }
  }
}
</script>
