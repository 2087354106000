<template>
  <div class="main-block-wrapper dish-edit-page-wrapper">
    <!-- Заголовок блока редактирования. -->
    <editBlockHeader
      :editBlockHeaderLinkButton="'Все меню'"
      :editBlockHeaderTitle="editBlockHeaderTitle"
      @toAllObjects="toAllMenus"
    />

    <div class="menu-edit-block-sub-header">
      <div class="edit-menu-name-block">
        <div class="edit-menu-title-wrapper edit-menu-title-inline-block">
          <div
            v-if="currentEditObject && !inputTitleIsFocused"
            class="edit-menu-title-input-label"
          >
            <span class="edit-menu-title-input-label-inner">
              {{ currentEditMenu.name }}
            </span>
          </div>

          <input
            v-model="currentEditMenu.name"
            class="edit-menu-title-input"
            :style="inputTitleStyle"
            type="text"
            style="visibility: visible"
            @focus="inputTitleIsFocused = true"
            @blur="inputTitleBlur"
            @keydown.enter="keydownEnterOnInput($event)"
          />
          <div ref="fakeDiv" class="fake_div">
            <a>{{ currentEditMenu.name }}</a>
          </div>
        </div>
      </div>

      <el-button
        class="black-fill-button"
        size="large"
        @click="openModalCreateMenuSection"
      >
        <span>+ Создать секцию меню</span>
      </el-button>
    </div>

    <!-- Тело блока редактирования. -->
    <div class="edit-block-body">
      <!-- Тело блока редактирования меню. -->
      <div class="edit-block-body-sections-wrapper">
        <div class="edit-block-body-sections-block">
          <!-- <el-scrollbar max-height="500px"> -->
          <!-- Блок drag-n-drop разделов меню. -->
          <!-- <div class="edit-block-menu-section-wrapper"> -->
          <Container
            @drop="onDropMenuSection"
            lock-axis="y"
            drag-handle-selector=".section-item-icon-drag"
          >
            <Draggable
              v-for="(
                menu_dash_menu_section, index
              ) in currentEditMenu.menu_dash_menu_sections"
              :key="index"
            >
              <div class="edit-block-menu-section">
                <div class="edit-block-menu-section-header">
                  <div
                    class="section-header-content-block"
                    :class="{
                      is_disable:
                        menu_dash_menu_section.menu_section.menu_section_dishes
                          .length === 0
                    }"
                    @click="
                      showDishesOfSection(
                        index,
                        menu_dash_menu_section.menu_section.menu_section_dishes
                          .length
                      )
                    "
                  >
                    <el-icon
                      :size="20"
                      class="section-header-arrow-down"
                      :class="{
                        is_rotate:
                          menu_dash_menu_section.id === currentShowSectionId &&
                          menu_dash_menu_section.menu_section
                            .menu_section_dishes.length > 0,
                        is_disable:
                          menu_dash_menu_section.menu_section
                            .menu_section_dishes.length === 0
                      }"
                    >
                      <ArrowDown />
                    </el-icon>

                    <div class="section-header-title">
                      <div
                        class="edit-menu-section-title-wrapper edit-menu-section-title-inline-block"
                        @click.stop
                      >
                        <div
                          v-if="
                            menu_dash_menu_section.menu_section.id !==
                            currentDataOfMenuSectionWhereEditName.id
                          "
                          class="edit-menu-section-title-input-label"
                        >
                          <span
                            class="edit-menu-section-title-input-label-inner"
                          >
                            {{
                              menu_dash_menu_section.menu_section.name
                                .charAt(0)
                                .toUpperCase() +
                              menu_dash_menu_section.menu_section.name.slice(1)
                            }}
                          </span>
                        </div>

                        <input
                          v-model="currentDataOfMenuSectionWhereEditName.name"
                          class="edit-menu-section-title-input"
                          :style="{
                            width:
                              menu_dash_menu_section.menu_section.nameWidth +
                              25 +
                              'px'
                          }"
                          type="text"
                          style="visibility: visible"
                          @focus="saveDataOfMenuSectionWhereEditName(index)"
                          @blur="inputSectionNameBlur"
                          @keydown.enter="keydownEnterOnInput($event)"
                        />

                        <div
                          :ref="`fakeDivSectionName_${index}`"
                          class="fake_div_section_name"
                        >
                          <a>
                            {{
                              menu_dash_menu_section.menu_section.name
                                .charAt(0)
                                .toUpperCase() +
                              menu_dash_menu_section.menu_section.name.slice(1)
                            }}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="table-row-description">
                      |&nbsp;&nbsp;&nbsp;
                      {{
                        getNumberOfDishes(
                          menu_dash_menu_section.menu_section
                            .menu_section_dishes.length
                        )
                      }}
                    </div>
                  </div>

                  <div class="section-item-icon-buttons">
                    <el-tooltip
                      placement="bottom"
                      effect="light"
                      popper-class="base-custom"
                      :show-after="500"
                    >
                      <template #content>
                        <div>Добавить блюдо</div>
                      </template>
                      <el-icon
                        class="section-item-icon section-item-icon-delete"
                        :size="20"
                        @click="
                          openModalAddDish(
                            currentEditMenu.menu_dash_menu_sections[index]
                          )
                        "
                      >
                        <Plus />
                      </el-icon>
                    </el-tooltip>

                    <el-tooltip
                      placement="bottom-end"
                      effect="light"
                      popper-class="base-custom"
                      :show-after="500"
                    >
                      <template #content>
                        <div>Удалить секцию меню</div>
                      </template>
                      <el-icon
                        class="section-item-icon section-item-icon-delete"
                        :size="20"
                        @click="
                          openModalRemoveMenuSection(
                            currentEditMenu.menu_dash_menu_sections[index]
                          )
                        "
                      >
                        <Delete />
                      </el-icon>
                    </el-tooltip>

                    <el-tooltip
                      placement="bottom-end"
                      effect="light"
                      popper-class="base-custom"
                      :show-after="500"
                    >
                      <template #content>
                        <div>Переместить секцию меню</div>
                      </template>
                      <el-icon
                        class="section-item-icon section-item-icon-drag"
                        :size="20"
                        @mousedown="dragMenuSectionMouseDown"
                      >
                        <DragVertical />
                      </el-icon>
                    </el-tooltip>
                  </div>
                </div>

                <!-- Блок drag-n-drop блюд в секции меню. -->
                <div
                  class="section-dishes-wrapper"
                  :class="{
                    is_visible:
                      menu_dash_menu_section.id === currentShowSectionId
                  }"
                >
                  <Container
                    @drop="onDropDish"
                    lock-axis="y"
                    drag-handle-selector=".section-item-icon-drag"
                  >
                    <Draggable
                      v-for="(
                        menu_section_dish, index
                      ) in menu_dash_menu_section.menu_section
                        .menu_section_dishes"
                      :key="index"
                    >
                      <div class="section-dish-item">
                        <!-- функция из тега выше!!!! @click="saveCurrentEditRecipeStep(index)" -->
                        <div class="section-dish-item-content-block">
                          <img
                            v-if="menu_section_dish.dish.image"
                            :src="baseURL + menu_section_dish.dish.image"
                            class="section-dish-item-image"
                          />
                          <img
                            v-else
                            :src="require(`@/assets/images/no_photo.jpeg`)"
                            class="section-dish-item-image"
                          />

                          <div>
                            <div class="section-header-title">
                              {{
                                menu_section_dish.dish.name
                                  .charAt(0)
                                  .toUpperCase() +
                                menu_section_dish.dish.name.slice(1)
                              }}
                            </div>

                            <div class="table-row-description">
                              {{ menu_section_dish.dish.description }}
                              {{
                                getWeightOfDish(menu_section_dish.dish.steps)
                              }}
                            </div>
                          </div>
                        </div>

                        <!-- Цена блюда (в секции меню) -->
                        <div
                          class="menu-section-dish-price-and-buttons-wrapper"
                        >
                          <div
                            class="menu-section-dish-price-wrapper menu-section-dish-price-inline-block"
                            @click.stop
                          >
                            <div
                              class="menu-section-dish-price-input-label-inner-currency"
                            >
                              ₽
                            </div>

                            <div
                              v-if="
                                menu_section_dish.id !==
                                menuSectionDishWithAnEditablePrice.id
                              "
                              class="menu-section-dish-price-input-label"
                            >
                              <span
                                class="menu-section-dish-price-input-label-inner"
                              >
                                {{ menu_section_dish.price.replace('.', ',') }}
                              </span>
                            </div>

                            <el-input-number
                              v-model="menuSectionDishWithAnEditablePrice.price"
                              class="menu-section-dish-price"
                              :class="{
                                is_focus:
                                  menuSectionDishPriceInputIsFocus &&
                                  menu_section_dish.id ===
                                    menuSectionDishWithAnEditablePrice.id
                              }"
                              style="width: 120px"
                              placeholder="Укажите цену"
                              prefix-icon="Search"
                              :controls="false"
                              :precision="2"
                              validate-event
                              @keydown.enter="$event.target.blur()"
                              @focus="
                                saveMenuSectionDishWithAnEditablePrice(
                                  menu_section_dish
                                )
                              "
                              @blur="inputMenuSectionDishPriceBlur"
                            />
                          </div>

                          <div class="section-dish-item-icon-buttons">
                            <el-tooltip
                              placement="bottom-end"
                              effect="light"
                              popper-class="base-custom"
                              :show-after="500"
                            >
                              <template #content>
                                <div>Удалить блюдо из секции меню</div>
                              </template>

                              <el-icon
                                class="section-item-icon section-item-icon-delete"
                                :size="20"
                                @click="
                                  openModalRemoveDish(
                                    menu_dash_menu_section.menu_section
                                      .menu_section_dishes[index].id
                                  )
                                "
                              >
                                <Delete />
                              </el-icon>
                            </el-tooltip>

                            <el-tooltip
                              placement="bottom-end"
                              effect="light"
                              popper-class="base-custom"
                              :show-after="500"
                            >
                              <template #content>
                                <div>Переместить блюдо</div>
                              </template>

                              <el-icon
                                class="section-item-icon section-item-icon-drag"
                                :size="20"
                              >
                                <DragVertical />
                              </el-icon>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </Draggable>
                  </Container>

                  <div
                    class="section-dishes-wrapper-padding-bottom-block"
                    :class="{
                      is_visible:
                        menu_dash_menu_section.id === currentShowSectionId
                    }"
                  />
                </div>
              </div>
            </Draggable>
          </Container>
          <!-- </div> -->
          <!-- </el-scrollbar> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Модальное окно Создание секции меню. -->
  <BaseModal
    ref="modalCreateMenuSection"
    @closeModalSignal="clearSectionFormData"
  >
    <template v-slot:header> Создание секции меню </template>

    <template v-slot:body>
      <p>Выберите название секции меню из имеющихся</p>
      <p>или укажите новое</p>

      <el-form
        :model="selectSectionForm"
        label-position="left"
        label-width="0px"
        size="large"
      >
        <el-select
          v-model="selectSectionForm.sectionName"
          size="large"
          :placeholder="'Начните вводить название секции меню'"
          :remote-method="getMenuSections"
          :loading="loading"
          :loading-text="'Поиск названий секций'"
          remote
          filterable
          allow-create
          default-first-option
          remote-show-suffix
          class="select-modal"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name_with_capital_letter"
            :value="item.name"
          />
        </el-select>
      </el-form>
    </template>

    <template v-slot:footer>
      <el-button
        class="base-button"
        size="large"
        :disabled="selectSectionForm.sectionName === ''"
        @click="createMenuSection"
      >
        Создать
      </el-button>
      <el-button plain size="large" @click="cancelCreateMenuSection">
        Отмена
      </el-button>
    </template>
  </BaseModal>

  <!-- Модальное окно Удаления раздела меню -->
  <BaseModal ref="modalRemoveMenuSection">
    <template v-slot:header> Удалить раздел из меню? </template>

    <template v-slot:body>
      <p>Раздел будет удален из меню вместе с содержащимися</p>
      <p>в нем блюдами</p>
    </template>

    <template v-slot:footer>
      <el-button type="danger" size="large" @click="removeMenuSection">
        Удалить
      </el-button>
      <el-button plain size="large" @click="cancelDeleteMenuSection">
        Отмена
      </el-button>
    </template>
  </BaseModal>

  <!-- Модальное окно Добавление блюда в меню. -->
  <AddingFromListModal ref="modalAddDish">
    <template v-slot:body>
      <AddDishBlock
        :current_language="current_language"
        :localizationData="localizationData"
        :currentEditObject="currentEditObject"
        :currentMenuSectionWhereAddDishId="
          currentMenuDashMenuSectionObjWhereAddDish.menu_section.id
        "
        @closeModalAddDish="closeModalAddDish"
        @updatedMenu="sendAnEmitUpdatedMenu"
      />
    </template>
  </AddingFromListModal>

  <!-- Модальное окно Удаления блюда из меню. -->
  <BaseModal ref="modalRemoveDish">
    <template v-slot:header> Удалить блюдо? </template>

    <template v-slot:body>
      <p>Блюдо будет удалено из данной секции меню</p>
    </template>

    <template v-slot:footer>
      <el-button type="danger" size="large" @click="removeDish">
        Удалить
      </el-button>
      <el-button plain size="large" @click="cancelDeleteDish">Отмена</el-button>
    </template>
  </BaseModal>
</template>

<script>
import editBlockHeader from '@/components/editBlockHeader'
import BaseModal from '@/components/modals/BaseModal'
import AddDishBlock from '@/components/modals/AddDishBlock'
import AddingFromListModal from '@/components/modals/AddingFromListModal'
import { Container, Draggable } from 'vue-dndrop'
import { applyDrag } from '@/utils/vue-dndrop'
import DragVertical from '@/components/icons/DragVertical'
import clone from 'just-clone'
import { ElNotification } from 'element-plus'
import MenusDataService from '@/services/MenusDataService'
import { capitalizeFirstLetter } from '@/utils/capitalize_first_letter'
import { getNoun } from '@/utils/get_noun'

export default {
  components: {
    editBlockHeader: editBlockHeader,
    BaseModal: BaseModal,
    AddDishBlock: AddDishBlock,
    AddingFromListModal: AddingFromListModal,
    Container: Container,
    Draggable: Draggable,
    DragVertical: DragVertical
  },

  props: [
    'current_language',
    'localizationData',
    'currentEditObject',
    'isCollapse'
  ],

  emits: ['toAllMenus', 'updatedMenu'],

  computed: {
    // Получаем заголовок блока редактирования.
    editBlockHeaderTitle() {
      if (this.currentEditObject) {
        return 'РЕДАКТИРОВАНИЕ МЕНЮ'
      } else {
        return 'РЕДАКТИРОВАНИЕ МЕНЮ'
      }
    }
  },

  watch: {
    // Отслеживаем какое-либо изменение в инпуте Названия меню.
    'currentEditMenu.name': function () {
      this.inputResize()
    },

    // Отслеживаем какое-либо изменение в инпуте Названия секции меню.
    'currentDataOfMenuSectionWhereEditName.name': function () {
      this.inputSectionNameResize()
    },

    // Отслеживаем какое-либо изменение в currentEditObject.
    currentEditObject: {
      handler: function () {
        this.saveCurrentEditMenu()
        this.saveCurrentEditSection()
      },
      deep: true
    }
  },

  mounted() {
    this.saveCurrentEditMenu()
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      currentEditMenu: {}, // Объект текущего редактируемого меню.
      currentRemoveSectionId: '', // ID секции меню, для которой вызвано модальное окно для удаления.
      currentMenuDashMenuSectionObjWhereAddDish: {}, // Объект секции меню, в которую добавляем блюдо.
      currentMenuDashMenuSectionWhereAddDishId: '', // ID секции меню, в которую добавляем блюдо.
      currentRemoveMenuSectionDishId: '', // ID объекта MenuSectionDish, для удаления блюда из секции меню.
      currentShowSectionId: '',
      currentEditSection: {},
      currentDataOfMenuSectionWhereEditName: {},
      currentIndexOfMenuSectionWhereEditName: '',
      menuSectionDishWithAnEditablePrice: {},
      initialPriceOfDish: '',
      loading: false, // Для отображения состояния загрузки списка секций меню (в блоке Создание секции меню).
      options: [], // Опции выпадающего списка списка секций меню (в блоке Создание секции меню).
      selectSectionForm: {
        sectionName: ''
      },
      inputTitleIsFocused: false,
      inputTitleStyle: {
        width: this.fakeDivWidths
      },
      fakeDivWidth: '10px',
      menuSectionDishPriceInputIsFocus: false
    }
  },

  methods: {
    // Вычисляем вес блюда.
    getWeightOfDish(steps) {
      let weight_of_dish = 0
      let i
      let len
      for (i = 0, len = steps.length; i < len; ++i) {
        if (
          steps[i].step_type === 'supply' &&
          steps[i].dish_ingredient !== null
        ) {
          weight_of_dish += steps[i].dish_ingredient.amount
        }
      }

      return '| ' + weight_of_dish + ' г'
    },

    // Свертываем секцию меню перед началом перемещения секции.
    dragMenuSectionMouseDown(event) {
      if (event.button === 0) {
        this.currentShowSectionId = ''
        this.currentEditSection = {}
      }
    },

    // Передаем emit родительскому компоненту, для получения обновленного списка меню.
    sendAnEmitUpdatedMenu() {
      this.$emit('updatedMenu')
    },

    // Сохраняем в data объект Секция меню - Блюдо, у которого редактируем цену блюда (price).
    saveMenuSectionDishWithAnEditablePrice(menu_section_dish) {
      if (
        Object.getOwnPropertyNames(this.menuSectionDishWithAnEditablePrice)
          .length === 0
      ) {
        let menuSectionDish = clone(menu_section_dish)
        menuSectionDish.price = parseFloat(menu_section_dish.price)
        this.menuSectionDishWithAnEditablePrice = menuSectionDish
        this.initialPriceOfDish = parseFloat(menu_section_dish.price)
        this.menuSectionDishPriceInputIsFocus = true
      } else {
        setTimeout(() => {
          let menuSectionDish = clone(menu_section_dish)
          menuSectionDish.price = parseFloat(menu_section_dish.price)
          this.menuSectionDishWithAnEditablePrice = menuSectionDish
          this.initialPriceOfDish = parseFloat(menu_section_dish.price)
          this.menuSectionDishPriceInputIsFocus = true
        }, 250)
      }
    },

    // Изменяем цену блюда.
    updateMenuSectionDishPrice() {
      if (
        this.initialPriceOfDish !==
        this.menuSectionDishWithAnEditablePrice.price
      ) {
        const data = {
          price: this.menuSectionDishWithAnEditablePrice.price
        }
        const menu_section_dish_id = this.menuSectionDishWithAnEditablePrice.id

        MenusDataService.updateMenuSectionDish(data, menu_section_dish_id)
          .then(res => {
            if (res) {
              this.sendAnEmitUpdatedMenu()
              this.menuSectionDishWithAnEditablePrice = {}
              this.menuSectionDishPriceInputIsFocus = false
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })
      } else {
        this.menuSectionDishWithAnEditablePrice = {}
        this.menuSectionDishPriceInputIsFocus = false
      }
    },

    // Выполняет input blur для инпута Цена блюда.
    inputMenuSectionDishPriceBlur() {
      this.updateMenuSectionDishPrice()
    },

    // Сохраняем в data объект секции меню, у которого редактируем название.
    saveDataOfMenuSectionWhereEditName(index) {
      this.currentDataOfMenuSectionWhereEditName =
        this.currentEditMenu.menu_dash_menu_sections[index].menu_section

      this.currentDataOfMenuSectionWhereEditName.name =
        this.currentEditMenu.menu_dash_menu_sections[index].menu_section.name
          .charAt(0)
          .toUpperCase() +
        this.currentEditMenu.menu_dash_menu_sections[
          index
        ].menu_section.name.slice(1)

      this.currentIndexOfMenuSectionWhereEditName = index
    },

    // Изменяем имя секции меню.
    updateMenuSectionName() {
      if (
        this.currentEditObject.menu_dash_menu_sections[
          this.currentIndexOfMenuSectionWhereEditName
        ].menu_section.name !==
          this.currentDataOfMenuSectionWhereEditName.name.toLowerCase() &&
        this.currentDataOfMenuSectionWhereEditName.name !== ''
      ) {
        let data = {
          name: this.currentDataOfMenuSectionWhereEditName.name.toLowerCase()
        }

        MenusDataService.updateSection(
          data,
          this.currentDataOfMenuSectionWhereEditName.id
        )
          .then(res => {
            if (res) {
              this.sendAnEmitUpdatedMenu()
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })
      }

      if (this.currentDataOfMenuSectionWhereEditName.name === '') {
        this.sendAnEmitUpdatedMenu()
        ElNotification({
          title: 'Ой!',
          message: 'Нельзя сохранить секцию меню без названия',
          type: 'warning'
        })
      }
    },

    // Выполняет input blur для инпута Названия секции меню.
    inputSectionNameBlur() {
      this.updateMenuSectionName()
      this.currentDataOfMenuSectionWhereEditName = {}
    },

    // Изменяет ширину инпута Названия секции меню, в зависимости от введенного текста.
    inputSectionNameResize() {
      const refName =
        'fakeDivSectionName_' +
        this.currentIndexOfMenuSectionWhereEditName.toString()

      if (
        this.currentIndexOfMenuSectionWhereEditName !== '' &&
        this.$refs[refName][0].clientWidth <= 300
      ) {
        this.currentEditMenu.menu_dash_menu_sections[
          this.currentIndexOfMenuSectionWhereEditName
        ].menu_section.nameWidth = this.$refs[refName][0].clientWidth
      }
    },

    // Изменяем имя меню.
    updateMenuName() {
      if (this.currentEditObject.name !== this.currentEditMenu.name) {
        let data = {
          name: this.currentEditMenu.name
        }

        MenusDataService.update(data, this.currentEditObject.id)
          .then(res => {
            if (res) {
              this.sendAnEmitUpdatedMenu()
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })
      }
    },

    // Выполняет input blur для инпута Названия меню.
    inputTitleBlur() {
      if (this.currentEditMenu.name === '') {
        this.currentEditMenu.name = 'Новое меню'
      }
      this.inputTitleIsFocused = false
      this.updateMenuName()
    },

    // Выполняет input blur по клику клавиши "Enter".
    keydownEnterOnInput(event) {
      event.target.blur()
      this.inputTitleBlur()
    },

    // Изменяет ширину инпута Названия меню, в зависимости от введенного текста.
    inputResize() {
      if (this.$refs.fakeDiv.clientWidth + 20 <= 600) {
        setTimeout(() => {
          this.inputTitleStyle.width =
            this.$refs.fakeDiv.clientWidth + 20 + 15 + 'px'
        }, 0)
      }
    },

    // Получаем количество блюд в секции меню.
    getNumberOfDishes(numberOfDishes) {
      let numberOfDishesText
      if (numberOfDishes > 0) {
        numberOfDishesText =
          numberOfDishes +
          ' ' +
          getNoun(numberOfDishes, 'блюдо', 'блюда', 'блюд')
      } else {
        numberOfDishesText = 'Блюда не добавлены'
      }

      return numberOfDishesText
    },

    // Меняем последовательность секций меню в MenuDashMenuSection (drag-n-drop).
    onDropMenuSection(dropResult) {
      let menu_dash_menu_section_id
      let data = {}
      let params_data = {
        params: {}
      }
      let i
      let len

      // Формируем объект data: где ключ элемента - это id menu_dash_menu_section,
      // значение ключа элемента - это новый порядковый номер menu_dash_menu_section (order_number).
      for (
        i = 0, len = this.currentEditMenu.menu_dash_menu_sections.length;
        i < len;
        ++i
      ) {
        // Обрабатываем перемещение (drag-and-drop) секции меню вверх по списку секций меню:
        if (dropResult.removedIndex > dropResult.addedIndex) {
          // Подготавливаем данные для изменения порядкового номера перемещенной секции меню.
          if (i === dropResult.removedIndex) {
            const { edit_menu_dash_menu_section_id, order_number } =
              this.prepareMenuDashMenuSectionIdAndOrderNumber(dropResult, i)
            menu_dash_menu_section_id = edit_menu_dash_menu_section_id
            data['order_number'] = order_number
          }
          // Подготавливаем данные для изменения порядковых номеров всех MenuDashMenuSection,
          // которые изменяются в связи с перемещением редактируемого MenuDashMenuSection.
          if (dropResult.addedIndex <= i && i < dropResult.removedIndex) {
            params_data['params'][
              this.currentEditMenu.menu_dash_menu_sections[i].id
            ] = this.currentEditMenu.menu_dash_menu_sections[i].order_number + 1
          }
        }

        // Обрабатываем перемещение (drag-and-drop) секции меню вниз по списку секций меню:
        if (dropResult.removedIndex < dropResult.addedIndex) {
          // Подготавливаем данные для изменения порядкового номера перемещенной секции меню.
          if (i === dropResult.removedIndex) {
            const { edit_menu_dash_menu_section_id, order_number } =
              this.prepareMenuDashMenuSectionIdAndOrderNumber(dropResult, i)
            menu_dash_menu_section_id = edit_menu_dash_menu_section_id
            data['order_number'] = order_number
          }
          // Подготавливаем данные для изменения порядковых номеров всех MenuDashMenuSection,
          // которые изменяются в связи с перемещением редактируемого MenuDashMenuSection.
          if (dropResult.addedIndex >= i && i > dropResult.removedIndex) {
            params_data['params'][
              this.currentEditMenu.menu_dash_menu_sections[i].id
            ] = this.currentEditMenu.menu_dash_menu_sections[i].order_number - 1
          }
        }
      }

      // Изменяем порядковые номера MenuDashMenuSection на сервере,
      // если перемещение (drag-and-drop) было фактически выполнено.
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        MenusDataService.updateMenuDashSection(
          data,
          menu_dash_menu_section_id,
          params_data
        )
          .then(res => {
            if (res) {
              this.sendAnEmitUpdatedMenu()
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })

        // Сохраняем в data список секций меню с измененным порядком.
        this.currentEditMenu.menu_dash_menu_sections = applyDrag(
          this.currentEditMenu.menu_dash_menu_sections,
          dropResult
        )
      }
    },

    // Подготавливаем значение id MenuDashMenuSection
    // и порядковый номер MenuDashMenuSection после завершения перемещения.
    prepareMenuDashMenuSectionIdAndOrderNumber(dropResult, i) {
      const deltaIndexes = dropResult.removedIndex - dropResult.addedIndex
      const menu_dash_menu_section_id =
        this.currentEditMenu.menu_dash_menu_sections[i].id
      const order_number =
        this.currentEditMenu.menu_dash_menu_sections[i].order_number -
        deltaIndexes

      return {
        edit_menu_dash_menu_section_id: menu_dash_menu_section_id,
        order_number: order_number
      }
    },

    // Меняем последовательность блюд в секции меню (drag-n-drop).
    onDropDish(dropResult) {
      let menu_section_dish_id
      let data = {}
      let params_data = {
        params: {}
      }
      let i
      let len

      // Формируем объект data: где ключ элемента - это id MenuSectionDish,
      // значение ключа элемента - это новый порядковый номер блюда (order_number).
      for (
        i = 0,
          len = this.currentEditSection.menu_section.menu_section_dishes.length;
        i < len;
        ++i
      ) {
        // Обрабатываем перемещение (drag-and-drop) блюда вверх по списку блюд:
        if (dropResult.removedIndex > dropResult.addedIndex) {
          // Подготавливаем данные для изменения порядкового номера перемещенного блюда.
          if (i === dropResult.removedIndex) {
            const { edit_menu_section_dish_id, order_number } =
              this.prepareMenuSectionDishIdAndOrderNumber(dropResult, i)
            menu_section_dish_id = edit_menu_section_dish_id
            data['order_number'] = order_number
          }

          // Подготавливаем данные для изменения порядковых номеров всех блюд,
          // которые изменяются в связи с перемещением редактируемого блюда.
          if (dropResult.addedIndex <= i && i < dropResult.removedIndex) {
            params_data['params'][
              this.currentEditSection.menu_section.menu_section_dishes[i].id
            ] =
              this.currentEditSection.menu_section.menu_section_dishes[i]
                .order_number + 1
          }
        }

        // Обрабатываем перемещение (drag-and-drop) шага вниз по списку блюд:
        if (dropResult.removedIndex < dropResult.addedIndex) {
          // Подготавливаем данные для изменения порядкового номера перемещенного блюда.
          if (i === dropResult.removedIndex) {
            const { edit_menu_section_dish_id, order_number } =
              this.prepareMenuSectionDishIdAndOrderNumber(dropResult, i)
            menu_section_dish_id = edit_menu_section_dish_id
            data['order_number'] = order_number
          }

          // Подготавливаем данные для изменения порядковых номеров всех блюд,
          // которые изменяются в связи с перемещением редактируемого блюда.
          if (dropResult.addedIndex >= i && i > dropResult.removedIndex) {
            params_data['params'][
              this.currentEditSection.menu_section.menu_section_dishes[i].id
            ] =
              this.currentEditSection.menu_section.menu_section_dishes[i]
                .order_number - 1
          }
        }
      }

      // Изменяем порядковые номера блюд на сервере,
      // если перемещение (drag-and-drop) было фактически выполнено.
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        MenusDataService.updateMenuSectionDish(
          data,
          menu_section_dish_id,
          params_data
        )
          .then(res => {
            if (res) {
              this.sendAnEmitUpdatedMenu()
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })

        // Сохраняем в data список шагов с измененным порядком.
        this.currentEditSection.menu_section.menu_section_dishes = applyDrag(
          this.currentEditSection.menu_section.menu_section_dishes,
          dropResult
        )
      }
    },

    // Подготавливаем значение id MenuSectionDish
    // и порядковый номер MenuSectionDish после завершения перемещения.
    prepareMenuSectionDishIdAndOrderNumber(dropResult, i) {
      const deltaIndexes = dropResult.removedIndex - dropResult.addedIndex
      const menu_section_dish_id =
        this.currentEditSection.menu_section.menu_section_dishes[i].id
      const order_number =
        this.currentEditSection.menu_section.menu_section_dishes[i]
          .order_number - deltaIndexes

      return {
        edit_menu_section_dish_id: menu_section_dish_id,
        order_number: order_number
      }
    },

    // Сохраняем Объект текущего редактируемого меню.
    saveCurrentEditMenu() {
      if (this.currentEditObject) {
        this.currentEditMenu = clone(this.currentEditObject)

        setTimeout(() => {
          let i
          let len
          for (
            i = 0, len = this.currentEditMenu.menu_dash_menu_sections.length;
            i < len;
            ++i
          ) {
            let refName = 'fakeDivSectionName_' + i
            this.currentEditMenu.menu_dash_menu_sections[
              i
            ].menu_section.nameWidth = this.$refs[refName][0].clientWidth
          }
        }, 0)
      }
    },

    // Сохраняем Объект текущей редактируемой секции меню (menu_dash_menu_sections).
    saveCurrentEditSection() {
      if (
        this.currentEditMenu &&
        this.currentEditMenu.menu_dash_menu_sections.length > 0
      ) {
        const currentShowSectionId = this.currentShowSectionId
        let i
        let len
        for (
          i = 0, len = this.currentEditMenu.menu_dash_menu_sections.length;
          i < len;
          ++i
        ) {
          if (
            currentShowSectionId ===
            this.currentEditMenu.menu_dash_menu_sections[i].id
          ) {
            this.currentEditSection =
              this.currentEditMenu.menu_dash_menu_sections[i]
          }
        }
      }
    },

    // Развернуть/свернуть блок секции меню.
    showDishesOfSection(index, number_of_dishes) {
      if (number_of_dishes > 0) {
        if (
          this.currentEditMenu.menu_dash_menu_sections[index].id ===
          this.currentShowSectionId
        ) {
          this.currentShowSectionId = ''
          this.currentEditSection = {}
        } else {
          this.currentShowSectionId =
            this.currentEditMenu.menu_dash_menu_sections[index].id
          this.currentEditSection =
            this.currentEditMenu.menu_dash_menu_sections[index]
        }
      }
    },

    // Получаем список секций меню с сервера, содержащих в названии строку,
    // указанную в запросе.
    getMenuSections(query) {
      if (query) {
        this.loading = true
        let data = {
          search: query,
          currentEditMenuId: this.currentEditObject.id,
          signal: 'getMenuSections'
        }

        MenusDataService.getListForMenuEdit(data)
          .then(res => {
            if (res.length > 0) {
              this.loading = false
              let updateOptions = res
              let i
              let len
              for (i = 0, len = res.length; i < len; ++i) {
                let updateOption = capitalizeFirstLetter(res[i].name)
                updateOptions[i]['name_with_capital_letter'] = updateOption
              }
              this.options = updateOptions
            } else {
              let new_option = capitalizeFirstLetter(query.toLowerCase())
              this.options = [
                { id: 1, name: query, name_with_capital_letter: new_option }
              ]
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })
      } else {
        this.options = []
      }
    },

    // Создаем секцию меню.
    createMenuSection() {
      const data = {
        section_name: this.selectSectionForm.sectionName.toLowerCase(),
        current_edit_menu_id: this.currentEditMenu.id
      }

      MenusDataService.createSection(data)
        .then(res => {
          if (res) {
            this.sendAnEmitUpdatedMenu()
          }
        })
        .catch(error => {
          if (
            error.response.status === 400 &&
            error.response.data[0] === 'menu section already exists'
          ) {
            ElNotification({
              title: 'Ой!',
              message: 'Секция с таким именем уже добавлена в меню',
              type: 'warning'
            })
          }
        })

      this.$refs.modalCreateMenuSection.closeModal()
      this.clearSectionFormData()
    },

    // Удаляем секцию меню.
    removeMenuSection() {
      MenusDataService.deleteSection(this.currentRemoveSectionId)
        .then(res => {
          if (res) {
            this.sendAnEmitUpdatedMenu()
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })

      this.$refs.modalRemoveMenuSection.closeModal()
      this.currentRemoveSectionId = ''
    },

    // Удаляем блюдо из меню.
    removeDish() {
      MenusDataService.deleteMenuSectionDish(
        this.currentRemoveMenuSectionDishId
      )
        .then(res => {
          if (res) {
            this.sendAnEmitUpdatedMenu()
            this.$refs.modalRemoveDish.closeModal()
            this.currentRemoveMenuSectionDishId = ''

            if (
              this.currentEditSection.menu_section.menu_section_dishes
                .length === 1
            ) {
              this.currentShowSectionId = ''
              this.currentEditSection = {}
            }
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Открываем модальное окно создания секции меню.
    openModalCreateMenuSection() {
      this.$refs.modalCreateMenuSection.openModal()
    },

    // Отменяем создание секции меню (закрываем модальное окно).
    cancelCreateMenuSection() {
      this.$refs.modalCreateMenuSection.closeModal()
      this.clearSectionFormData()
    },

    // Очищаем данные в селекте выбора Названия секции меню.
    clearSectionFormData() {
      this.selectSectionForm.sectionName = ''
    },

    // Вызываем модальное окно для удаления секции меню.
    openModalRemoveMenuSection(menu_dash_menu_section) {
      this.currentRemoveSectionId = menu_dash_menu_section.id
      this.$refs.modalRemoveMenuSection.openModal()
    },

    // Отменяем удаление секции меню (закрываем модальное окно).
    cancelDeleteMenuSection() {
      this.$refs.modalRemoveMenuSection.closeModal()
      this.currentRemoveSectionId = ''
    },

    // Вызываем модальное окно для добавления блюда в меню.
    openModalAddDish(menu_dash_menu_sections) {
      this.currentMenuDashMenuSectionObjWhereAddDish = menu_dash_menu_sections
      this.currentMenuDashMenuSectionWhereAddDishId = menu_dash_menu_sections.id
      this.$refs.modalAddDish.openModal()
    },

    // Отменяем добавление блюда в меню (закрываем модальное окно).
    closeModalAddDish(signal) {
      if (signal === 'is_create') {
        this.currentShowSectionId =
          this.currentMenuDashMenuSectionWhereAddDishId
        this.currentEditSection = this.currentMenuDashMenuSectionObjWhereAddDish
      }

      this.$refs.modalAddDish.closeModal()
    },

    // Вызываем модальное окно для удаления блюда из меню.
    openModalRemoveDish(menu_section_dish_id) {
      this.currentRemoveMenuSectionDishId = menu_section_dish_id
      this.$refs.modalRemoveDish.openModal()
    },

    // Отменяем удаление блюда из меню (закрываем модальное окно).
    cancelDeleteDish() {
      this.$refs.modalRemoveDish.closeModal()
      this.currentRemoveMenuSectionDishId = ''
    },

    // Возвращаемся из редактирования к списку всех меню.
    toAllMenus() {
      this.$emit('toAllMenus')
    }
  }
}
</script>
