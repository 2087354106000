import { capitalizeFirstLetter } from '@/utils/capitalize_first_letter'
// Cоздает локализованные options для el-select.
// Агрументы: исходная data (array), поля подлежащие локализации (array),
// конфигурация локализации полей, текущий язык интерфейса.
export function creatingLocalizedDataFields(
  initial_data,
  fields_for_localization,
  config_localization_data_fields,
  current_language,
  array_of_initial_fields = []
) {
  const localized_data = []
  let i
  for (i = 0, initial_data.length; i < initial_data.length; ++i) {
    let item = initial_data[i]
    item.name = capitalizeFirstLetter(item.name)
    let j
    for (
      j = 0, fields_for_localization.length;
      j < fields_for_localization.length;
      ++j
    ) {
      // Если значение поля, подлежащего локализации, должно быть передано в данных списка,в том числе,
      // и в исходном (нелокализованном виде), (например, как значение status у Блюда, для дальнейшего
      // сранения и отображения тега в таблице и в карточках Блюд), то дополнительно добавляем в массив данных
      // исходное значение данного поля с именем имеющем префикс "initial_"
      if (array_of_initial_fields.length > 0) {
        let k
        for (
          k = 0, array_of_initial_fields.length;
          k < array_of_initial_fields.length;
          ++k
        ) {
          item['initial_' + array_of_initial_fields[k]] =
            item[fields_for_localization[j]]
        }
      }

      // Локализуем значение поля, подлежащего локализации.
      item[fields_for_localization[j]] =
        config_localization_data_fields[fields_for_localization[j]][
          current_language
        ][item[fields_for_localization[j]]]
    }

    localized_data.push(item)
  }

  return localized_data
}
