import exe_axios from '@/_helpers/axios.js'

class IngredientDataService {
  async getListForDishEdit(data) {
    const response = await exe_axios.get('v1/ingredients-for-dish-edit/', {
      params: data
    })
    return response.data
  }

  getList(prepared_query_params) {
    return exe_axios.get('v1/ingredients/' + prepared_query_params)
  }
}

export default new IngredientDataService()
