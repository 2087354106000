<template>
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    stroke-width="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="4" r="1" transform="rotate(90 8 4)" />
    <circle cx="16" cy="3" r="1" transform="rotate(90 16 4)" />
    <circle cx="8" cy="12" r="1" transform="rotate(90 8 12)" />
    <circle cx="16" cy="11" r="1" transform="rotate(90 16 12)" />
    <circle cx="8" cy="20" r="1" transform="rotate(90 8 20)" />
    <circle cx="16" cy="19" r="1" transform="rotate(90 16 20)" />
  </svg>
</template>
