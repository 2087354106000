// Возвращаемся на предыдущий шаг настройки блюда.
export function returnToPreviousStep(activeStep) {
  return activeStep > 0 ? activeStep - 1 : activeStep
}

// Переходим к следующему шагу настройки блюда.
export function goToNextStep(activeStep, arrEditStepsTitles) {
  return activeStep < arrEditStepsTitles.length - 1
    ? activeStep + 1
    : activeStep
}
