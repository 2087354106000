<template>
  <div class="wrapper-page">
    <el-button
      v-if="!MenuEditBlockIsVisible && defaultActiveTab === 'menus'"
      class="black-fill-button tab-pane-create-button"
      size="large"
      :disabled="MenuEditBlockIsVisible && defaultActiveTab === 'menus'"
      @click="openCollectionsObjectEditBlock('createMenu')"
    >
      <span v-if="defaultActiveTab === 'menus'">Создать меню</span>
    </el-button>

    <el-tabs
      v-model="defaultActiveTab"
      class="products-page-tabs"
      :class="{
        default_theme: nameActiveTheme === 'default_theme',
        base_theme: nameActiveTheme === 'base_theme'
      }"
    >
      <el-tab-pane
        :label="
          localizationData.collectionsPage.tab_pane[current_language].menus
        "
        name="menus"
      >
        <MenusBlock
          v-show="!MenuEditBlockIsVisible"
          :current_language="current_language"
          :localizationData="localizationData"
          :defaultActiveTab="defaultActiveTab"
          :signalAboutCreationOfMenu="signalAboutCreationOfMenu"
          :signalAboutUpdatedOfMenu="signalAboutUpdatedOfMenu"
          @openEditBlock="openCollectionsObjectEditBlock"
          @afterGetMenusData="saveAfterGetMenusData"
        />

        <MenuEditBlock
          v-if="MenuEditBlockIsVisible"
          :current_language="current_language"
          :localizationData="localizationData"
          :currentEditObject="currentEditObject"
          :isCollapse="isCollapse"
          @toAllMenus="closeMenuEditBlock"
          @updatedMenu="saveUpdatedMenuToCurrentObject"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="
          localizationData.collectionsPage.tab_pane[current_language].libraries
        "
        name="libraries"
      >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MenusBlock from './MenusBlock'
import MenuEditBlock from './MenuEditBlock'
import MenusDataService from '@/services/MenusDataService'

export default {
  props: [
    'current_language',
    'localizationData',
    'nameActiveTheme',
    'isCollapse'
  ],

  components: {
    MenusBlock: MenusBlock,
    MenuEditBlock: MenuEditBlock
  },

  watch: {
    // Отслеживаем какое-либо изменение в afterGetMenusData.
    afterGetMenusData: {
      handler: function () {
        let i
        let len
        for (i = 0, len = this.afterGetMenusData.length; i < len; ++i) {
          if (
            this.currentEditObject &&
            this.currentEditObject.id === this.afterGetMenusData[i].id
          ) {
            this.currentEditObject = this.afterGetMenusData[i]
          }
        }
      },
      deep: true
    }
  },

  data() {
    return {
      menus: [],
      defaultActiveTab: 'menus',
      MenuEditBlockIsVisible: false,
      currentEditObject: '',
      signalAboutCreationOfMenu: 0,
      signalAboutUpdatedOfMenu: 0,
      afterGetMenusData: []
    }
  },

  methods: {
    // Открываем блок редактирования объекта (меню).
    openCollectionsObjectEditBlock(object) {
      if (this.defaultActiveTab === 'menus') {
        this.saveCurrentEditObject(object)
        this.MenuEditBlockIsVisible = !this.MenuEditBlockIsVisible
      }
      if (this.defaultActiveTab === 'libraries') {
        console.log('start edit libraries')
      }
    },

    // Сохраняем текущий редактируемый объект в data.currentEditObject.
    saveCurrentEditObject(object) {
      if (object === 'createMenu') {
        this.createMenu()
        setTimeout(() => {
          this.saveCreatedMenuToCurrentObject()
        }, 2000)
      } else {
        this.currentEditObject = object
      }
    },

    // Создаем меню.
    createMenu() {
      const data = {
        name: 'Новое меню'
      }

      MenusDataService.createMenu(data)
        .then(res => {
          this.currentEditObject = res.data
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Сохраняем созданный объект в текущий редактируемый объект в data.
    saveCreatedMenuToCurrentObject() {
      this.signalAboutCreationOfMenu += 1
    },

    // Сохраняем в currentEditObject текущий редактируемый объект и отправляем
    // сигнал об изменении блюда, для получения обновленного списка блюд.
    saveUpdatedMenuToCurrentObject() {
      this.signalAboutUpdatedOfMenu += 1
    },

    // Сохраняем обновленный список блюд
    // (получаем после обновления данного списка в DishesBlock).
    saveAfterGetMenusData(afterGetMenusData) {
      this.afterGetMenusData = afterGetMenusData
    },

    // Закрываем блок редактирования объекта (меню).
    closeMenuEditBlock() {
      this.MenuEditBlockIsVisible = false
    }
  }
}
</script>
