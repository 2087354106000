<template>
  <svg
    width="90"
    height="90"
    fill="#555555"
    stroke="#f2f4f7"
    stroke-width="3.5"
    transform="scale (-1, 1)"
    transform-origin="center"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_39" data-name="Layer 39">
      <path
        d="m58.2 45.61-14-14a.5.5 0 0 0 -.7 0l-2.89 2.89a.5.5 0 0 0 0 .71l8.22 8.22a39.51 39.51 0 0 1 -38.08-39.43.5.5 0 0 0 -.5-.5h-4.1a.5.5 0 0 0 -.5.5 44.61 44.61 0 0 0 43.18 44.49l-8.26 8.26c-.07 0-.27.48 0 .68l2.93 2.92a.5.5 0 0 0 .35.15.47.47 0 0 0 .35-.15l14-14a.51.51 0 0 0 0-.74z"
      />
    </g>
  </svg>
</template>
