import exe_axios from '@/_helpers/axios'

class StepDataService {
  create(data) {
    return exe_axios.post('v1/steps/', data)
  }

  update(data, step_id, params_data) {
    return exe_axios.patch(`v1/steps/${step_id}/`, data, params_data)
  }

  delete(id) {
    return exe_axios.delete(`v1/steps/${id}/`)
  }
}

export default new StepDataService()
