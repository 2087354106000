<template>
  <div class="in-center-of-screen">
    <!-- Форма регистрации (при всех разрешениях выше, чем sm) -->
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      class="login-box hidden-xs-only"
      label-position="left"
      label-width="90px"
      size="large"
      :hide-required-asterisk="true"
    >
      <h2 class="login-title">
        <span class="title">{{ title }}</span>
        Вход в личный кабинет
      </h2>

      <el-form-item
        class="input-login-and-registration"
        prop="email"
        label="Email"
      >
        <el-input
          v-model.trim="ruleForm.email"
          type="email"
          placeholder="Введите email"
        />
      </el-form-item>

      <el-form-item
        class="input-login-and-registration"
        prop="password"
        label="Пароль"
      >
        <el-input
          v-model.trim="ruleForm.password"
          type="password"
          placeholder="Введите пароль"
          show-password
        />
      </el-form-item>

      <el-form-item>
        <el-button
          class="base-button login-button"
          size="large"
          @click="signIn(ruleForm)"
          >Войти</el-button
        >
      </el-form-item>

      <!-- <el-form-item>
      <p class="form_descriptor">
        Еще не зарегистрированы?
        <router-link to="/registr" class="link-login-registration-form"
          >Регистрация</router-link
        >
      </p>
    </el-form-item> -->
    </el-form>

    <!-- Форма регистрации (при разрешении экрана sm) -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="login-box hidden-sm-and-up"
      label-position="top"
      size="large"
      :hide-required-asterisk="true"
    >
      <h2 class="login-title">
        <span class="title">{{ title }}</span>
        Вход в личный кабинет
      </h2>

      <el-form-item
        class="input-login-and-registration"
        prop="email"
        label="Email"
      >
        <el-input
          v-model.trim="ruleForm.email"
          type="email"
          placeholder="Введите email"
        />
      </el-form-item>

      <el-form-item
        class="input-login-and-registration"
        prop="password"
        label="Пароль"
      >
        <el-input
          v-model.trim="ruleForm.password"
          type="password"
          placeholder="Введите пароль"
          show-password
        />
      </el-form-item>

      <el-form-item>
        <el-button
          class="base-button login-button"
          size="large"
          @click="signIn(ruleForm)"
          >Войти</el-button
        >
      </el-form-item>

      <!-- <el-form-item>
      <p class="form_descriptor">
        Еще не зарегистрированы?
        <router-link to="/registr" class="link-login-registration-form"
          >Регистрация</router-link
        >
      </p>
    </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import { app, INPUT_FIELD_RULES_FOR_SIGNIN } from '@/_config'
import messages from '@/utils/messages'
import { ElNotification } from 'element-plus'

export default {
  name: 'login-page',
  data() {
    return {
      title: app.title,
      ruleForm: {
        email: '',
        password: ''
      },
      rules: INPUT_FIELD_RULES_FOR_SIGNIN
    }
  },
  mounted() {
    if (messages[this.$route.query.msg]) {
      ElNotification({
        message: messages[this.$route.query.msg].message,
        type: messages[this.$route.query.msg].type
      })
    }
  },

  methods: {
    signIn() {
      // логика валидации формы
      this.$refs['ruleFormRef'].validate(valid => {
        if (valid) {
          // логика авторизации
          this.$store.dispatch('logIn', {
            email: this.ruleForm.email.toLowerCase(),
            password: this.ruleForm.password
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
