import exe_axios from '@/_helpers/axios'
import { store } from '@/store'
import { getAccessToken } from '@/services/UsersAuthService'
import { ElMessage } from 'element-plus'
import messages from '@/utils/messages'

// Переменная для хранения запроса токена.
let refreshTokenRequest = null

// Запросить валидный аксесс токен.
async function requestValidAccessToken() {
  // Сначала запоминаем текущий accessToken из хранилища.
  let accessToken = getAccessToken()

  // Приводим текущее время к unix timestamp.
  const now = Math.floor(Date.now() * 0.001)

  if (!store.getters.isRefreshTokenValid(now)) {
    // Если рефреш токен устарел, разлогиниваем пользователя.
    store.dispatch('logOut')
  } else if (!store.getters.isAccessTokenValid(now)) {
    // Если не было запроса на обновление, создаем запрос
    // и запоминаем его в переменную для избежания race condition.
    if (refreshTokenRequest === null) {
      accessToken = await store.dispatch('refreshToken')
    }
  }

  // Возвращаем рабочий accessToken.
  return accessToken
}

export default function axiosSetUp() {
  exe_axios.interceptors.request.use(
    async config => {
      // Если указан флаг skipAuth, пропускаем запрос дальше как есть
      // (этот флаг указан у методов login и refreshToken, они не подкрепляются токенами)
      if (config.skipAuth) {
        return config
      }
      // иначе запрашиваем валидный accessToken
      const accessToken = await requestValidAccessToken()

      // и возвращаем пропатченный конфиг с токеном в заголовке.
      return {
        ...config,
        headers: {
          // common: {
          ['Authorization']: `Bearer ${accessToken}`
          // }
        }
      }
    },
    error => {
      return Promise.reject(error)
    }
  )

  exe_axios.interceptors.response.use(
    // сюда попадает все, что валидируется успешным ответом status < 500
    response => {
      return response
    },
    error => {
      // Этот блок кода срабатывает, когда прилетает ошибка с бэка
      // о невалидных логине и/или пароле (при авторизации).
      if (error.response.status === 401 && error.config.url === 'v1/token/') {
        ElMessage.error({
          message:
            messages['no_active_account_found_with_the_given_credentials'][
              'message'
            ],
          duration: 5000
        })

        return Promise.reject(error)
      }

      // Этот блок кода срабатывает, когда прилетает любая ошибка !== 401.
      if (error.response.status !== 401) {
        // ElMessage.error({
        //   message: 'Что-то пошло не так',
        //   grouping: true,
        //   duration: 5000
        // })

        return Promise.reject(error)
      }

      return Promise.reject(error)
    }
  )
}
