// Изменить createWebHashHistory на createWebHistory, чтобы из url убрать '#'
// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { store } from '@/store'

// const routerHistory = createWebHashHistory()
const routerHistory = createWebHistory()

import SignInPage from '@/pages/SignInPage.vue'
import RegistrationPage from '@/pages/RegistrationPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import DashboardPage from '@/pages/dashboard_page/DashboardPage.vue'
import OrdersPage from '@/pages/orders_page/OrdersPage.vue'
import CollectionsPage from '@/pages/collections_page/CollectionsPage.vue'
import ProductsPage from '@/pages/products_page/ProductsPage.vue'
import SettingsPage from '@/pages/settings_page/SettingsPage.vue'
import MenuPage from '@/pages/menus_page/MenuPage'

const routers = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'empty' },
      component: SignInPage
    },
    {
      path: '/registration',
      name: 'registration',
      meta: { layout: 'empty' },
      component: RegistrationPage
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: { layout: 'main', auth: true },
      component: DashboardPage
    },
    {
      path: '/orders',
      name: 'orders',
      meta: { layout: 'main', auth: true },
      component: OrdersPage
    },
    {
      path: '/collections',
      name: 'collections',
      meta: { layout: 'main', auth: true },
      component: CollectionsPage
    },
    {
      path: '/products',
      name: 'products',
      meta: { layout: 'main', auth: true },
      component: ProductsPage
    },
    {
      path: '/settings',
      name: 'settings',
      meta: { layout: 'main', auth: true },
      component: SettingsPage
    },
    {
      path: '/:CatchAll(.*)',
      name: '404',
      meta: { layout: 'not-found' },
      component: NotFoundPage
    },
    {
      path: '/menu',
      name: 'menu',
      meta: { layout: 'empty' },
      component: MenuPage
    }
  ]
})

routers.beforeEach((to, from, next) => {
  const token = store.getters.accessToken
  const requireAuth = to.matched.some(element => element.meta.auth)

  if (requireAuth && !token) {
    next('/login?msg=login')
  } else {
    if (token && (to.name === 'login' || to.name === 'registr')) {
      next({ name: 'dashboard' })
    } else {
      if (token && to.name === '404') {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    }
  }
})

export default routers
