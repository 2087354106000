import exe_axios from '@/_helpers/axios'

class MenuForGuestsDataService {
  get(identifier) {
    return exe_axios({
      method: 'get',
      url: `v1/menu-for-service-point/?gsp_id=${identifier}`,
      skipAuth: true
    })
  }
}

export default new MenuForGuestsDataService()
