// Настройки локализации (localizationData).
export const localizationData = {
  sideBar: {
    ru: {
      dashboard: {
        title: 'Дашборд'
      },
      orders: {
        title: 'Заказы'
      },
      collections: {
        title: 'Коллекции'
      },
      products: {
        title: 'Продукция'
      },
      settings: {
        title: 'Настройки'
      }
    },
    en: {
      dashboard: {
        title: 'Dashboard'
      },
      orders: {
        title: 'Orders'
      },
      collections: {
        title: 'Collections'
      },
      products: {
        title: 'Products'
      },
      settings: {
        title: 'Settings'
      }
    }
  },

  collectionsPage: {
    tab_pane: {
      ru: {
        menus: 'Меню',
        libraries: 'Библиотеки'
      },
      en: {
        menus: 'Menus',
        libraries: 'Libraries'
      }
    }
  },

  productsPage: {
    tab_pane: {
      ru: {
        dishes: 'Блюда',
        ingredients: 'Ингредиенты'
      },
      en: {
        dishes: 'Dishes',
        ingredients: 'Ingredients'
      }
    }
  },

  ordersBlock: {
    mainBlockInput: {
      ru: {
        placeholder: 'Поиск заказов (введите номер)'
      },
      en: {
        placeholder: 'Search orders'
      }
    },

    mainBlockSelectWithoutIcon: {
      ru: {
        placeholder: 'Статус заказа',
        options: {
          wait_payment: 'Ожидает оплату',
          accepted: 'Принят',
          queued: 'Поставлен в очередь',
          preparing: 'Готовится',
          is_ready: 'Готово',
          issued: 'Выдан'
        }
      },
      en: {
        placeholder: 'Order status',
        options: {
          wait_payment: 'Waiting for payment',
          accepted: 'Accepted',
          queued: 'Queued',
          preparing: 'Preparing',
          is_ready: 'Is ready',
          issued: 'Issued'
        }
      }
    },

    datePicker: {
      ru: {
        start_placeholder: 'Период с ...',
        end_placeholder: 'по ...'
      },
      en: {
        start_placeholder: 'Start date',
        end_placeholder: 'End date'
      }
    },

    labelsOfOrdersTable: {
      ru: {
        order: 'Заказ',
        price_sum: 'Сумма',
        creation_time: 'Дата создания',
        cooking_time: 'Время приготовления',
        status: 'Статус'
      },
      en: {
        order: 'Order',
        price_sum: 'Sum',
        creation_time: 'Creation time',
        cooking_time: 'Cooking time',
        status: 'Status'
      }
    },

    ordersStatusTags: {
      ru: {
        wait_payment: 'Ожидает оплату',
        accepted: 'Принят',
        queued: 'В очереди',
        preparing: 'Готовится',
        is_ready: 'Готово',
        issued: 'Выдан'
      },
      en: {
        wait_payment: 'Waiting for payment',
        accepted: 'Accepted',
        queued: 'Queued',
        preparing: 'Preparing',
        is_ready: 'Is ready',
        issued: 'Issued'
      }
    }
  },

  collectionsBlock: {
    mainBlockInput: {
      ru: {
        placeholder: 'Поиск меню'
      },
      en: {
        placeholder: 'Search menu'
      }
    },

    mainBlockSelectWithoutIcon: {
      ru: {
        placeholder: 'Статус',
        options: {
          development: 'В разработке',
          is_active: 'Активно',
          to_archive: 'В архиве'
        }
      },
      en: {
        placeholder: 'Status',
        options: {
          development: 'In Dev',
          is_active: 'Active',
          to_archive: 'Archive'
        }
      }
    },

    labelsOfMenusTable: {
      ru: {
        name: 'Название',
        status: 'Статус',
        created: 'Дата создания'
      },
      en: {
        name: 'Name',
        status: 'Status',
        created: 'Creation time'
      }
    },

    menuStatusTags: {
      ru: {
        development: 'В разработке',
        is_active: 'Активно',
        to_archive: 'В архиве'
      },
      en: {
        development: 'In Dev',
        is_active: 'Active',
        to_archive: 'Archive'
      }
    }
  },

  dishesBlock: {
    mainBlockInput: {
      ru: {
        placeholder: 'Поиск блюд'
      },
      en: {
        placeholder: 'Search dishes'
      }
    },

    mainBlockSelectWithoutIcon: {
      ru: {
        placeholder: 'Статус',
        options: {
          idea: 'Идея',
          development: 'В разработке',
          ready: 'Готово'
        }
      },
      en: {
        placeholder: 'Status',
        options: {
          idea: 'Idea',
          development: 'In Dev',
          ready: 'Ready'
        }
      }
    },

    mainBlockSelectAnIcon: {
      ru: {
        placeholder: 'Фильтр',
        validity: {
          options_group_name: 'Валидность',
          options: {
            draft: 'Проект',
            valid: 'Валиден'
          }
        }
      },
      en: {
        placeholder: 'Filter',
        validity: {
          options_group_name: 'Validity',
          options: {
            draft: 'Draft',
            valid: 'Valid'
          }
        }
      }
    },

    labelsOfDishesTable: {
      ru: {
        name: 'Название',
        cooking_time: 'Время приготовления',
        validity: 'Валидность',
        status: 'Статус'
      },
      en: {
        name: 'Name',
        cooking_time: 'Cooking time',
        validity: 'Valid',
        status: 'Status'
      }
    },

    fieldsOfDish: {
      status: {
        ru: {
          idea: 'Идея',
          development: 'В разработке',
          ready: 'Готово'
        },
        en: {
          idea: 'Idea',
          development: 'Development',
          ready: 'Ready'
        }
      }
    },

    dishesActitvityTags: {
      ru: {
        idea: 'Идея',
        development: 'В разработке',
        ready: 'Готово'
      },
      en: {
        idea: 'Idea',
        development: 'In Dev',
        ready: 'Ready'
      }
    },

    descriptionOfIngredientsAmount: {
      ru: {
        initial_ingredient_word: {
          one: 'ингредиент',
          two: 'ингредиента',
          five: 'ингредиентов'
        },
        if_ingredients_are_not_selected: 'Ингредиенты не выбраны'
      },
      en: {
        initial_ingredient_word: {
          one: 'ingredient',
          two: 'ingredients',
          five: 'ingredients'
        },
        if_ingredients_are_not_selected: 'Ingredients are not selected'
      }
    }
  },

  ingredientsBlock: {
    mainBlockInput: {
      ru: {
        placeholder: 'Поиск ингредиентов'
      },
      en: {
        placeholder: 'Search ingredients'
      }
    },

    mainBlockSelectWithoutIcon: {
      ru: {
        placeholder: 'Тип',
        options: {
          meat: 'Мясо',
          seafood: 'Морепродукты',
          vegetables: 'Овощи',
          fruits: 'Фрукты',
          pasta: 'Паста',
          sauce: 'Соусы',
          spices: 'Специи',
          water: 'Вода'
        }
      },
      en: {
        placeholder: 'Type',
        options: {
          meat: 'Meat',
          seafood: 'Seafood',
          vegetables: 'Vegetables',
          fruits: 'Fruits',
          pasta: 'Pasta',
          sauce: 'Sauce',
          spices: 'Spices',
          water: 'Water'
        }
      }
    },

    mainBlockSelectAnIcon: {
      ru: {
        placeholder: 'Фильтр',
        form: {
          options_group_name: 'Форма',
          options: {
            cube: 'Кубики',
            juliennes: 'Соломка',
            slices: 'Дольки',
            liquid: 'Жидкость',
            herbs: 'Травы',
            powder: 'Порошок',
            unit: 'Единица'
          }
        },
        state: {
          options_group_name: 'Состояние',
          options: {
            raw: 'Сырой',
            cooked: 'Приготовленный',
            dried: 'Сушеный'
          }
        },
        validity: {
          options_group_name: 'Валидность',
          options: {
            draft: 'Проект',
            valid: 'Валиден'
          }
        }
      },
      en: {
        placeholder: 'Filter',
        form: {
          options_group_name: 'Form',
          options: {
            cube: 'Cube',
            juliennes: 'Juliennes',
            slices: 'Slices',
            liquid: 'Liquid',
            herbs: 'Herbs',
            powder: 'Powder',
            unit: 'Unit'
          }
        },
        state: {
          options_group_name: 'State',
          options: {
            raw: 'Raw',
            cooked: 'Cooked',
            dried: 'Dried'
          }
        },
        validity: {
          options_group_name: 'Validity',
          options: {
            draft: 'Draft',
            valid: 'Valid'
          }
        }
      }
    },

    labelsOfIngredientsTable: {
      ru: {
        name: 'Название',
        measurement_unit: 'Ед. изм.',
        form: 'Форма',
        state: 'Состояние',
        valid: 'Валидность'
      },
      en: {
        name: 'Name',
        measurement_unit: 'Unit',
        form: 'Form',
        state: 'State',
        valid: 'Valid'
      }
    },

    fieldsOfIngredient: {
      form: {
        ru: {
          cube: 'Кубики',
          juliennes: 'Соломка',
          slices: 'Дольки',
          liquid: 'Жидкость',
          herbs: 'Травы',
          powder: 'Порошок',
          unit: 'Единица'
        },
        en: {
          cube: 'Cube',
          juliennes: 'Juliennes',
          slices: 'Slices',
          liquid: 'Liquid',
          herbs: 'Herbs',
          powder: 'Powder',
          unit: 'Unit'
        }
      },
      state: {
        ru: {
          raw: 'Сырой',
          cooked: 'Приготовленный',
          dried: 'Сушеный'
        },
        en: {
          raw: 'Raw',
          cooked: 'Cooked',
          dried: 'Dried'
        }
      },
      validity: {
        ru: {
          draft: 'draft',
          valid: 'valid'
        },
        en: {
          draft: 'draft',
          valid: 'valid'
        }
      }
    },

    ingredientActitvityTags: {
      ru: {
        draft: 'Проект',
        valid: 'Валиден'
      },
      en: {
        draft: 'Draft',
        valid: 'Valid'
      }
    },

    descriptionOfHowManyDishesAreUsed: {
      ru: {
        description_path_one: 'Используется в',
        initial_dish_word: {
          one: 'блюде',
          two: 'блюдах',
          five: 'блюдах'
        },
        not_used: 'Не используется'
      },
      en: {
        description_path_one: 'Used in',
        initial_dish_word: {
          one: 'dish',
          two: 'dishes',
          five: 'dishes'
        },
        not_used: 'Not used'
      }
    }
  }
}
