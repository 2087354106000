export const process = {
  dev: true
}

export const site = {
  home: process.dev ? 'https://localhost:8080/' : 'https://my-site-project.ru/'
}

// Настройки текущего языка интерфейса ('ru', 'en').
export const current_language = 'ru'

// Настройки валидации формы авторизации (SignInPage.vue)
const TYPICAL_ITEMS_FOR_EMAIL = [
  {
    required: true,
    message: 'Введите Email',
    trigger: 'blur'
  },
  {
    type: 'email',
    message: 'Введите корректный Email',
    trigger: 'blur'
  }
]

const TYPICAL_ITEMS_FOR_PASSWORD = [
  { required: true, message: 'Введите пароль', trigger: 'blur' },
  {
    min: 8,
    message: 'Пароль должен содержать не менее 8 символов',
    trigger: 'blur'
  }
]

export const INPUT_FIELD_RULES_FOR_SIGNIN = {
  email: TYPICAL_ITEMS_FOR_EMAIL,
  password: TYPICAL_ITEMS_FOR_PASSWORD
}

// Настройки сайдбара.
// Название сервиса (логотип)
export const app = {
  title: 'Robocook'
}

export const sideBarItems = [
  {
    alias: 'dashboard',
    index: 'dashboard'
  },
  {
    alias: 'orders',
    index: 'orders'
  },
  {
    alias: 'collections',
    index: 'collections'
  },
  {
    alias: 'products',
    index: 'products'
  },
  {
    alias: 'settings',
    index: 'settings'
  }
]

// Настройки тем интерфейса.
// Активная тема интерфейса.
export const nameActiveTheme = 'base_theme'

// Темы интерфейса (настройки для сайдбара).
export const sideBarThemes = {
  default_theme: {
    text_color: '#ffffff',
    active_text_color: '#ffd04b',
    background_color: '#545c64',
    size_icon: 20
  },

  base_theme: {
    text_color: '#222222',
    active_text_color: '#ffffff',
    background_color: '#ffffff',
    size_icon: 20
  }
}

// Настройки таблицы Меню.
// Value для заголовка таблицы Меню.
export const menusTableItems = ['name', 'status', 'created']

// Поля меню, которые подлежат локализации.
export const fieldsOfMenuForLocalization = ['status']

// Настройки value для фильтра Статус меню.
export const valueOfOptionsForMenusStatus = [
  'development',
  'is_active',
  'to_archive'
]

// Настройки таблицы Заказы.
// Value для заголовка таблицы Заказы.
export const ordersTableItems = [
  'order',
  'price_sum',
  'creation_time',
  'cooking_time',
  'status'
]

// Настройки value для фильтра Статус заказа.
export const valueOfOptionsForOrderStatus = [
  'wait_payment',
  'accepted',
  'queued',
  'preparing',
  'is_ready',
  'issued'
]

// Настройки таблицы Блюда.
// Value для заголовка таблицы Блюда.
export const dishesTableItems = ['name', 'cooking_time', 'validity', 'status']

// Поля блюд, которые подлежат локализации.
export const fieldsOfDishForLocalization = ['status']

// Настройки value для фильтра Статус блюда.
export const valueOfOptionsForDishesStatus = ['idea', 'development', 'ready']

// Настройки options для общего фильтра Блюд (с иконкой).
export const valueOfOptionsForDishesGeneralFilter = [
  { validity: ['draft', 'valid'] }
]

// Настройки таблицы Ингредиенты.
// Value для заголовка таблицы Ингредиенты.
export const ingredientsTableItems = [
  'name',
  'measurement_unit',
  'form',
  'state',
  'valid'
]

// Поля ингредиентов, которые подлежат локализации.
export const fieldsOfIngredientForLocalization = ['state', 'form', 'validity']

// Настройки value для фильтра Тип ингредиента.
export const valueOfOptionsForIngredientTypes = [
  'meat',
  'seafood',
  'vegetables',
  'fruits',
  'pasta',
  'sauce',
  'spices',
  'water'
]

// Настройки options для общего фильтра Ингридиентов (с иконкой).
export const valueOfOptionsForIngredientsGeneralFilter = [
  {
    form: ['cube', 'juliennes', 'slices', 'liquid', 'herbs', 'powder', 'unit']
  },
  { state: ['raw', 'cooked', 'dried'] },
  { validity: ['draft', 'valid'] }
]

// Настройки элементов.
// Типовой размер иконки в элементах (в select)
export const baseIconSize = 22

// Настройки для модальных окон.
export const SIZE_CLOSE_ICON = 33
