import axios from 'axios'

// Настраиваем атрибуты axios
axios.defaults.timeout = 6000 // Время ожидания запроса - 1 минута.
axios.defaults.responseType = 'json'

const exe_axios = axios.create({
  // baseURL: process.env.NODE_ENV == 'development' ? '' : '',
  baseURL: process.env.VUE_APP_BASE_URL + '/api/',
  headers: {
    'Content-Type': 'application/json'
  }
})

export default exe_axios
