// Подготавливаем query params для общего фильтра.
export function preparedGeneralFilterEndpoint(data, config_options) {
  let final_general_filter_endpoint = ''
  let general_filter_endpoint = {}

  if (data) {
    let value_group_options_arr = []
    let i
    for (i = 0, config_options.length; i < config_options.length; ++i) {
      const value_group_options = Object.keys(config_options[i])[0]
      value_group_options_arr.push(value_group_options)
      const values_options = Object.values(config_options[i])[0]

      let j
      for (j = 0, values_options.length; j < values_options.length; ++j) {
        if (data.selectAnIconValue.includes(values_options[j])) {
          if (!(value_group_options in general_filter_endpoint)) {
            general_filter_endpoint[value_group_options] = [values_options[j]]
          } else {
            general_filter_endpoint[value_group_options].push(values_options[j])
          }
        }
      }
    }

    let k
    for (
      k = 0, value_group_options_arr.length;
      k < value_group_options_arr.length;
      ++k
    ) {
      if (general_filter_endpoint[value_group_options_arr[k]]) {
        const query_params_filter =
          general_filter_endpoint[value_group_options_arr[k]].join(',')
        final_general_filter_endpoint =
          final_general_filter_endpoint +
          '&' +
          value_group_options_arr[k] +
          '=' +
          query_params_filter
      }
    }
  }

  return final_general_filter_endpoint
}
