<template>
  <div class="main-search-filtering-panel">
    <!-- Input для поиска (крайнее левое положение) -->
    <el-input
      v-if="isMainSearchFilteringBlock"
      v-model="searchTableInput"
      :placeholder="mainBlockInputPlaceholder"
      class="main-block-input"
      :prefix-icon="Search"
      @input="validateSearchTableInput"
    />

    <!-- Select без иконки (не крайний) -->
    <div>
      <el-select
        v-if="
          isMainSearchFilteringBlock && selectWithoutIconPlaceholderIsActive
        "
        v-model="selectWithoutIconValue"
        :placeholder="mainBlockSelectWithoutIconPlaceholder"
        size="large"
        class="main-block-select select-without-icon"
        style="width: 250px"
        clearable
      >
        <el-option
          v-for="item in selectWithoutIconOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>

    <!-- Select с иконкой (не крайний) -->
    <div
      v-if="isMainSearchFilteringBlock && selectAnIconPlaceholderIsActive"
      class="wrapper-select-with-an-icon"
    >
      <div class="wrapper-main-block-select-icon">
        <el-icon class="main-block-select-icon" :size="baseIconSize">
          <Operation />
        </el-icon>
      </div>

      <el-tree-select
        v-model="selectAnIconValue"
        :data="selectAnIconOptions"
        :placeholder="mainBlockSelectAnIconPlaceholder"
        :render-after-expand="false"
        :max-collapse-tags="1"
        multiple
        collapse-tags
        collapse-tags-tooltip
        show-checkbox
        check-on-click-node
        clearable
        size="large"
        class="main-block-select main-block-tree-select select-with-an-icon"
        style="width: 250px"
      />
    </div>

    <!-- Select без иконки (крайнее правое положение) -->
    <div>
      <el-select
        v-if="
          isMainSearchFilteringBlock &&
          selectWithoutIconPlaceholderWithRightmostPositionIsActive
        "
        v-model="selectWithoutIconValue"
        :placeholder="mainBlockSelectWithoutIconPlaceholder"
        size="large"
        class="main-block-select select-without-icon select-without-icon-with-rightmost-position"
        style="width: 250px"
        clearable
      >
        <el-option
          v-for="item in selectWithoutIconOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>

    <!-- Выбор диапазона дат DatePicker (крайнее правое положение) -->
    <el-date-picker
      v-if="
        isMainSearchFilteringBlock && datePickerWithRightmostPositionIsActive
      "
      v-model="datePickerValue"
      type="daterange"
      range-separator="–"
      :start-placeholder="datePickerWithRightmostPositionStartPlaceholder"
      :end-placeholder="datePickerWithRightmostPositionEndPlaceholder"
      format="DD.MM.YYYY"
      value-format="x"
      :unlink-panels="true"
      :size="sizeDatePicker"
      :default-value="defaultValue"
      :default-time="defaultTime"
      :disabled-date="disabledDate"
      class="main-block-select main-block-tree-select select-with-an-icon"
    />

    <!-- Блок переключения вида отображаемых данных (таблица или карточки) -->
    <div
      v-if="isMainSearchFilteringBlock && blockViewChangeIsActive"
      class="main-block-view-change-wrapper"
    >
      <div class="main-block-view-change">
        <el-icon
          :size="baseIconSize"
          class="view-change-icon"
          :class="{
            active_list_view: activeDataView === 'active_list_view',
            default_theme: nameActiveTheme === 'default_theme',
            base_theme: nameActiveTheme === 'base_theme'
          }"
          @click="activateDataView('active_list_view')"
        >
          <ListIcon />
        </el-icon>
        <el-icon
          :size="baseIconSize"
          class="view-change-icon"
          :class="{
            active_blocks_view: activeDataView === 'active_blocks_view',
            default_theme: nameActiveTheme === 'default_theme',
            base_theme: nameActiveTheme === 'base_theme'
          }"
          @click="activateDataView('active_blocks_view')"
        >
          <Menu />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { nameActiveTheme, baseIconSize } from '@/_config'
import { Search } from '@element-plus/icons-vue'
import ListIcon from '@/components/icons/ListIcon'

export default {
  props: [
    'nameBlock',
    'isMainSearchFilteringBlock',
    'selectWithoutIconPlaceholderIsActive',
    'selectAnIconPlaceholderIsActive',
    'datePickerWithRightmostPositionIsActive',
    'blockViewChangeIsActive',
    'mainBlockInputPlaceholder',
    'mainBlockSelectWithoutIconPlaceholder',
    'mainBlockSelectAnIconPlaceholder',
    'selectWithoutIconOptions',
    'selectAnIconOptions',
    'selectWithoutIconPlaceholderWithRightmostPositionIsActive',
    'datePickerWithRightmostPositionStartPlaceholder',
    'datePickerWithRightmostPositionEndPlaceholder'
  ],

  components: {
    ListIcon: ListIcon
  },

  watch: {
    searchTableInput() {
      this.changingFilterSearchValue()
    },
    selectWithoutIconValue() {
      this.changingFilterSearchValue()
    },
    selectAnIconValue() {
      this.changingFilterSearchValue()
    },
    datePickerValue() {
      this.changingFilterSearchValue()
    }
  },

  data() {
    return {
      activeDataView: 'active_list_view',
      nameActiveTheme: nameActiveTheme,
      Search: markRaw(Search),
      searchTableInput: '',
      selectWithoutIconValue: '',
      selectAnIconValue: [],
      datePickerValue: [],
      sizeDatePicker: 'large',
      defaultValue: [
        new Date().setMonth(new Date().getMonth() - 1),
        new Date()
      ],
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
      ],
      baseIconSize: baseIconSize
    }
  },

  methods: {
    // Валидируем вводимые данные в Input.
    validateSearchTableInput() {
      // Если выполняем поиск в таблице раздела "Заказы",
      // проверяем, что вводим число.
      if (
        this.nameBlock === 'orders' &&
        !/^\d*[1-9]\d*$/.test(this.searchTableInput)
      ) {
        this.searchTableInput = this.searchTableInput.slice(0, -1)
      }
    },

    // Делаем в DatePicker неактивными все даты, следующие за текущей датой.
    disabledDate(time) {
      return time.getTime() > Date.now()
    },

    // Отправляем сигнал о переключении вида отображения данных (таблица - карточки).
    activateDataView(view) {
      this.activeDataView = view
      this.$emit('changeDataView', this.activeDataView)
    },

    // Отправляем сигнал об изменении значения фильтров или поиска.
    changingFilterSearchValue() {
      if (!this.selectWithoutIconValue) {
        this.selectWithoutIconValue = ''
      }

      this.$emit('changingFilterSearchValue', {
        signal: 'changeFilterSearchValue',
        searchTableInput: this.searchTableInput,
        selectWithoutIconValue: this.selectWithoutIconValue,
        selectAnIconValue: this.selectAnIconValue,
        datePickerValue: this.datePickerValue
      })
    }
  }
}
</script>
