<template>
  <div class="main-block">
    <!-- Основной блок поиска и фильтрации -->
    <MainSearchFilteringBlock
      :isMainSearchFilteringBlock="true"
      :selectWithoutIconPlaceholderIsActive="false"
      :selectAnIconPlaceholderIsActive="true"
      :selectAnIconPlaceholderWithRightmostPositionIsActive="false"
      :blockViewChangeIsActive="true"
      :mainBlockInputPlaceholder="
        localizationData.dishesBlock.mainBlockInput[current_language]
          .placeholder
      "
      :mainBlockSelectWithoutIconPlaceholder="
        localizationData.dishesBlock.mainBlockSelectWithoutIcon[
          current_language
        ].placeholder
      "
      :mainBlockSelectAnIconPlaceholder="
        localizationData.dishesBlock.mainBlockSelectAnIcon[current_language]
          .placeholder
      "
      :selectAnIconOptions="creatingLocalizedOptionsForDishesGeneralFilter"
      @changeDataView="changeDishesView"
      @changingFilterSearchValue="getDishes"
    />

    <!-- Отображение контента в виде таблицы -->
    <el-table
      v-if="activeDishesView === 'active_list_view'"
      :data="dishesData"
      height="300"
    >
      <el-table-column
        prop="name"
        :label="
          this.localizationData.dishesBlock.labelsOfDishesTable[
            this.current_language
          ][this.dishesTableItems[0]]
        "
        width="360"
        sortable
      >
        <template #default="scope">
          <div class="wpapper-custom-table-cell">
            <img
              v-if="scope.row.image !== null"
              :src="baseURL + scope.row.image"
              class="custom-table-cell-item-image"
            />
            <img
              v-else
              :src="require(`@/assets/images/no_photo.jpeg`)"
              class="custom-table-cell-item-image"
            />
            <div>
              <div class="table-row-name">
                {{ scope.row.name }}
              </div>
              <div class="table-row-description">
                {{ ingredientCount(scope.row.ingredient_count) }}
                {{ getWeightOfDish(scope.row.weight_of_dish) }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="roasting_time_user_format"
        :label="
          this.localizationData.dishesBlock.labelsOfDishesTable[
            this.current_language
          ][this.dishesTableItems[1]]
        "
        width="250"
        align="center"
        sortable
      />

      <el-table-column prop="id" align="right">
        <template #default="scope">
          <el-button class="base-button" @click="addDish(scope.row)">
            Добавить блюдо
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Отображение контента в виде карточек -->
    <div
      v-if="activeDishesView === 'active_blocks_view'"
      class="wrapper-box-card"
    >
      <el-card v-for="dish in dishesData" :key="dish.id" class="box-card">
        <div
          v-if="dish.image !== null"
          class="box-card-with-background-fill"
          :style="{
            backgroundImage: 'url(' + baseURL + dish.image + ')'
          }"
        >
          <div class="box-card-with-background-fill-mask">
            <div class="box-card-with-background-fill-title">
              {{ dish.name }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="box-card-with-background-fill"
          :style="{
            backgroundImage:
              'url(' + require(`@/assets/images/no_photo.jpeg`) + ')'
          }"
        >
          <div class="box-card-with-background-fill-mask">
            <div class="box-card-with-background-fill-title">
              {{ dish.name }}
            </div>
          </div>
        </div>

        <div class="box-card-header-button">
          <el-dropdown
            trigger="click"
            placement="bottom"
            @visible-change="
              dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
            "
          >
            <el-button
              class="card-more-filled-button"
              :class="{
                active:
                  dropdownMoreFilledIsVisible === true &&
                  dish.id === currentDishId
              }"
              circle
              @click="writeCurrentTableRowId(dish.id)"
            >
              <el-icon :size="18">
                <MoreFilled />
              </el-icon>
            </el-button>

            <template #dropdown>
              <el-dropdown-item @click="addDish(dish)">
                Добавить блюдо
              </el-dropdown-item>
            </template>
          </el-dropdown>
        </div>
      </el-card>
    </div>

    <div class="pagination-and-button-footer-block">
      <div>
        <!-- Блок пагинации -->
        <PaginationBlock
          :total="dishesCount"
          @changeCurrentPaginationPage="getDishes"
          :key="keyPaginationBlock"
        />
        <div />
      </div>

      <!-- Кнопка закрытия модального окна (Добавление блюда в секцию меню) -->
      <div class="wrapper-button-footer-block">
        <el-button plain size="large" @click="closeModalAddDish('is_cancel')">
          Отмена
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nameActiveTheme,
  dishesTableItems,
  fieldsOfDishForLocalization,
  valueOfOptionsForDishesStatus,
  valueOfOptionsForDishesGeneralFilter
} from '@/_config'
import { creatingLocalizedOptionsForTreeSelect } from '@/utils/creating_localized_options_for_selects'
import { creatingLocalizedDataFields } from '@/utils/creating_localized_data_fields'
import { preparedGeneralFilterEndpoint } from '@/utils/prepared_endpoints'
import MenusDataService from '@/services/MenusDataService'
import DishDataService from '@/services/DishDataService'
import MainSearchFilteringBlock from '@/components/MainSearchFilteringBlock'
import PaginationBlock from '@/components/PaginationBlock'
import { getNoun } from '@/utils/get_noun'

export default {
  props: [
    'current_language',
    'localizationData',
    'currentEditObject',
    'currentMenuSectionWhereAddDishId'
  ],

  emits: ['closeModalAddDish', 'updatedMenu'],

  components: {
    MainSearchFilteringBlock: MainSearchFilteringBlock,
    PaginationBlock: PaginationBlock
  },

  computed: {
    // Получаем options для общего фильтра блюд.
    creatingLocalizedOptionsForDishesGeneralFilter() {
      const localization_data_for_select =
        this.localizationData.dishesBlock.mainBlockSelectAnIcon[
          this.current_language
        ]
      const config_options = this.valueOfOptionsForDishesGeneralFilter
      const options = creatingLocalizedOptionsForTreeSelect(
        localization_data_for_select,
        config_options
      )
      return options
    }
  },

  created() {
    this.getDishes()
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      activeDishesView: 'active_list_view',
      nameActiveTheme: nameActiveTheme,
      dishesTableItems: dishesTableItems,
      fieldsOfDishForLocalization: fieldsOfDishForLocalization,
      valueOfOptionsForDishesStatus: valueOfOptionsForDishesStatus,
      valueOfOptionsForDishesGeneralFilter:
        valueOfOptionsForDishesGeneralFilter,
      searchTableInput: '',
      selectWithoutIconValue: '',
      finalGeneralFilterEndpoint: '',
      dishesData: [],
      dishesCount: 0,
      currentPage: 1,
      keyPaginationBlock: 0,
      dropdownMoreFilledIsVisible: false,
      currentDishId: ''
    }
  },

  methods: {
    // Добавляем блюдо в секцию меню.
    addDish(dish_obj) {
      const data = {
        menu_section: this.currentMenuSectionWhereAddDishId,
        dish: dish_obj.id
      }

      MenusDataService.createMenuSectionDish(data)
        .then(res => {
          if (res) {
            this.$emit('updatedMenu')
            this.closeModalAddDish('is_create')
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Отменяем добавление блюда в меню (закрываем модальное окно).
    closeModalAddDish(signal) {
      this.$emit('closeModalAddDish', signal)
    },

    // Запишем в data id текущего блюда.
    writeCurrentTableRowId(currentDishId) {
      this.currentDishId = currentDishId
    },

    // Изменяет вид отображения блюд.
    changeDishesView(activeDataView) {
      this.activeDishesView = activeDataView
      this.dropdownMoreFilledIsVisible = false
    },

    // Получаем список блюд.
    getDishes(data) {
      let prepared_query_params

      if (data && data.signal === 'changeCurrentPaginationPage') {
        this.currentPage = data.updatePage
      }

      if (data && data.signal === 'changeFilterSearchValue') {
        // Если в метод переданы параметры общего фильтра.
        // Сбрасываем текущую страницу пагинации на 1.
        this.currentPage = 1

        // Сохраняем текущие параметры поиска и фильтра Статус в data.
        this.searchTableInput = data.searchTableInput.toLowerCase()
        this.selectWithoutIconValue = data.selectWithoutIconValue

        // Подготавливаем query params для общего фильтра.
        const config_options = this.valueOfOptionsForDishesGeneralFilter
        const final_general_filter_endpoint = preparedGeneralFilterEndpoint(
          data,
          config_options
        )
        this.finalGeneralFilterEndpoint = final_general_filter_endpoint
      }

      // Формируем итоговый query params для страницы пагинации,
      // поиска, фильтра Статус, общего фильтра.
      prepared_query_params =
        '?page=' +
        this.currentPage +
        '&search=' +
        this.searchTableInput +
        '&status=' +
        this.selectWithoutIconValue +
        this.finalGeneralFilterEndpoint

      const params_data = {
        params: {
          menu_id: this.currentEditObject.id
        }
      }

      DishDataService.getListForAddToMenu(prepared_query_params, params_data)
        .then(res => {
          if (res) {
            this.dishesData = creatingLocalizedDataFields(
              res.data.results,
              fieldsOfDishForLocalization,
              this.localizationData.dishesBlock.fieldsOfDish,
              this.current_language,
              ['status']
            )
            this.dishesCount = res.data.count

            if (data && data.signal === 'changeFilterSearchValue') {
              this.forcePaginationBlock()
            }
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Получаем дескриптор к названию блюда.
    ingredientCount(ingredient_count) {
      if (ingredient_count > 0) {
        const ingredient_word = getNoun(
          ingredient_count,
          this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
            this.current_language
          ].initial_ingredient_word.one,
          this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
            this.current_language
          ].initial_ingredient_word.two,
          this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
            this.current_language
          ].initial_ingredient_word.five
        )
        return `${ingredient_count} ${ingredient_word}`
      } else {
        return this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
          this.current_language
        ].if_ingredients_are_not_selected
      }
    },

    // Получаем дескриптор к названию блюда.
    getWeightOfDish(weight_of_dish) {
      if (weight_of_dish !== null) {
        return '| ' + weight_of_dish + ' г'
      }
    },

    // Делаем перерендеринг блока пагинации.
    forcePaginationBlock() {
      this.keyPaginationBlock += 1
    }
  }
}
</script>
