<template>
  <div class="wrapper-page">
    <el-button
      v-if="
        (!DishEditBlockIsVisible && defaultActiveTab === 'dishes') ||
        (defaultActiveTab === 'ingredients' && false)
      "
      class="black-fill-button tab-pane-create-button"
      size="large"
      :disabled="DishEditBlockIsVisible && defaultActiveTab === 'dishes'"
      @click="openProductsObjectEditBlock('createDish')"
    >
      <span v-if="defaultActiveTab === 'dishes'">Создать блюдо</span>
      <span v-if="defaultActiveTab === 'ingredients'">Создать ингредиент</span>
    </el-button>

    <el-tabs
      v-model="defaultActiveTab"
      class="products-page-tabs"
      :class="{
        default_theme: nameActiveTheme === 'default_theme',
        base_theme: nameActiveTheme === 'base_theme'
      }"
    >
      <el-tab-pane
        :label="localizationData.productsPage.tab_pane[current_language].dishes"
        name="dishes"
      >
        <DishesBlock
          v-show="!DishEditBlockIsVisible"
          :current_language="current_language"
          :localizationData="localizationData"
          :defaultActiveTab="defaultActiveTab"
          :signalAboutCreationOfDish="signalAboutCreationOfDish"
          :signalAboutUpdatedOfDish="signalAboutUpdatedOfDish"
          @openEditBlock="openProductsObjectEditBlock"
          @afterGetDishesData="saveAfterGetDishesData"
        />
        <DishEditBlock
          v-if="DishEditBlockIsVisible"
          :currentEditObject="currentEditObject"
          :isCollapse="isCollapse"
          @toAllDishes="closeDishEditBlock"
          @createdDish="saveCreatedDishToCurrentObject"
          @updatedDish="saveUpdatedDishToCurrentObject"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="
          localizationData.productsPage.tab_pane[current_language].ingredients
        "
        name="ingredients"
      >
        <IngredientsBlock
          :current_language="current_language"
          :localizationData="localizationData"
          :defaultActiveTab="defaultActiveTab"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DishesBlock from './DishesBlock'
import DishEditBlock from './DishEditBlock'
import IngredientsBlock from './IngredientsBlock'

export default {
  props: [
    'current_language',
    'localizationData',
    'nameActiveTheme',
    'isCollapse'
  ],

  components: {
    DishesBlock: DishesBlock,
    DishEditBlock: DishEditBlock,
    IngredientsBlock: IngredientsBlock
  },

  watch: {
    // Отслеживаем какое-либо изменение в afterGetDishesData.
    afterGetDishesData: {
      handler: function () {
        let i
        let len
        for (i = 0, len = this.afterGetDishesData.length; i < len; ++i) {
          if (
            this.currentEditObject &&
            this.currentEditObject.id === this.afterGetDishesData[i].id
          ) {
            this.currentEditObject = this.afterGetDishesData[i]
          }
        }
      },
      deep: true
    }
  },

  data() {
    return {
      defaultActiveTab: 'dishes',
      DishEditBlockIsVisible: false,
      currentEditObject: '',
      signalAboutCreationOfDish: 0,
      signalAboutUpdatedOfDish: 0,
      afterGetDishesData: []
    }
  },

  methods: {
    // Сохраняем обновленный список блюд
    // (получаем после обновления данного списка в DishesBlock).
    saveAfterGetDishesData(afterGetDishesData) {
      this.afterGetDishesData = afterGetDishesData
    },

    // Сохраняем текущий редактируемый объект в data.currentEditObject.
    saveCurrentEditObject(object) {
      if (object === 'createDish') {
        this.currentEditObject = ''
      } else {
        this.currentEditObject = object
      }
    },

    // Открываем блок редактирования объекта (блюдо).
    openProductsObjectEditBlock(object) {
      if (this.defaultActiveTab === 'dishes') {
        this.saveCurrentEditObject(object)
        this.DishEditBlockIsVisible = !this.DishEditBlockIsVisible
      }
      if (this.defaultActiveTab === 'ingredients') {
        console.log('start edit ingredients')
      }
    },

    // Сохраняем созданный объект в текущий редактируемый объект в data.
    saveCreatedDishToCurrentObject(createdDish) {
      this.currentEditObject = createdDish
      this.signalAboutCreationOfDish += 1
    },

    // Сохраняем в currentEditObject текущий редактируемый объект и отправляем
    // сигнал об изменении блюда, для получения обновленного списка блюд.
    saveUpdatedDishToCurrentObject() {
      this.signalAboutUpdatedOfDish += 1
    },

    // Закрываем блок редактирования объекта (блюдо).
    closeDishEditBlock() {
      this.DishEditBlockIsVisible = false
    }
  }
}
</script>
