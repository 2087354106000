<template>
  <div class="main-layout">
    <!-- Блок SideBar -->
    <el-menu
      class="sidebar-menu"
      :text-color="sideBarThemes[nameActiveTheme].text_color"
      :active-text-color="sideBarThemes[nameActiveTheme].active_text_color"
      :background-color="sideBarThemes[nameActiveTheme].background_color"
      :default-active="defaultActiveMenuItem"
      :collapse="isCollapse"
      @select="openPage"
    >
      <img
        v-if="imgCapSantaIsActive"
        :src="require(`@/assets/images/cap_santa.png`)"
        class="img-cap-santa"
      />
      <div
        v-if="isCollapse === false"
        class="sidebar-menu-logo"
        :class="{
          default_theme: nameActiveTheme === 'default_theme',
          base_theme: nameActiveTheme === 'base_theme'
        }"
      >
        {{ title }}
      </div>
      <div
        v-if="isCollapse === true"
        class="sidebar-menu-logo"
        :class="{
          default_theme: nameActiveTheme === 'default_theme',
          base_theme: nameActiveTheme === 'base_theme'
        }"
      >
        R
      </div>

      <el-tooltip
        v-for="item in sideBarItems"
        placement="right"
        effect="light"
        popper-class="base-custom"
        :offset="8"
        :key="item.alias"
        :show-after="300"
        :disabled="isCollapse === false"
      >
        <template #content>
          <div>
            {{ localizationData.sideBar[current_language][item.alias].title }}
          </div>
        </template>

        <el-menu-item
          class="sidebar-menu-item"
          :class="{
            default_theme: nameActiveTheme === 'default_theme',
            base_theme: nameActiveTheme === 'base_theme'
          }"
          :index="item.index"
        >
          <el-icon :size="sideBarThemes[nameActiveTheme].size_icon">
            <Odometer v-if="item.alias === 'dashboard'" />
            <ShoppingCart v-if="item.alias === 'orders'" />
            <Memo v-if="item.alias === 'collections'" />
            <Dish v-if="item.alias === 'products'" />
            <Setting v-if="item.alias === 'settings'" />
          </el-icon>
          <span>
            {{ localizationData.sideBar[current_language][item.alias].title }}
          </span>
        </el-menu-item>
      </el-tooltip>

      <div class="exit-button-wrapper">
        <el-tooltip
          placement="right"
          effect="light"
          popper-class="base-custom"
          :offset="8"
          :show-after="300"
          :disabled="isCollapse === false"
        >
          <template #content>
            <div>Выйти</div>
          </template>

          <div class="exit-button" @click="logOut">
            <el-icon :size="sideBarThemes[nameActiveTheme].size_icon">
              <ExitIcon />
            </el-icon>
            <span v-if="isCollapse === false">Выйти</span>
          </div>
        </el-tooltip>
      </div>
    </el-menu>

    <el-container>
      <!-- Блок Header -->
      <el-header>
        <el-button
          class="sidebar-collapse-button"
          :class="{
            is_collapse: isCollapse === true
          }"
          :icon="ArrowLeftBold"
          :key="keySidebarCollapseButton"
          circle
          @click="isCollapse = !isCollapse"
        />

        <HeaderBlock v-if="headerBlockIsVisible" />
      </el-header>

      <el-scrollbar>
        <!-- Основной блок -->
        <PageHeaderBlock :currentActiveMenuItem="currentActiveMenuItem" />

        <el-main>
          <router-view
            :current_language="current_language"
            :localizationData="localizationData"
            :nameActiveTheme="nameActiveTheme"
            :isCollapse="isCollapse"
          />
        </el-main>
      </el-scrollbar>
    </el-container>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { localizationData } from '@/_localization'
import {
  app,
  current_language,
  sideBarThemes,
  nameActiveTheme,
  sideBarItems
} from '@/_config'
import HeaderBlock from '@/components/HeaderBlock'
import PageHeaderBlock from '@/components/PageHeaderBlock'
import ExitIcon from '@/components/icons/ExitIcon'
import { ArrowLeftBold } from '@element-plus/icons-vue'

export default {
  components: {
    HeaderBlock: HeaderBlock,
    PageHeaderBlock: PageHeaderBlock,
    ExitIcon: ExitIcon
  },

  watch: {
    isCollapse() {
      setTimeout(this.forceSidebarCollapseButton, 300)
    }
  },

  computed: {
    // Включаем отображение шапки Санты, если текущая дата находится
    // в диапазоне с 15 декабря по 15 января.
    imgCapSantaIsActive() {
      let date = new Date()
      if (date.getMonth() === 11 && date.getDate() >= 15) {
        return true
      }
      if (date.getMonth() === 0 && date.getDate() <= 15) {
        return true
      }

      return false
    }
  },

  mounted() {
    this.defaultActiveMenuItem = this.$route.name
    this.saveCurrentActiveMenuItem(this.$route.name)
  },

  data() {
    return {
      headerBlockIsVisible: false,
      current_language: current_language,
      sideBarItems: sideBarItems,
      localizationData: localizationData,
      isCollapse: false,
      nameActiveTheme: nameActiveTheme,
      sideBarThemes: sideBarThemes,
      defaultActiveMenuItem: '',
      currentActiveMenuItem: '',
      ArrowLeftBold: markRaw(ArrowLeftBold),
      title: app.title,
      keySidebarCollapseButton: 0
    }
  },

  methods: {
    // Открываем страницу в соответствии с выбранным разделом меню сайдбара.
    openPage(item_index) {
      if (item_index === 'dashboard') {
        this.$router.push('/dashboard')
        this.saveCurrentActiveMenuItem(item_index)
      }
      if (item_index === 'orders') {
        this.$router.push('/orders')
        this.saveCurrentActiveMenuItem(item_index)
      }
      if (item_index === 'collections') {
        this.$router.push('/collections')
        this.saveCurrentActiveMenuItem(item_index)
      }
      if (item_index === 'products') {
        this.$router.push('/products')
        this.saveCurrentActiveMenuItem(item_index)
      }
      if (item_index === 'settings') {
        this.$router.push('/settings')
        this.saveCurrentActiveMenuItem(item_index)
      }
    },

    // Сохраняем в data название выбранного раздела меню сайдбара.
    saveCurrentActiveMenuItem(item_index) {
      let i
      for (i = 0; i < sideBarItems.length; ++i) {
        if (sideBarItems[i].index === item_index) {
          this.currentActiveMenuItem =
            localizationData.sideBar[current_language][
              sideBarItems[i].index
            ].title
        }
      }
    },

    // Выйти из личного кабинета.
    logOut() {
      this.$store.dispatch('logOut', 'logoutSignal')
    },

    // Обновляем рендер кнопки свертывания сайдбара (для снятия фокуса после нажатия)
    forceSidebarCollapseButton() {
      this.keySidebarCollapseButton += 1
    }
  }
}
</script>
