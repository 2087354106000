<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" @click="closeModal" />

      <div class="modal__add_dialog">
        <div class="modal__add_body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { SIZE_CLOSE_ICON } from '@/_config'

export default {
  name: 'AddingFromListModal',

  emits: ['closeModalSignal'],

  data() {
    return {
      show: false,
      size_close_icon: SIZE_CLOSE_ICON
    }
  },

  methods: {
    closeModal() {
      this.show = !this.show
      document.querySelector('body').classList.remove('overflow-hidden')
      this.$emit('closeModalSignal')
    },

    openModal() {
      this.show = !this.show
      document.querySelector('body').classList.add('overflow-hidden')
    }
  }
}
</script>
