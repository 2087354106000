import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'
import { KJUR } from 'jsrsasign'
import router from '@/_helpers/routes'
import exe_axios from '@/_helpers/axios'
import { login, refreshToken } from '@/services/UsersAuthService'

export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'rkit',
      paths: [
        'refresh_token',
        'access_token',
        'loggedInUser',
        'isAuthenticated',
        'refreshTokenExpires',
        'accessTokenExpires',
        'cart'
      ],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 14,
            secure: true,
            domain: process.env.VUE_APP_DOMAIN,
            sameSite: 'strict'
          }),
        removeItem: key => Cookies.remove(key)
      }
    })
  ],
  state: {
    refresh_token: '',
    access_token: '',
    loggedInUser: {},
    isAuthenticated: false,
    refreshTokenExpires: 0,
    accessTokenExpires: 0,
    cart: []
  },
  mutations: {
    setRefreshToken(state, refreshToken) {
      state.refresh_token = refreshToken
      state.refreshTokenExpires =
        KJUR.jws.JWS.parse(refreshToken).payloadObj.exp
    },
    setAccessToken(state, accessToken) {
      state.access_token = accessToken
      state.accessTokenExpires = KJUR.jws.JWS.parse(accessToken).payloadObj.exp
    },
    // Устанавливает состояние с информацией о пользователе и переключает
    // IsAuthenticated с false на true.
    setLoggedInUser(state, user) {
      state.loggedInUser = user
      state.isAuthenticated = true
    },
    // Удалите из state всю информацию об авторизации и пользователе.
    clearUserData(state) {
      state.refresh_token = ''
      state.access_token = ''
      state.loggedInUser = {}
      state.isAuthenticated = false
      state.refreshTokenExpires = 0
      state.accessTokenExpires = 0
      state.cart = []
    },
    // Сохраняет в state корзину заказов.
    setCart(state, cart_items) {
      state.cart = cart_items
    }
  },
  actions: {
    logIn: async ({ commit, dispatch }, { email, password }) => {
      try {
        const data = await login({ email, password })

        if (data) {
          commit('setRefreshToken', data.refresh)
          commit('setAccessToken', data.access)
          await dispatch('fetchUser')
          // Редирект на DashboardPage.
          router.push({ name: 'dashboard' })
        }
      } catch (e) {
        console.log('')
      }
    },

    refreshToken: async ({ state, commit }) => {
      try {
        const data = await refreshToken({
          refresh: state.refresh_token
        })

        commit('setAccessToken', data.access)

        // Возвращает токен строкой, чтобы обновить заголовок клиента axios.
        return data.access
      } catch (e) {
        console.log('')
      }
    },

    logOut: async ({ commit }, signal) => {
      commit('clearUserData')
      if (signal === 'logoutSignal') {
        router.replace('/login?msg=logout')
      } else {
        router.replace('/login?msg=login')
      }
    },

    fetchUser: async ({ commit }) => {
      const currentUserUrl = 'v1/auth/users/me/'

      try {
        await exe_axios.get(currentUserUrl).then(response => {
          if (response.status === 200) {
            commit('setLoggedInUser', response.data)
          }
        })
      } catch (e) {
        console.log('')
      }
    },

    saveCart: async ({ commit }, cart_items) => {
      try {
        commit('setCart', cart_items)
      } catch (e) {
        console.log('')
      }
    }
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    isAuthenticated: state => state.isAuthenticated,
    accessToken: state => state.access_token,
    refreshToken: state => state.refresh_token,
    isAccessTokenValid: state => now => now < state.accessTokenExpires,
    isRefreshTokenValid: state => now => now < state.refreshTokenExpires,
    cart: state => state.cart
  }
})
