import axios from 'axios'
import exe_axios from '@/_helpers/axios'

// Создаем отдельный экземпляр axios, чтобы не сталкиваться с ошибкой в интерсепторе
// при регистрации (в связи с тем, что не авторизованы).
const custom_axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + '/api/',
  headers: {
    'Content-Type': 'application/json'
  }
})

class UsersDataService {
  // Отправляем сообщение по выделенному URL-адресу, который есть у нас на сервере
  // (для которого потребуется токен reCAPTCHA).
  verifyCaptcha(body) {
    return custom_axios.post('v1/verify-captcha/', body)
  }

  // Регистрируем пользователя.
  signUp(body) {
    return custom_axios.post('v1/auth/users/', body)
  }

  // Изменяем данные у пользователя.
  update(data, id) {
    return exe_axios.patch(`v1/auth/users/${id}/`, data)
  }

  // Изменяем пароль пользователя.
  change_password(data) {
    return exe_axios.post('v1/auth/users/set_password/', data)
  }

  // Изменяем данные пользователя.
  update_me(data) {
    return exe_axios.patch('v1/auth/users/me/', data)
  }

  signIn(email, password) {
    return exe_axios({
      method: 'post',
      url: 'v1/token/',
      data: { email, password },
      skipAuth: true
    })
  }

  refreshToken(refresh) {
    return exe_axios({
      method: 'post',
      url: 'v1/token/refresh/',
      data: refresh,
      skipAuth: true
    })
  }
}

export default new UsersDataService()
