<template>
  <div class="main-block-wrapper">
    <div class="main-block">
      <!-- Основной блок поиска и фильтрации -->
      <MainSearchFilteringBlock
        :isMainSearchFilteringBlock="true"
        :selectWithoutIconPlaceholderIsActive="false"
        :selectAnIconPlaceholderIsActive="false"
        :selectWithoutIconPlaceholderWithRightmostPositionIsActive="true"
        :selectAnIconPlaceholderWithRightmostPositionIsActive="false"
        :blockViewChangeIsActive="false"
        :mainBlockInputPlaceholder="
          localizationData.collectionsBlock.mainBlockInput[current_language]
            .placeholder
        "
        :mainBlockSelectWithoutIconPlaceholder="
          localizationData.collectionsBlock.mainBlockSelectWithoutIcon[
            current_language
          ].placeholder
        "
        :selectWithoutIconOptions="creatingLocalizedOptionsForMenusStatus"
        @changingFilterSearchValue="getMenus"
      />

      <!-- Отображение контента в виде таблицы -->
      <el-table :data="menusData">
        <el-table-column
          prop="name"
          :label="
            this.localizationData.collectionsBlock.labelsOfMenusTable[
              this.current_language
            ][this.menusTableItems[0]]
          "
          width="450"
          sortable
        >
          <template #default="scope">
            <div class="wpapper-custom-table-cell">
              <div>
                <div class="table-row-name">
                  {{ scope.row.name }}
                </div>
                <div class="table-row-description">Описание меню</div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          :label="
            this.localizationData.collectionsBlock.labelsOfMenusTable[
              this.current_language
            ][this.menusTableItems[1]]
          "
          width="180"
          sortable
        >
          <template #default="scope">
            <div
              class="wpapper-custom-table-cell table-cell-justify-content-center"
            >
              <el-tag
                v-if="scope.row.status === 'development'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-3"
              >
                {{
                  localizationData.collectionsBlock.menuStatusTags[
                    current_language
                  ].development
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.status === 'is_active'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-5"
              >
                {{
                  localizationData.collectionsBlock.menuStatusTags[
                    current_language
                  ].is_active
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.status === 'to_archive'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-1"
              >
                {{
                  localizationData.collectionsBlock.menuStatusTags[
                    current_language
                  ].to_archive
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="created"
          :label="
            this.localizationData.collectionsBlock.labelsOfMenusTable[
              this.current_language
            ][this.menusTableItems[2]]
          "
          width="200"
          align="center"
          sortable
        />

        <el-table-column prop="id" align="right">
          <template #default="scope">
            <el-dropdown
              trigger="click"
              placement="bottom-end"
              @visible-change="
                dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
              "
            >
              <el-button
                class="table-more-filled-button"
                :class="{
                  active:
                    dropdownMoreFilledIsVisible === true &&
                    scope.row.id === currentMenuId
                }"
                icon="MoreFilled"
                circle
                @click="writeCurrentTableRowId(scope.row.id)"
              />
              <template #dropdown>
                <el-dropdown-item @click="openEditBlock(scope.row)">
                  Редактировать
                </el-dropdown-item>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <!-- Блок пагинации -->
      <PaginationBlock
        :total="menusCount"
        @changeCurrentPaginationPage="getMenus"
        :key="keyPaginationBlock"
      />
    </div>
  </div>
</template>

<script>
import {
  nameActiveTheme,
  menusTableItems,
  fieldsOfMenuForLocalization,
  valueOfOptionsForMenusStatus
} from '@/_config'
import { creatingLocalizedOptionsForSelect } from '@/utils/creating_localized_options_for_selects'
import MenusDataService from '@/services/MenusDataService'
import MainSearchFilteringBlock from '@/components/MainSearchFilteringBlock'
import PaginationBlock from '@/components/PaginationBlock'

export default {
  props: [
    'current_language',
    'localizationData',
    'defaultActiveTab',
    'signalAboutCreationOfMenu',
    'signalAboutUpdatedOfMenu'
  ],

  emits: ['openEditBlock', 'afterGetMenusData'],

  components: {
    MainSearchFilteringBlock: MainSearchFilteringBlock,
    PaginationBlock: PaginationBlock
  },

  computed: {
    // Получаем options для фильтра Статус меню.
    creatingLocalizedOptionsForMenusStatus() {
      const localization_data_for_select =
        this.localizationData.collectionsBlock.mainBlockSelectWithoutIcon[
          this.current_language
        ]
      const config_options = this.valueOfOptionsForMenusStatus
      const options = creatingLocalizedOptionsForSelect(
        localization_data_for_select,
        config_options
      )
      return options
    }
  },

  watch: {
    // Если активен таб блюд, получаем список блюд.
    defaultActiveTab() {
      if (this.defaultActiveTab === 'menus') {
        this.getMenus()
      }
    },
    // Если получен сигнал о создании меню, получаем обновленный список меню.
    signalAboutCreationOfMenu() {
      const data = {
        signal: 'createdMenu'
      }

      this.getMenus(data)
    },
    // Если получен сигнал о изменении блюда, получаем обновленный список блюд.
    signalAboutUpdatedOfMenu() {
      this.getMenus()
    },

    // Отслеживаем какое-либо изменение в menusData.
    menusData: {
      handler: function () {
        this.$emit('afterGetMenusData', this.menusData)
      },
      deep: true
    }
  },

  created() {
    if (this.defaultActiveTab === 'menus') {
      this.getMenus()
    }
  },

  data() {
    return {
      menusData: [],
      nameActiveTheme: nameActiveTheme,
      menusTableItems: menusTableItems,
      fieldsOfMenuForLocalization: fieldsOfMenuForLocalization,
      valueOfOptionsForMenusStatus: valueOfOptionsForMenusStatus,
      searchTableInput: '',
      selectWithoutIconValue: '',
      menusCount: 0,
      currentPage: 1,
      keyPaginationBlock: 0,
      dropdownMoreFilledIsVisible: false,
      currentMenuId: ''
    }
  },

  methods: {
    // Получаем список меню.
    getMenus(data) {
      let prepared_query_params

      if (data && data.signal === 'changeCurrentPaginationPage') {
        this.currentPage = data.updatePage
      }

      if (data && data.signal === 'changeFilterSearchValue') {
        // Если в метод переданы параметры общего фильтра.
        // Сбрасываем текущую страницу пагинации на 1.
        this.currentPage = 1

        // Сохраняем текущие параметры поиска и фильтра Статус в data.
        this.searchTableInput = data.searchTableInput.toLowerCase()
        this.selectWithoutIconValue = data.selectWithoutIconValue
      }

      // Формируем итоговый query params для страницы пагинации,
      // поиска, фильтра Статус, общего фильтра.
      prepared_query_params =
        '?page=' +
        this.currentPage +
        '&search=' +
        this.searchTableInput +
        '&status=' +
        this.selectWithoutIconValue

      MenusDataService.get(prepared_query_params)
        .then(res => {
          this.menusData = res.data.results
          this.menusCount = res.data.count

          if (data && data.signal === 'changeFilterSearchValue') {
            this.forcePaginationBlock()
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Запишем в data id текущего блюда.
    writeCurrentTableRowId(currentMenuId) {
      this.currentMenuId = currentMenuId
    },

    // Открываем блок редактирования блюда.
    openEditBlock(menu_object) {
      this.$emit('openEditBlock', menu_object)
    },

    // Делаем перерендеринг блока пагинации.
    forcePaginationBlock() {
      this.keyPaginationBlock += 1
    }
  }
}
</script>
