<template>
  <div class="edit-block-steps">
    <el-steps :active="activeStep" process-status="process" align-center>
      <el-step
        v-for="(editStepTitle, index) in arrEditStepsTitles"
        :key="index"
        :title="`${editStepTitle}`"
      />
    </el-steps>
  </div>
</template>

<script>
export default {
  props: ['activeStep', 'arrEditStepsTitles']
}
</script>
