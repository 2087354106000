<template>
  <div class="edit-block-header">
    <el-row>
      <el-col class="edit-block-header-link-button" :span="4">
        <el-button icon="ArrowLeft" link @click="toAllObjects">
          {{ editBlockHeaderLinkButton }}
        </el-button>
      </el-col>
      <el-col class="edit-block-header-title" :span="16">
        <div>{{ editBlockHeaderTitle }}</div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ['editBlockHeaderLinkButton', 'editBlockHeaderTitle'],

  emits: ['toAllObjects'],

  data() {
    return {}
  },

  methods: {
    toAllObjects() {
      this.$emit('toAllObjects')
    }
  }
}
</script>
