<template>
  <div class="main-block-wrapper">
    <div class="main-block">
      <!-- Основной блок поиска и фильтрации -->
      <MainSearchFilteringBlock
        :nameBlock="'orders'"
        :isMainSearchFilteringBlock="true"
        :selectWithoutIconPlaceholderIsActive="true"
        :selectAnIconPlaceholderIsActive="false"
        :selectWithoutIconPlaceholderWithRightmostPositionIsActive="false"
        :datePickerWithRightmostPositionIsActive="true"
        :blockViewChangeIsActive="false"
        :mainBlockInputPlaceholder="
          localizationData.ordersBlock.mainBlockInput[current_language]
            .placeholder
        "
        :mainBlockSelectWithoutIconPlaceholder="
          localizationData.ordersBlock.mainBlockSelectWithoutIcon[
            current_language
          ].placeholder
        "
        :selectWithoutIconOptions="creatingLocalizedOptionsForOrderStatus"
        :datePickerWithRightmostPositionStartPlaceholder="
          localizationData.ordersBlock.datePicker[current_language]
            .start_placeholder
        "
        :datePickerWithRightmostPositionEndPlaceholder="
          localizationData.ordersBlock.datePicker[current_language]
            .end_placeholder
        "
        @changingFilterSearchValue="getOrders"
      />

      <!-- Отображение контента в виде таблицы -->
      <el-table :data="orders">
        <el-table-column
          prop="order_number"
          :label="
            this.localizationData.ordersBlock.labelsOfOrdersTable[
              this.current_language
            ][this.ordersTableItems[0]]
          "
          width="170"
          sortable
        >
          <template #default="scope">
            <div class="wpapper-custom-table-cell">
              <div>
                <div class="table-row-name">
                  Заказ #{{ scope.row.order_number_in_full_format }}
                </div>
                <div class="table-row-description">
                  {{ getNumberServings(scope.row.order_dish) }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="price_sum"
          :label="
            this.localizationData.ordersBlock.labelsOfOrdersTable[
              this.current_language
            ][this.ordersTableItems[1]]
          "
          width="150"
          align="left"
        >
          <template #default="scope"> {{ scope.row.price_sum }} ₽ </template>
        </el-table-column>

        <el-table-column
          prop="order_roasting_time_user_format"
          :label="
            this.localizationData.ordersBlock.labelsOfOrdersTable[
              this.current_language
            ][this.ordersTableItems[3]]
          "
          width="300"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ getRoastingTimeInUserFormat(scope.row.order_roasting_time) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          :label="
            this.localizationData.ordersBlock.labelsOfOrdersTable[
              this.current_language
            ][this.ordersTableItems[4]]
          "
          width="180"
          sortable
        >
          <template #default="scope">
            <div
              class="wpapper-custom-table-cell table-cell-justify-content-center"
            >
              <el-tag
                v-if="scope.row.status === 'wait_payment'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-1"
              >
                {{
                  localizationData.ordersBlock.ordersStatusTags[
                    current_language
                  ].wait_payment
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.status === 'accepted'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-2"
              >
                {{
                  localizationData.ordersBlock.ordersStatusTags[
                    current_language
                  ].accepted
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.status === 'queued'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-2"
              >
                {{
                  localizationData.ordersBlock.ordersStatusTags[
                    current_language
                  ].queued
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.status === 'preparing'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-3"
              >
                {{
                  localizationData.ordersBlock.ordersStatusTags[
                    current_language
                  ].preparing
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.status === 'is_ready'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-4"
              >
                {{
                  localizationData.ordersBlock.ordersStatusTags[
                    current_language
                  ].is_ready
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.status === 'issued'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-5"
              >
                {{
                  localizationData.ordersBlock.ordersStatusTags[
                    current_language
                  ].issued
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="created"
          :label="
            this.localizationData.ordersBlock.labelsOfOrdersTable[
              this.current_language
            ][this.ordersTableItems[2]]
          "
          width="220"
          align="center"
          sortable
        />

        <el-table-column prop="id" align="right">
          <template #default="scope">
            <el-dropdown
              trigger="click"
              placement="bottom-end"
              @visible-change="
                dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
              "
            >
              <el-button
                class="table-more-filled-button"
                :class="{
                  active:
                    dropdownMoreFilledIsVisible === true &&
                    scope.row.id === currentOrderId
                }"
                icon="MoreFilled"
                circle
                @click="writeCurrentTableRowId(scope.row.id)"
              />
              <template #dropdown>
                <el-dropdown-item
                  @click="openBlockWithDetailedOrderData(scope.row)"
                >
                  Подробнее
                </el-dropdown-item>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <!-- Блок пагинации -->
      <PaginationBlock
        :total="ordersCount"
        @changeCurrentPaginationPage="getOrders"
        :key="keyPaginationBlock"
      />
    </div>
  </div>
</template>

<script>
import OrdersDataService from '@/services/OrdersDataService'
import {
  nameActiveTheme,
  ordersTableItems,
  valueOfOptionsForOrderStatus
} from '@/_config'
import { creatingLocalizedOptionsForSelect } from '@/utils/creating_localized_options_for_selects'
import MainSearchFilteringBlock from '@/components/MainSearchFilteringBlock'
import PaginationBlock from '@/components/PaginationBlock'
import { getNoun } from '@/utils/get_noun'
import { convertingSecondsToTimeInCustomFormat } from '@/utils/converting_seconds_to_time_in_custom_format'

export default {
  props: ['current_language', 'localizationData'],

  emits: ['openBlockWithDetailedOrderData', 'afterGetDishesData'],

  components: {
    MainSearchFilteringBlock: MainSearchFilteringBlock,
    PaginationBlock: PaginationBlock
  },

  computed: {
    // Получаем options для фильтра Статус заказа.
    creatingLocalizedOptionsForOrderStatus() {
      const localization_data_for_select =
        this.localizationData.ordersBlock.mainBlockSelectWithoutIcon[
          this.current_language
        ]
      const config_options = this.valueOfOptionsForOrderStatus
      const options = creatingLocalizedOptionsForSelect(
        localization_data_for_select,
        config_options
      )
      return options
    }
  },

  created() {
    this.getOrders()
  },

  data() {
    return {
      orders: [],
      nameActiveTheme: nameActiveTheme,
      ordersTableItems: ordersTableItems,
      valueOfOptionsForOrderStatus: valueOfOptionsForOrderStatus,
      searchTableInput: '',
      selectWithoutIconValue: '',
      datePickerValue: null,
      ordersCount: 0,
      currentPage: 1,
      keyPaginationBlock: 0,
      dropdownMoreFilledIsVisible: false,
      currentOrderId: ''
    }
  },

  methods: {
    // Получаем список заказов.
    getOrders(data) {
      let prepared_query_params

      if (data && data.signal === 'changeCurrentPaginationPage') {
        this.currentPage = data.updatePage
      }

      if (data && data.signal === 'changeFilterSearchValue') {
        // Если в метод переданы параметры общего фильтра.
        // Сбрасываем текущую страницу пагинации на 1.
        this.currentPage = 1

        // Сохраняем текущие параметры поиска и фильтра Статус в data.
        this.searchTableInput = data.searchTableInput.toLowerCase()
        this.selectWithoutIconValue = data.selectWithoutIconValue
        this.datePickerValue = data.datePickerValue
      }

      // Формируем итоговый query params для страницы пагинации,
      // поиска, фильтра Статус, общего фильтра.
      prepared_query_params =
        '?page=' +
        this.currentPage +
        '&search=' +
        this.searchTableInput +
        '&status=' +
        this.selectWithoutIconValue +
        '&created=' +
        this.datePickerValue

      OrdersDataService.get(prepared_query_params)
        .then(res => {
          if (res) {
            this.orders = res.data.results
            this.ordersCount = res.data.count

            if (data && data.signal === 'changeFilterSearchValue') {
              this.forcePaginationBlock()
            }
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Получаем количество порций в заказе.
    getNumberServings(order_dish) {
      return (
        order_dish.length +
        ' ' +
        getNoun(order_dish.length, 'порция', 'порции', 'порций')
      )
    },

    // Получаем суммарное время приготовления всех блюд в заказе в пользовательском формате.
    getRoastingTimeInUserFormat(roasting_time) {
      const roastingTimeInUserFormat =
        convertingSecondsToTimeInCustomFormat(roasting_time)
      return roastingTimeInUserFormat
    },

    // Запишем в data id текущего заказа.
    writeCurrentTableRowId(currentOrderId) {
      this.currentOrderId = currentOrderId
    },

    // Открываем блок с подробными данными заказа.
    openBlockWithDetailedOrderData(order) {
      this.$emit('openBlockWithDetailedOrderData', order)
    },

    // Делаем перерендеринг блока пагинации.
    forcePaginationBlock() {
      this.keyPaginationBlock += 1
    }
  }
}
</script>
