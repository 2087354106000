<template>
  <el-pagination
    class="wrapper-pagination-block"
    v-model:pager-count="pagerCount"
    v-model:page-size="pageSize"
    :total="total"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="prev, pager, next"
    hide-on-single-page
    @current-change="changeCurrentPage"
  />
</template>

<script>
export default {
  props: ['total'],
  emits: ['changeCurrentPaginationPage'],

  data() {
    return {
      pagerCount: 7, // Максимальное количество отображаемых кнопок страниц в пагинации.
      pageSize: 20, // Количество объектов на странице. Должно совпадать с настройками на бэкенде!
      small: false,
      disabled: false,
      background: true
    }
  },

  methods: {
    // При выборе страницы пагинации, передаем данные в родительский компонент.
    changeCurrentPage(page) {
      this.$emit('changeCurrentPaginationPage', {
        signal: 'changeCurrentPaginationPage',
        updatePage: page
      })
    }
  }
}
</script>
