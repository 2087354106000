<template>
  <div class="main-block-wrapper dish-edit-page-wrapper">
    <!-- Заголовок блока редактирования. -->
    <editBlockHeader
      :editBlockHeaderLinkButton="'Все блюда'"
      :editBlockHeaderTitle="editBlockHeaderTitle"
      @toAllObjects="toAllDishes"
    />

    <!-- Блок Шаги создания/редактирования. -->
    <editBlockSteps
      :activeStep="activeStep"
      :arrEditStepsTitles="arrEditStepsTitles"
    />

    <!-- Тело блока редактирования. -->
    <div class="edit-block-body">
      <div
        v-show="activeStep === 0"
        class="edit-block-body-step-information-wrapper"
      >
        <div class="one-step-information-image-block">
          <!-- Элемент upload для загрузки/удаления изображения блюда. -->
          <el-upload
            ref="upload"
            :class="{
              is_hide: hideUploadEdit
            }"
            v-model:file-list="imageFileList"
            list-type="picture-card"
            action="#"
            :on-change="handleEditChange"
            :limit="1"
            :auto-upload="false"
            accept=".jpeg, .jpg, .png, .gif"
            :disabled="imageFileList.length > 0"
            drag
          >
            <!-- Drag элемент загрузки картинки. -->
            <div class="upload-drop-block-content">
              <div>
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  Перетащите файл с изображением блюда сюда или
                  <em>нажмите, чтобы загрузить</em>
                </div>
              </div>
            </div>

            <!-- Загруженное изображение блюда. -->
            <template #file="{ file }">
              <div class="upload-image-wrapper">
                <img
                  v-if="
                    imageFileList.length > 0 &&
                    currentEditObject.image === imageFileList[0].url
                  "
                  class="el-upload-list__item-thumbnail"
                  :src="baseURL + file.url"
                  alt=""
                />
                <img
                  v-if="
                    imageFileList.length > 0 &&
                    currentEditObject.image !== imageFileList[0].url
                  "
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />

                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="openModalRemoveImage"
                  >
                    <el-icon><Delete /></el-icon>
                  </span>
                </span>
              </div>
            </template>

            <!-- Подпись о типе и размере файла, который возможно загрузить -->
            <template v-if="!hideUploadEdit" #tip>
              <div class="el-upload__tip">
                файл jpeg/jpg/png/gif размером не более 5 Мб
              </div>
            </template>
          </el-upload>
        </div>

        <div class="one-step-information-description-block">
          <!-- Блок редактирования Названия и Описания рецепта. -->
          <div class="step-setting-block">
            <div class="cooking-step-title">Информация о блюде</div>
            <el-form
              v-model="currentEditDish"
              label-position="left"
              label-width="0px"
              size="large"
            >
              <div>
                <div class="input-edit-object-block-placeholder">Название</div>
                <el-input
                  v-model="currentEditDish.name"
                  class="input-component-edit-block"
                  size="large"
                  placeholder="Укажите название блюда"
                />
              </div>
              <div>
                <div class="input-edit-object-block-placeholder">Описание</div>
                <el-input
                  v-model="currentEditDish.description"
                  class="input-component-edit-block last-input-component-edit-block"
                  size="large"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 8 }"
                  resize="none"
                  placeholder="Укажите описание блюда"
                />
              </div>
            </el-form>
          </div>
        </div>
      </div>

      <!-- Тело блока редактирования шагов. -->
      <div v-show="activeStep === 1" class="edit-block-body-steps-wrapper">
        <!-- Блок редактирования шагов рецепта (блок слева). -->

        <div class="edit-block-body-steps-block">
          <div class="start-recipe-step" @click="currentEditRecipeStep = ''">
            <div class="step-title">Старт</div>
          </div>
          <div class="recipe-step-plus">
            <el-dropdown
              trigger="click"
              @visible-change="saveStateStepPlusDropDownVisibleChange"
              @command="addStep"
            >
              <el-button
                icon="Plus"
                text
                :class="{
                  step_plus_dropdown_is_visible:
                    stepPlusDropDownIsVisible === true &&
                    currentStepIndex === 'start_step'
                }"
                @click="saveCurrentStepIndex('start_step')"
              />
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :command="{
                      step_type: 'supply',
                      step_number: 0
                    }"
                  >
                    Добавить ингредиент
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{
                      step_type: 'cooking',
                      step_number: 0
                    }"
                    >Готовка</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

          <el-scrollbar max-height="500px">
            <Container
              @drop="onDrop"
              lock-axis="y"
              drag-handle-selector=".recipe-step-icon-drag"
            >
              <Draggable
                v-for="(step, index) in currentEditDish.steps"
                :key="step.id"
              >
                <div
                  class="recipe-step"
                  :class="{
                    first_child: index === 0,
                    last_child: index === currentEditDish.steps.length - 1,
                    current_edit_recipe_step:
                      currentEditRecipeStep.id === step.id
                  }"
                  @click="saveCurrentEditRecipeStep(index)"
                >
                  <div class="recipe-step-content-block">
                    <div
                      class="step-left-decor"
                      :class="{
                        warning:
                          step.step_type === 'supply' &&
                          step.dish_ingredient !== null &&
                          step.dish_ingredient.ingredient.image === null,
                        danger:
                          (step.step_type === 'supply' &&
                            step.dish_ingredient === null) ||
                          (step.step_type === 'cooking' &&
                            (!step.roasting_time ||
                              !step.rotation_speed ||
                              !step.temperature))
                      }"
                    />

                    <img
                      v-if="
                        step.step_type === 'supply' &&
                        step.dish_ingredient !== null &&
                        step.dish_ingredient.ingredient.image !== null
                      "
                      :src="baseURL + step.dish_ingredient.ingredient.image"
                      class="recipe-step-image"
                    />
                    <img
                      v-if="
                        step.step_type === 'supply' &&
                        (!step.dish_ingredient ||
                          (step.dish_ingredient !== null &&
                            step.dish_ingredient.ingredient.image === null))
                      "
                      :src="require(`@/assets/images/no_photo.jpeg`)"
                      class="recipe-step-image"
                    />

                    <div>
                      <div class="step-title">
                        <div
                          class="supply-step-title"
                          v-if="
                            step.step_type === 'supply' && step.dish_ingredient
                          "
                        >
                          <div>
                            {{
                              step.dish_ingredient.ingredient.name
                                .charAt(0)
                                .toUpperCase() +
                              step.dish_ingredient.ingredient.name.slice(1)
                            }}
                          </div>

                          <el-icon
                            v-if="
                              step.dish_ingredient.ingredient.validity ===
                              'valid'
                            "
                            class="table-tag-icon"
                            :size="16"
                          >
                            <CircleCheckFilled />
                          </el-icon>
                        </div>

                        <div
                          v-if="
                            step.step_type === 'supply' && !step.dish_ingredient
                          "
                        >
                          Ингредиент не выбран
                        </div>

                        <div
                          v-if="
                            step.step_type === 'cooking' &&
                            step.roasting_time &&
                            step.rotation_speed &&
                            step.temperature
                          "
                        >
                          Готовка
                        </div>
                        <div
                          v-if="
                            step.step_type === 'cooking' &&
                            (!step.roasting_time ||
                              !step.rotation_speed ||
                              !step.temperature)
                          "
                        >
                          Готовка (не настроено)
                        </div>
                      </div>

                      <div class="step-description">
                        <div
                          v-if="
                            step.step_type === 'supply' &&
                            step.dish_ingredient &&
                            step.dish_ingredient.amount
                          "
                        >
                          {{
                            step.dish_ingredient.amount +
                            ' ' +
                            step.dish_ingredient.ingredient.measurement_unit
                          }}
                        </div>
                        <div
                          v-if="
                            step.step_type === 'supply' &&
                            step.dish_ingredient &&
                            !step.dish_ingredient.amount
                          "
                        >
                          Количество не указано
                        </div>
                        <div
                          v-if="
                            step.step_type === 'supply' && !step.dish_ingredient
                          "
                          class="supply-step-title-none-amount"
                        >
                          Укажите
                          <span v-if="!step.dish_ingredient"
                            >ингредиент и его количество</span
                          >
                          <span
                            v-if="
                              step.dish_ingredient &&
                              !step.dish_ingredient.amount
                            "
                          >
                            количество ингредиента
                          </span>
                        </div>

                        <div
                          v-if="
                            step.step_type === 'cooking' &&
                            step.roasting_time &&
                            step.rotation_speed &&
                            step.temperature
                          "
                        >
                          {{
                            step.roasting_time +
                            ' сек | ' +
                            step.rotation_speed +
                            ' об/мин | ' +
                            step.temperature +
                            ' ℃'
                          }}
                        </div>
                        <div
                          v-if="
                            step.step_type === 'cooking' &&
                            (!step.roasting_time ||
                              !step.rotation_speed ||
                              !step.temperature)
                          "
                        >
                          Укажите
                          <span v-if="!step.roasting_time">время готовки </span>
                          <span
                            v-if="
                              !step.roasting_time &&
                              !step.rotation_speed &&
                              !step.temperature
                            "
                            style="margin-left: -4px"
                          >
                            ,
                          </span>
                          <span
                            v-if="
                              !step.roasting_time &&
                              !step.rotation_speed &&
                              step.temperature
                            "
                          >
                            и
                          </span>
                          <span v-if="!step.rotation_speed"
                            >скорость вращения</span
                          >
                          <span
                            v-if="
                              (!step.roasting_time || !step.rotation_speed) &&
                              !step.temperature
                            "
                          >
                            и
                          </span>
                          <span v-if="!step.temperature">
                            температуру нагрева
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="recipe-step-icon-buttons">
                    <el-tooltip
                      placement="bottom"
                      effect="light"
                      popper-class="base-custom"
                      :show-after="500"
                    >
                      <template #content>
                        <div>Удалить шаг рецепта</div>
                      </template>
                      <el-icon
                        class="recipe-step-icon recipe-step-icon-delete"
                        :size="20"
                        @click="openModalRemoveStep"
                      >
                        <Delete />
                      </el-icon>
                    </el-tooltip>

                    <el-tooltip
                      placement="bottom"
                      effect="light"
                      popper-class="base-custom"
                      :show-after="500"
                    >
                      <template #content>
                        <div>Переместить шаг рецепта</div>
                      </template>
                      <el-icon
                        class="recipe-step-icon recipe-step-icon-drag"
                        :size="20"
                      >
                        <DragVertical />
                      </el-icon>
                    </el-tooltip>
                  </div>
                </div>

                <div class="recipe-step-plus">
                  <el-dropdown
                    ref="dropdownStepPlus"
                    trigger="click"
                    @visible-change="saveStateStepPlusDropDownVisibleChange"
                    @command="addStep"
                  >
                    <el-button
                      icon="Plus"
                      text
                      :class="{
                        step_plus_dropdown_is_visible:
                          stepPlusDropDownIsVisible === true &&
                          currentStepIndex === index
                      }"
                      @click="saveCurrentStepIndex(index)"
                    />
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="{
                            step_type: 'supply',
                            step_number: step.step_number
                          }"
                        >
                          Добавить ингредиент
                        </el-dropdown-item>
                        <el-dropdown-item
                          :command="{
                            step_type: 'cooking',
                            step_number: step.step_number
                          }"
                        >
                          Готовка
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </Draggable>
            </Container>
          </el-scrollbar>
        </div>

        <!-- Блок редактирования настроек шага рецепта (блок справа). -->
        <div class="edit-block-body-step-setting-block">
          <!-- Отображаем, если шаг не выбран. -->
          <div
            v-if="currentEditRecipeStep === ''"
            class="step-setting-explanation-block-wrapper"
          >
            <div class="step-setting-explanation-block">
              <div
                v-if="
                  currentEditObject.steps && currentEditObject.steps.length > 0
                "
                class="step-setting-explanation-block-title"
              >
                Для редактирования шага,<br />
                кликните по нему
              </div>
              <div v-else class="step-setting-explanation-block-title">
                Создайте первый шаг рецепта
              </div>

              <div class="step-setting-explanation-block-turn">
                <TurnDownLeft />
              </div>
            </div>
          </div>

          <!-- Блок редактирования шага Добавление ингредиента. -->
          <div v-if="currentEditRecipeStep.step_type === 'supply'">
            <div class="step-setting-block">
              <img
                v-if="
                  currentEditRecipeStep.dish_ingredient !== null &&
                  currentEditRecipeStep.dish_ingredient.ingredient.image !==
                    null
                "
                :src="
                  baseURL +
                  currentEditRecipeStep.dish_ingredient.ingredient.image
                "
                class="edit-recipe-step-image"
              />
              <img
                v-if="
                  currentEditRecipeStep.dish_ingredient !== null &&
                  currentEditRecipeStep.dish_ingredient.ingredient.image ===
                    null
                "
                :src="require(`@/assets/images/no_photo.jpeg`)"
                class="edit-recipe-step-image"
              />

              <el-form
                :model="editSupplyForm"
                label-position="left"
                label-width="0px"
                size="large"
              >
                <div class="step-setting-block-edit-step">
                  <div class="input-edit-object-block-placeholder">
                    Ингредиент
                  </div>
                  <el-select
                    v-model="editSupplyForm.ingredientName"
                    class="main-block-select select-without-icon select-component-edit-block"
                    size="large"
                    :placeholder="'Начните набирать название'"
                    :remote-method="getIngredients"
                    :loading="loading"
                    :loading-text="'Загрузка'"
                    remote
                    filterable
                    default-first-option
                    remote-show-suffix
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name_with_capital_letter"
                      :value="item.name"
                    />
                  </el-select>
                </div>

                <div>
                  <div class="input-edit-object-block-placeholder">
                    Количество, {{ editSupplyForm.measurementUnit }}
                  </div>
                  <el-input-number
                    v-model="editSupplyForm.ingredientQuantity"
                    class="input-component-edit-block last-input-component-edit-block"
                    :min="1"
                    controls-position="right"
                    size="large"
                  />
                </div>
              </el-form>
            </div>
          </div>

          <!-- Блок редактирования шага Готовка. -->
          <div v-if="currentEditRecipeStep.step_type === 'cooking'">
            <div class="step-setting-block">
              <div class="cooking-step-title">Готовка</div>
              <el-form
                :model="editCookingForm"
                label-position="left"
                label-width="0px"
                size="large"
              >
                <div>
                  <div class="input-edit-object-block-placeholder">
                    Время готовки, сек
                  </div>
                  <el-input-number
                    v-model="editCookingForm.roastingTime"
                    class="input-component-edit-block"
                    :min="1"
                    controls-position="right"
                    size="large"
                  />
                </div>
                <div>
                  <div class="input-edit-object-block-placeholder">
                    Скорость вращения, об/мин
                  </div>
                  <el-input-number
                    v-model="editCookingForm.rotationSpeed"
                    class="input-component-edit-block"
                    :min="1"
                    controls-position="right"
                    size="large"
                  />
                </div>
                <div>
                  <div class="input-edit-object-block-placeholder">
                    Температура нагрева, ℃
                  </div>
                  <el-input-number
                    v-model="editCookingForm.stovePower"
                    class="input-component-edit-block last-input-component-edit-block"
                    :min="0"
                    :max="290"
                    controls-position="right"
                    size="large"
                  />
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="edit-block-save-panel"
    :class="{
      is_collapse: isCollapse === true
    }"
  >
    <div class="edit-block-save-panel-buttons-wrapper">
      <div>
        <el-button
          v-if="activeStep !== 0"
          class="default-black-button edit-block-save-panel-back-button"
          size="large"
          :disabled="false"
          @click="returnToPreviousEditDishStep"
        >
          Назад
        </el-button>
      </div>
      <div class="edit-block-save-panel-right-buttons-block">
        <el-button
          v-if="!currentEditObject"
          class="default-black-button"
          size="large"
          :disabled="createSaveDishButtonIsDisabled"
          @click="createDish"
        >
          Создать блюдо
        </el-button>
        <el-button
          v-if="currentEditObject"
          class="default-black-button"
          size="large"
          :disabled="createSaveDishButtonIsDisabled"
          @click="updateDish"
        >
          Сохранить
        </el-button>
        <el-button
          v-if="activeStep < arrEditStepsTitles.length - 1 && currentEditObject"
          class="black-fill-button"
          size="large"
          :disabled="false"
          @click="goToNextEditDishStep"
        >
          Продолжить
        </el-button>
      </div>
    </div>
  </div>

  <!-- Модальное окно Удаления изображения блюда -->
  <BaseModal ref="modalRemoveImage">
    <template v-slot:header> Удалить изображение блюда? </template>

    <template v-slot:body>
      <p>Текущее изображение блюда будет удалено.</p>
      <p>Вы сможете загрузить новое изображение.</p>
    </template>

    <template v-slot:footer>
      <el-button type="danger" size="large" @click="handleRemoveImage">
        Удалить
      </el-button>
      <el-button plain size="large" @click="cancelDeleteCandidateCard"
        >Отмена</el-button
      >
    </template>
  </BaseModal>

  <!-- Модальное окно Удаления шага блюда -->
  <BaseModal ref="modalRemoveStep">
    <template v-slot:header> Удалить шаг? </template>

    <template v-slot:body>
      <p>Редактируемый шаг блюда будет удален.</p>
    </template>

    <template v-slot:footer>
      <el-button type="danger" size="large" @click="handleRemoveStep">
        Удалить
      </el-button>
      <el-button plain size="large" @click="cancelDeleteStep">Отмена</el-button>
    </template>
  </BaseModal>
</template>

<script>
import editBlockHeader from '@/components/editBlockHeader'
import editBlockSteps from '@/components/editBlockSteps'
import BaseModal from '@/components/modals/BaseModal'
import { Container, Draggable } from 'vue-dndrop'
import { applyDrag } from '@/utils/vue-dndrop'
import clone from 'just-clone'
import DishDataService from '@/services/DishDataService'
import IngredientDataService from '@/services/IngredientDataService'
import DishIngredientDataService from '@/services/DishIngredientDataService'
import StepDataService from '@/services/StepDataService'
import DragVertical from '@/components/icons/DragVertical'
import TurnDownLeft from '@/components/icons/TurnDownLeft'
import { capitalizeFirstLetter } from '@/utils/capitalize_first_letter'
import {
  returnToPreviousStep,
  goToNextStep
} from '@/utils/changing_step_in_sequence_of_steps'
import messages from '@/utils/messages'

export default {
  components: {
    editBlockHeader: editBlockHeader,
    editBlockSteps: editBlockSteps,
    BaseModal: BaseModal,
    Container: Container,
    Draggable: Draggable,
    DragVertical: DragVertical,
    TurnDownLeft: TurnDownLeft
  },

  props: ['currentEditObject', 'isCollapse'],

  emits: ['toAllDishes', 'createdDish', 'updatedDish'],

  computed: {
    // Скрываем элемент загрузки картинки upload.
    hideUploadEdit() {
      if (this.imageFileList.length > 0) {
        return true
      } else {
        return false
      }
    },
    // Получаем заголовок блока редактирования.
    editBlockHeaderTitle() {
      if (this.currentEditObject) {
        return 'РЕДАКТИРОВАНИЕ БЛЮДА'
      } else {
        return 'СОЗДАНИЕ БЛЮДА'
      }
    }
  },

  watch: {
    // Следим за изменением названия блюда.
    'currentEditDish.name': function () {
      this.changingDishButtonActivity()
    },
    // Следим за изменением описания блюда.
    'currentEditDish.description': function () {
      this.changingDishButtonActivity()
    },
    // Следим за изменением описания блюда.
    'currentEditDish.image': function () {
      this.changingDishButtonActivity()
    },
    // Следим за изменением имени ингредиента.
    'editSupplyForm.ingredientName': function () {
      this.changingDishButtonActivity()
    },
    // Следим за изменением количества ингредиента.
    'editSupplyForm.ingredientQuantity': function () {
      this.changingDishButtonActivity()
    },

    // Следим за изменением времени готовки.
    'editCookingForm.roastingTime': function () {
      this.changingDishButtonActivity()
    },
    // Следим за изменением скорости вращения.
    'editCookingForm.rotationSpeed': function () {
      this.changingDishButtonActivity()
    },
    // Следим за изменением мощности нагрева.
    'editCookingForm.stovePower': function () {
      this.changingDishButtonActivity()
    },
    // Отслеживаем какое-либо изменение в currentEditObject.
    currentEditObject: {
      handler: function () {
        this.savecurrentEditDish()

        if (this.currentEditDish && this.currentEditDish.steps.length > 0) {
          const currentEditRecipeStepId = this.currentEditRecipeStep.id
          let i
          let len
          for (i = 0, len = this.currentEditDish.steps.length; i < len; ++i) {
            if (currentEditRecipeStepId === this.currentEditDish.steps[i].id) {
              this.currentEditRecipeStep = this.currentEditDish.steps[i]
            }
          }
        }
      },
      deep: true
    }
  },

  mounted() {
    this.savecurrentEditDish()
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      imageFileList: [],
      fileList: [],
      activeStep: 0,
      arrEditStepsTitles: ['Информация о блюде', 'Рецепт'],
      stepPlusDropDownIsVisible: false,
      createSaveDishButtonIsDisabled: true, // Значение активности кнопки создания блюда.s
      currentEditDish: {
        name: '',
        description: '',
        image: null
      }, // Объект текущего редактируемого блюда.
      currentStepIndex: '', // Индекс текущего шага (для дропдауна создания нового шага).
      currentEditRecipeStep: '', // Объект редактируемого шага.
      editSupplyForm: {
        ingredientName: '',
        ingredientQuantity: 1,
        measurementUnit: ''
      },
      editCookingForm: {
        roastingTime: 0,
        rotationSpeed: 0,
        stovePower: 0
      },
      loading: false, // Для отображения состояния загрузки списка ингредиентов (в блоке редактирования шага).
      options: [], // Опции выпадающего списка с ингредиентами (в блоке редактирования шага).
      duplicateOptions: []
    }
  },

  methods: {
    // Добавляем шаг блюда.
    addStep(command) {
      let data = {
        step_number: command.step_number + 1,
        step_type: command.step_type,
        dish: this.currentEditObject.id
      }

      StepDataService.create(data)
        .then(res => {
          if (res) {
            this.$emit('updatedDish')
          }
        })
        .catch(err => {
          console.error(err)
        })
    },

    // Сохраняем Объект текущего редактируемого блюда.
    savecurrentEditDish() {
      if (this.currentEditObject) {
        this.currentEditDish = clone(this.currentEditObject)

        if (this.currentEditObject.image) {
          this.imageFileList = [
            {
              url: this.currentEditObject.image
            }
          ]
        }
      }
    },

    // Функция перехвата при выборе файла, успешной загрузке файла или ошибке загрузки файла.
    handleEditChange(file) {
      let imgSize = Number(file.size / 1024 / 1024)

      if (imgSize > 5) {
        this.$message(
          messages['size_of_uploaded_image_should_not_exceed_5_mb'].message,
          messages['size_of_uploaded_image_should_not_exceed_5_mb'].type
        )
        this.imageFileList = []
        this.currentEditDish.image = null

        return
      }

      // Читаем файл изображения, кодируем его в base64 и сохраняем в data.
      let reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = event => {
        this.currentEditDish.image = event.target.result
      }
    },

    // Создаем блюдо.
    createDish() {
      this.createSaveDishButtonIsDisabled = true

      let data = {
        name: this.currentEditDish.name,
        description: this.currentEditDish.description,
        image: this.currentEditDish.image
      }

      DishDataService.create(data)
        .then(res => {
          this.$emit('createdDish', res.data)
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Получить id выбранного ингредиента (в форме редактирования шага).
    getIdOfSelectedIngredient(options, ingredientName) {
      let i
      let len
      for (i = 0, len = options.length; i < len; ++i) {
        if (options[i].name === ingredientName.toLowerCase()) {
          return options[i].id
        }
      }
    },

    // Сохраняем блюдо.
    updateDish() {
      this.createSaveDishButtonIsDisabled = true

      // Если этап редактирования Информации о блюде (1), то update блюда.
      if (this.activeStep === 0) {
        let data = {
          name: this.currentEditDish.name,
          description: this.currentEditDish.description
        }

        if (this.currentEditDish.image !== this.currentEditObject.image) {
          data.image = this.currentEditDish.image
        }

        DishDataService.update(data, this.currentEditDish.id)
          .then(res => {
            if (res) {
              this.$emit('updatedDish')
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })
      }

      // Если блок редактирования Рецепт (2) и редактируем шаг Ингредиент,
      // то подготавливаем data запроса update объекта DishIngredient.
      // В data добавляем поля: ingredient и/или amount.
      if (
        this.activeStep === 1 &&
        this.currentEditRecipeStep.step_type === 'supply'
      ) {
        // Если ингредиент в шаге уже существует (ранее был создан DishIngredient)
        if (this.currentEditRecipeStep.dish_ingredient) {
          const dish_ingredient_id =
            this.currentEditRecipeStep.dish_ingredient.id
          let dish_ingredient_data = {}

          // Если был изменен Ингредиент в форме редактирования,
          // добавляем id нового ингредиента в data запроса.
          if (
            this.editSupplyForm.ingredientName.toLowerCase() !==
            this.currentEditRecipeStep.dish_ingredient.ingredient.name
          ) {
            dish_ingredient_data.ingredient = this.getIdOfSelectedIngredient(
              this.duplicateOptions,
              this.editSupplyForm.ingredientName
            )
          }
          // Если Количество ингредиента было изменено в форме редактирования,
          // добавляем новое значение в data запроса.
          if (
            this.editSupplyForm.ingredientQuantity !==
            this.currentEditRecipeStep.dish_ingredient.amount
          ) {
            dish_ingredient_data.amount = this.editSupplyForm.ingredientQuantity
          }

          DishIngredientDataService.update(
            dish_ingredient_data,
            dish_ingredient_id
          )
            .then(res => {
              if (res) {
                this.$emit('updatedDish')
              }
            })
            .catch(error => {
              if (error) {
                this.$error('Что-то пошло не так')
              }
            })
        } else {
          // иначе, если ингредиент в шаге еще не создан (отутствует DishIngredient)
          let ingredient_id = ''
          // Если был изменен Ингредиент в форме редактирования,
          // добавляем id нового ингредиента в data запроса.
          if (this.editSupplyForm.ingredientName.toLowerCase() !== '') {
            ingredient_id = this.getIdOfSelectedIngredient(
              this.duplicateOptions,
              this.editSupplyForm.ingredientName
            )
          }

          let data = {
            dish: this.currentEditDish.id,
            ingredient: ingredient_id,
            amount: this.editSupplyForm.ingredientQuantity
          }
          let params = {
            params: {
              step_id: this.currentEditRecipeStep.id
            }
          }

          DishIngredientDataService.create(data, params)
            .then(res => {
              if (res) {
                this.$emit('updatedDish')
              }
            })
            .catch(error => {
              if (error) {
                this.$error('Что-то пошло не так')
              }
            })
        }
      }

      // Если блок редактирования Рецепт (2) и редактируем шаг Готовка,
      // то подготавливаем data запроса update объекта Step.
      // В data добавляем поля: roasting_time и/или rotation_speed и/или temperature.
      if (
        this.activeStep === 1 &&
        this.currentEditRecipeStep.step_type === 'cooking'
      ) {
        const step_id = this.currentEditRecipeStep.id
        let step_data = {}

        // Если Время готовки было изменено в форме редактирования,
        // добавляем новое значение в data запроса.
        if (
          this.editCookingForm.roastingTime !==
          this.currentEditRecipeStep.roasting_time
        ) {
          step_data.roasting_time = this.editCookingForm.roastingTime
        }
        // Если Скорость вращения была изменена в форме редактирования,
        // добавляем новое значение в data запроса.
        if (
          this.editCookingForm.rotationSpeed !==
          this.currentEditRecipeStep.rotation_speed
        ) {
          step_data.rotation_speed = this.editCookingForm.rotationSpeed
        }
        // Если Температура нагрева была изменена в форме редактирования,
        // добавляем новое значение в data запроса.
        if (
          this.editCookingForm.stovePower !==
          this.currentEditRecipeStep.temperature
        ) {
          step_data.temperature = this.editCookingForm.stovePower
        }

        StepDataService.update(step_data, step_id)
          .then(res => {
            if (res) {
              this.$emit('updatedDish')
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так')
            }
          })
      }
    },

    // Измененяем активность кнопки создания блюда.
    changingDishButtonActivity() {
      if (
        (this.activeStep === 0 &&
          this.currentEditDish.name.length >= 2 &&
          this.currentEditDish.description.length >= 2 &&
          (this.currentEditDish.name !== this.currentEditObject.name ||
            this.currentEditDish.description !==
              this.currentEditObject.description ||
            this.currentEditDish.image !== this.currentEditObject.image)) ||
        (this.activeStep === 1 &&
          this.currentEditRecipeStep.step_type === 'supply' &&
          this.currentEditRecipeStep.dish_ingredient &&
          (this.editSupplyForm.ingredientName.toLowerCase() !==
            this.currentEditRecipeStep.dish_ingredient.ingredient.name ||
            this.editSupplyForm.ingredientQuantity !==
              this.currentEditRecipeStep.dish_ingredient.amount)) ||
        (this.currentEditRecipeStep.step_type === 'supply' &&
          !this.currentEditRecipeStep.dish_ingredient &&
          this.editSupplyForm.ingredientName.toLowerCase() !== '') ||
        (this.activeStep === 1 &&
          this.currentEditRecipeStep.step_type === 'cooking' &&
          (this.editCookingForm.roastingTime !==
            this.currentEditRecipeStep.roasting_time ||
            this.editCookingForm.rotationSpeed !==
              this.currentEditRecipeStep.rotation_speed ||
            this.editCookingForm.stovePower !==
              this.currentEditRecipeStep.temperature))
      ) {
        this.createSaveDishButtonIsDisabled = false
      } else {
        this.createSaveDishButtonIsDisabled = true
      }
    },

    // Сохраняем Объект редактируемого шага.
    saveCurrentEditRecipeStep(indexCurrentEditRecipeStep) {
      if (
        this.currentEditRecipeStep.id !==
        this.currentEditDish.steps[indexCurrentEditRecipeStep].id
      ) {
        this.currentEditRecipeStep =
          this.currentEditDish.steps[indexCurrentEditRecipeStep]

        if (this.currentEditRecipeStep.step_type === 'supply') {
          if (this.currentEditRecipeStep.dish_ingredient) {
            if (this.currentEditRecipeStep.dish_ingredient.ingredient) {
              this.editSupplyForm.ingredientName =
                this.currentEditRecipeStep.dish_ingredient.ingredient.name
                  .charAt(0)
                  .toUpperCase() +
                this.currentEditRecipeStep.dish_ingredient.ingredient.name.slice(
                  1
                )

              this.editSupplyForm.measurementUnit =
                this.currentEditRecipeStep.dish_ingredient.ingredient.measurement_unit
            } else {
              this.editSupplyForm.ingredientName = ''
              this.editSupplyForm.measurementUnit = ''
            }

            if (this.currentEditRecipeStep.dish_ingredient.amount) {
              this.editSupplyForm.ingredientQuantity =
                this.currentEditRecipeStep.dish_ingredient.amount
            } else {
              this.editSupplyForm.ingredientQuantity = 1
            }
          } else {
            this.editSupplyForm.ingredientName = ''
            this.editSupplyForm.ingredientQuantity = 1
            this.editSupplyForm.measurementUnit = ''
          }
        }

        if (this.currentEditRecipeStep.step_type === 'cooking') {
          this.editCookingForm.roastingTime =
            this.currentEditRecipeStep.roasting_time
          this.editCookingForm.rotationSpeed =
            this.currentEditRecipeStep.rotation_speed
          this.editCookingForm.stovePower =
            this.currentEditRecipeStep.temperature
        }
      }
    },

    // Получаем список ингредиентов с сервера, содержащих в названии строку,
    // указанную в запросе.
    getIngredients(query) {
      if (query) {
        this.loading = true
        let data = {
          search: query,
          currentEditDishId: this.currentEditObject.id
        }

        setTimeout(() => {
          IngredientDataService.getListForDishEdit(data)
            .then(res => {
              this.loading = false
              let updateOptions = res
              let i
              let len
              for (i = 0, len = res.length; i < len; ++i) {
                let updateOption = capitalizeFirstLetter(res[i].name)
                updateOptions[i]['name_with_capital_letter'] = updateOption
              }
              this.options = updateOptions
              this.duplicateOptions = updateOptions
            })
            .catch(error => {
              if (error) {
                this.$error('Что-то пошло не так')
              }
            })
        }, 200)
      } else {
        this.options = []
      }
    },

    // Подготавливаем значение id перемещаемого шага
    // и порядковый номер данного шага после завершения перемещения.
    prepareStepIdAndParamsData(dropResult, i) {
      const deltaIndexes = dropResult.removedIndex - dropResult.addedIndex
      const step_id = this.currentEditDish.steps[i].id
      const step_number =
        this.currentEditDish.steps[i].step_number - deltaIndexes

      return { edit_step_id: step_id, step_number: step_number }
    },

    // Меняем последовательность шагов рецепта в currentEditDish (drag-n-drop).
    onDrop(dropResult) {
      let step_id
      let data = {}
      let params_data = {
        params: {}
      }
      let i
      let len

      // Формируем объект data: где ключ элемента - это id шага,
      // значение ключа элемента - это новый порядковый номер шага (step_number).
      for (i = 0, len = this.currentEditDish.steps.length; i < len; ++i) {
        // Обрабатываем перемещение (drag-and-drop) шага вверх по списку шагов:
        if (dropResult.removedIndex > dropResult.addedIndex) {
          // Подготавливаем данные для изменения порядкового номера перемещенного шага блюда.
          if (i === dropResult.removedIndex) {
            const { edit_step_id, step_number } =
              this.prepareStepIdAndParamsData(dropResult, i)
            step_id = edit_step_id
            data['step_number'] = step_number
          }

          // Подготавливаем данные для изменения порядковых номеров всех шагов блюда,
          // которые изменяются в связи с перемещением редактируемого шага.
          if (dropResult.addedIndex <= i && i < dropResult.removedIndex) {
            params_data['params'][this.currentEditDish.steps[i].id] =
              this.currentEditDish.steps[i].step_number + 1
          }
        }

        // Обрабатываем перемещение (drag-and-drop) шага вниз по списку шагов:
        if (dropResult.removedIndex < dropResult.addedIndex) {
          // Подготавливаем данные для изменения порядкового номера перемещенного шага блюда.
          if (i === dropResult.removedIndex) {
            const { edit_step_id, step_number } =
              this.prepareStepIdAndParamsData(dropResult, i)
            step_id = edit_step_id
            data['step_number'] = step_number
          }

          // Подготавливаем данные для изменения порядковых номеров всех шагов блюда,
          // которые изменяются в связи с перемещением редактируемого шага.
          if (dropResult.addedIndex >= i && i > dropResult.removedIndex) {
            params_data['params'][this.currentEditDish.steps[i].id] =
              this.currentEditDish.steps[i].step_number - 1
          }
        }
      }

      // Изменяем порядковые номера шагов на сервере,
      // если перемещение (drag-and-drop) было фактически выполнено.
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        StepDataService.update(data, step_id, params_data)
          .then(res => {
            if (res) {
              this.$emit('updatedDish')
            }
          })
          .catch(error => {
            if (error) {
              this.$error('Что-то пошло не так 77')
            }
          })

        // Сохраняем в data список шагов с измененным порядком.
        this.currentEditDish.steps = applyDrag(
          this.currentEditDish.steps,
          dropResult
        )
      }
    },

    // Меняем значение видимости dropdown.
    saveStateStepPlusDropDownVisibleChange(val) {
      this.stepPlusDropDownIsVisible = val
    },

    // Сохраняем в data Индекс текущего шага (для дропдауна создания нового шага).
    saveCurrentStepIndex(val) {
      if (val === 'start_step') {
        this.currentStepIndex = 'start_step'
      } else {
        this.currentStepIndex = val
      }
    },

    // Возвращаемся из редактирования к списку всех блюд.
    toAllDishes() {
      this.$emit('toAllDishes')
      this.clearCurrentEditData()
    },

    // Очищаем данные текущего редактируемого блюда.
    clearCurrentEditData() {
      this.imageFileList = []
      this.fileList = []
      this.currentEditDish = {
        name: '',
        description: '',
        image: null
      }
      this.currentStepIndex = ''
      this.currentEditRecipeStep = ''
      this.editSupplyForm.ingredientName = ''
      this.editSupplyForm.ingredientQuantity = 0
      this.editCookingForm.roastingTime = 0
      this.editCookingForm.rotationSpeed = 0
      this.editCookingForm.stovePower = 0
    },

    // Возвращаемся на предыдущий шаг настройки блюда.
    returnToPreviousEditDishStep() {
      // Запускаем сохранение текущего шага редактирования блюда.
      this.updateDish()
      this.activeStep = returnToPreviousStep(this.activeStep)
    },

    // Переходим к следующему шагу настройки блюда.
    goToNextEditDishStep() {
      // Запускаем сохранение текущего шага редактирования блюда.
      this.updateDish()
      this.activeStep = goToNextStep(this.activeStep, this.arrEditStepsTitles)
    },

    // Вызываем модальное окно для удаления изображения блюда.
    openModalRemoveImage() {
      this.$refs.modalRemoveImage.openModal()
    },

    // Удаляем файла изображения блюда из элемента загрузки картинки upload.
    handleRemoveImage() {
      this.imageFileList = []
      this.currentEditDish.image = null

      this.$refs.modalRemoveImage.closeModal()
    },

    // Отменяем удаление файла изображения блюда (закрываем модальное окно).
    cancelDeleteCandidateCard() {
      this.$refs.modalRemoveImage.closeModal()
    },

    // Вызываем модальное окно для удаления шага блюда.
    openModalRemoveStep() {
      this.$refs.modalRemoveStep.openModal()
    },

    // Удаляем шаг блюда.
    handleRemoveStep() {
      StepDataService.delete(this.currentEditRecipeStep.id)
        .then(res => {
          if (res) {
            this.$emit('updatedDish')
            this.currentEditRecipeStep = ''
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })

      this.$refs.modalRemoveStep.closeModal()
    },

    // Отменяем удаление шага блюда (закрываем модальное окно).
    cancelDeleteStep() {
      this.$refs.modalRemoveStep.closeModal()
    }
  }
}
</script>
