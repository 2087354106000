// Cоздает локализованные options для el-select.
// Агрументы: конфигурация локализации options с учетом текущего языка интерфейса,
// value для фильтра Тип ингредиента (array)
export function creatingLocalizedOptionsForSelect(
  localization_data_for_select,
  config_options
) {
  const options = []
  let i
  for (i = 0, config_options.length; i < config_options.length; ++i) {
    const option = localization_data_for_select.options[config_options[i]]
    options.push({
      value: config_options[i],
      label: option
    })
  }

  return options
}

// Cоздает локализованные options для el-tree-select.
// Агрументы: конфигурация локализации options с учетом текущего языка интерфейса,
// value для общего фильтра ингредиентов (array)
export function creatingLocalizedOptionsForTreeSelect(
  localization_data_for_select,
  config_options
) {
  const options = []
  let i
  for (i = 0, config_options.length; i < config_options.length; ++i) {
    const value_group_options = Object.keys(config_options[i])[0]
    const values_options = Object.values(config_options[i])[0]
    const options_group_name =
      localization_data_for_select[value_group_options].options_group_name

    let childrens = []
    let j
    for (j = 0, values_options.length; j < values_options.length; ++j) {
      childrens.push({
        value: values_options[j],
        label:
          localization_data_for_select[value_group_options].options[
            values_options[j]
          ],
        disabled: false
      })
    }

    options.push({
      value: value_group_options,
      label: options_group_name,
      children: childrens,
      disabled: false
    })
  }

  return options
}
