import { ElMessage } from 'element-plus'

export default {
  install(app) {
    app.config.globalProperties.$message = function (text, type) {
      ElMessage({
        message: text,
        type: type,
        duration: 5000
      })
    }

    app.config.globalProperties.$error = function (text) {
      ElMessage.error({
        message: text,
        duration: 5000
      })
    }

    app.config.globalProperties.$messageCommentDelete = function (
      dangerouslyUseHTMLString,
      text,
      type,
      duration,
      showClose
    ) {
      ElMessage({
        dangerouslyUseHTMLString: dangerouslyUseHTMLString,
        message: text,
        type: type,
        duration: duration,
        showClose: showClose
      })
    }
  }
}
