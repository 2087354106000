<template>
  <nav class="nav-panel-wrapper fixed">
    <div class="grid">
      <div v-if="menu !== ''" class="nav-panel-section-title">
        {{ title }}
      </div>
      <nav class="nav-panel-navigation">
        <el-anchor :offset="10" direction="horizontal">
          <el-anchor-link
            v-for="item in menu.menu_dash_menu_sections"
            :key="item.id"
            :href="`#item_${item.menu_section.id}`"
          >
            {{ capitalizeFirstLetterSectionName(item.menu_section.name) }}
          </el-anchor-link>
        </el-anchor>
      </nav>

      <div class="shopping-cart-button-wrap">
        <button
          type="button"
          data-type="primary"
          data-size="medium"
          class="shopping-cart-button shopping-cart-button-hover"
          @click="openDrawerWithCart"
        >
          Корзина

          <div
            v-if="cart.length > 0"
            class="shopping-cart-button-right-block-wrap"
          >
            <div class="shopping-cart-button-divider" />
            <div class="shopping-cart-button-number">
              {{ allDishQuantity() }}
            </div>
            <RightArrowIcon class="shopping-cart-button-arrow" />
          </div>
        </button>
      </div>
    </div>
  </nav>

  <div class="main-menu-page-wrapper">
    <main class="main-menu-page">
      <div
        v-for="item in menu.menu_dash_menu_sections"
        :key="item.id"
        class="menu-section-wrapper"
      >
        <section :id="`item_${item.menu_section.id}`" class="menu-section">
          <h2 class="menu-section-name">
            {{ capitalizeFirstLetterSectionName(item.menu_section.name) }}
          </h2>

          <div class="dish-blocks-wrapper">
            <div
              v-for="menu_section_dishes in item.menu_section
                .menu_section_dishes"
              :key="menu_section_dishes.id"
            >
              <div class="dish-block">
                <div class="main-dish-block">
                  <picture class="dish-block-picture">
                    <img
                      :alt="menu_section_dishes.dish.name"
                      :title="menu_section_dishes.dish.name"
                      :src="baseURL + menu_section_dishes.dish.image"
                    />
                  </picture>

                  <div class="dish-title">
                    {{ menu_section_dishes.dish.name }}
                  </div>

                  {{ menu_section_dishes.dish.description }}
                </div>

                <div>
                  <footer class="dish-block-footer">
                    <div class="dish-price">
                      {{ getDishPrice(menu_section_dishes.price) }} ₽
                    </div>

                    <div
                      v-if="isInCart(menu_section_dishes.id)"
                      class="number-servings-control"
                    >
                      <button
                        type="button"
                        class="number-servings-control-button"
                        @click="removeFromCart(menu_section_dishes.id)"
                      >
                        <el-icon :size="12"><MinusIcon /></el-icon>
                      </button>
                      <div class="number-servings">
                        {{ dishQuantity(menu_section_dishes.id) }}
                      </div>
                      <button
                        type="button"
                        class="number-servings-control-button"
                        @click="
                          addToCart(menu_section_dishes, item.menu_section.id)
                        "
                      >
                        <el-icon :size="12"><PlusIcon /></el-icon>
                      </button>
                    </div>

                    <button
                      v-else
                      data-type="secondary"
                      data-size="medium"
                      class="add-product-button button-style"
                      @click="
                        addToCart(menu_section_dishes, item.menu_section.id)
                      "
                    >
                      В корзину
                    </button>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>

  <!-- Блок drawer для работы с комментариями. -->
  <div class="shopping-cart-drawer-wrapper">
    <el-drawer
      v-model="drawer"
      direction="rtl"
      :size="430"
      :show-close="false"
      :lock-scroll="false"
      :modal="true"
      :destroy-on-close="true"
    >
      <template v-if="cart.length > 0" #default>
        <button class="drawer-close-icon" @click="closeDrawerWithCar">
          <el-icon :size="40" color="#ffffff">
            <CloseIcon />
          </el-icon>
        </button>

        <h1 class="cart-title">
          {{ allDishQuantityWithWordProduct() }} на {{ getSumCheck() }} ₽
        </h1>

        <article
          v-for="item in cart"
          :key="item.id"
          class="card-of-dish-in-drawer"
        >
          <button
            class="card-of-dish-in-drawer-close-button"
            @click="removeFromCart(item.id, 'allRemoveSignal')"
          >
            <el-icon><CloseIcon /></el-icon>
          </button>
          <div data-clickable="true" class="card-of-dish-in-drawer-data">
            <picture class="card-of-dish-in-drawer-picture" data-type="product">
              <img
                class="img"
                :alt="item.dish.name"
                :title="item.dish.name"
                :src="baseURL + item.dish.image"
            /></picture>
            <div class="card-of-dish-in-drawer-description">
              <h3 class="card-of-dish-in-drawer-name">
                {{ item.dish.name }}
              </h3>
              <section class="card-of-dish-in-drawer-weight">110 г</section>
            </div>
          </div>
          <div class="card-of-dish-in-drawer-footer">
            <div class="card-of-dish-in-drawer-price">
              <div class="current-price">{{ item.price }} ₽</div>
            </div>

            <div class="number-servings-control-wrapper">
              <div class="number-servings-control">
                <button
                  type="button"
                  class="number-servings-control-remove-button"
                  @click="removeFromCart(item.id)"
                >
                  <el-icon :size="12"><MinusIcon /></el-icon>
                </button>
                <div class="number-servings">{{ item.quantity }}</div>
                <button
                  type="button"
                  class="number-servings-control-add-button"
                  @click="addToCart(item, item.menu_section_id)"
                >
                  <el-icon :size="12"><PlusIcon /></el-icon>
                </button>
              </div>
            </div>
          </div>
        </article>
      </template>

      <template v-if="cart.length > 0" #footer>
        <div class="drawer-footer-wrapper">
          <!-- <div class="drawer-footer">
            <div class="price">185 ₽</div>
            <button
              data-testid="cart__button_next_sticky"
              type="button"
              data-type="primary"
              data-size="big"
              data-arrow="right"
              class="sc-qlzmyl-0 making-an-order-button next-button"
            >
              К оформлению
              <ArrowRightBoldIcon />
            </button>
          </div> -->

          <div data-mobile="false" class="drawer-footer-base">
            <section class="drawer-footer-base-total">
              <div class="subtotal">
                <div class="info">
                  {{ allDishQuantityWithWordProduct() }}
                  <span>{{ getSumCheck() }} ₽</span>
                </div>
              </div>
              <div class="info">
                Сумма заказа<span>{{ getSumCheck() }} ₽</span>
              </div>
              <button
                type="button"
                data-type="primary"
                data-size="big"
                data-arrow="right"
                class="making-an-order-button next-button"
                @click="createOrder"
              >
                К оформлению заказа
                <ArrowRightBoldIcon />
              </button>
            </section>
          </div>
        </div>
      </template>

      <div class="empty-cart-wrapper">
        <button class="drawer-close-icon" @click="closeDrawerWithCar">
          <el-icon :size="40" color="#ffffff">
            <CloseIcon />
          </el-icon>
        </button>

        <div class="empty-cart">
          <el-empty :image-size="200" description=" " />
          <div class="empty-cart-title">Ой, пусто!</div>
          <div>
            Ваша корзина пуста, откройте «Меню» <br />
            и выберите понравившийся товар
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { app } from '@/_config'
import RightArrowIcon from '@/components/icons/RightArrowIcon'
import CloseIcon from '@/components/icons/CloseIcon'
import MinusIcon from '@/components/icons/MinusIcon'
import PlusIcon from '@/components/icons/PlusIcon'
import ArrowRightBoldIcon from '@/components/icons/ArrowRightBoldIcon'
import MenuForGuestsDataService from '@/services/MenuForGuestsDataService'
import OrdersDataService from '@/services/OrdersDataService'
import { getNoun } from '@/utils/get_noun'
import { capitalizeFirstLetter } from '@/utils/capitalize_first_letter'
import clone from 'just-clone'

export default {
  components: {
    RightArrowIcon: RightArrowIcon,
    CloseIcon: CloseIcon,
    MinusIcon: MinusIcon,
    PlusIcon: PlusIcon,
    ArrowRightBoldIcon: ArrowRightBoldIcon
  },

  mounted() {
    this.getMenu()
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      menu: '',
      cart: [],
      title: app.title,
      drawer: false, // Определяет видимость блока работы с корзиной заказов (el-drawer).
      size_close_icon: 36
    }
  },

  methods: {
    capitalizeFirstLetterSectionName(section_name) {
      let sectionName = capitalizeFirstLetter(section_name)
      return sectionName
    },
    // Получаем данные текущего меню.
    getMenu() {
      const identifier = this.$route.query.gsp_id

      MenuForGuestsDataService.get(identifier)
        .then(res => {
          this.menu = res.data[0]
          this.getCart()
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Получаем данные корзины из coockie (local storage).
    getCart() {
      if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
      }

      // Проверяем, если в корзине хранится товар с ценой, которая
      // уже изменилась на сервере, то обновляем цену данного товара.
      let cartItems = JSON.parse(localStorage.getItem('cart'))
      // let cartItems = this.$store.getters.cart
      let i
      let len
      for (i = 0, len = cartItems.length; i < len; ++i) {
        let j
        let menu_dash_menu_sections = this.menu.menu_dash_menu_sections
        let len_sections = menu_dash_menu_sections.length

        for (j = 0, len_sections; j < len_sections; ++j) {
          const menu_section_dishes =
            menu_dash_menu_sections[j].menu_section.menu_section_dishes

          if (
            cartItems[i].id === menu_section_dishes[0].id &&
            cartItems[i].price !== menu_section_dishes[0].price
          ) {
            cartItems[i].price = menu_section_dishes[0].price
          }
        }
      }

      localStorage.setItem('cart', JSON.stringify(cartItems))
      this.cart = JSON.parse(localStorage.getItem('cart'))

      // this.$store.dispatch('saveCart', cartItems)
      // this.cart = this.$store.getters.cart
    },

    // Получаем стоимость блюда в меню (в т.ч. исключаем значение копеек, если оно равно "00").
    getDishPrice(dishPrice) {
      let dish_price = parseFloat(dishPrice)

      if (dish_price === Math.floor(dish_price)) {
        return dish_price
      } else {
        return dish_price.toFixed(2)
      }
    },

    // Вычисляем количество порций в корзине для каждого блюда.
    dishQuantity(menuSectionDishesId) {
      const indexDishInCart = this.cart.findIndex(
        ({ id }) => id === menuSectionDishesId
      )

      if (indexDishInCart === -1) {
        return 0
      } else {
        return this.cart[indexDishInCart].quantity
      }
    },

    // Вычисляем общее количество порций в корзине (для всех блюд).
    allDishQuantity() {
      let all_dish_quantity = 0
      this.cart.forEach(cart_item => {
        all_dish_quantity += cart_item.quantity
      })

      return all_dish_quantity
    },

    // Вычисляем общее количество порций в корзине (для всех блюд),
    // с просклоненным словом "товар".
    allDishQuantityWithWordProduct() {
      let all_dish_quantity = 0
      this.cart.forEach(cart_item => {
        all_dish_quantity += cart_item.quantity
      })
      return (
        all_dish_quantity +
        ' ' +
        getNoun(all_dish_quantity, 'товар', 'товара', 'товаров')
      )
    },

    // Получаем сумму заказов в корзине (в т.ч. исключаем значение копеек, если оно равно "00").
    getSumCheck() {
      let sum_check = 0
      this.cart.forEach(cart_item => {
        sum_check += parseFloat(cart_item.price) * 100 * cart_item.quantity
      })
      let sum = sum_check / 100

      if (sum === Math.floor(sum)) {
        return sum
      } else {
        return sum.toFixed(2)
      }
    },

    // Отслеживаем, находится ли блюдо в корзине.
    isInCart(menuSectionDishesId) {
      if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
      }

      const cartItem = this.cart.find(({ id }) => id === menuSectionDishesId)
      return Boolean(cartItem)
    },

    // Добавляем блюдо в корзину.
    addToCart(dish, menu_section_id) {
      let menuSectionDishWithQuantity = clone(dish)
      delete menuSectionDishWithQuantity.dish.steps
      delete menuSectionDishWithQuantity.dish.description

      let cartItems = JSON.parse(localStorage.getItem('cart'))
      // let cartItems = this.$store.getters.cart
      const indexDishInCart = this.cart.findIndex(({ id }) => id === dish.id)

      if (indexDishInCart === -1) {
        menuSectionDishWithQuantity.quantity = 1
        menuSectionDishWithQuantity.menu_section_id = menu_section_id
        cartItems.push(menuSectionDishWithQuantity)
      } else {
        cartItems[indexDishInCart].quantity += 1
        cartItems[indexDishInCart].menu_section_id = menu_section_id
      }

      localStorage.setItem('cart', JSON.stringify(cartItems))
      this.cart = JSON.parse(localStorage.getItem('cart'))

      // this.$store.dispatch('saveCart', cartItems)
      // this.cart = this.$store.getters.cart
    },

    // Удаляем блюдо из корзины (полностью или одну порцию,
    // в зависимости от полученного allRemoveSignal).
    removeFromCart(DishId, allRemoveSignal) {
      let cartItems = JSON.parse(localStorage.getItem('cart'))
      // let cartItems = this.$store.getters.cart
      const index = cartItems.findIndex(({ id }) => id === DishId)

      if (
        allRemoveSignal === 'allRemoveSignal' ||
        cartItems[index].quantity === 1
      ) {
        // Удаляем полностью блюдо из корзины.
        cartItems.splice(index, 1)
      } else {
        // Удаляем одну порцию блюда из корзины.
        cartItems[index].quantity -= 1
      }

      localStorage.setItem('cart', JSON.stringify(cartItems))
      this.cart = JSON.parse(localStorage.getItem('cart'))

      // this.$store.dispatch('saveCart', cartItems)
      // this.cart = this.$store.getters.cart
    },

    // Создать заказ.
    createOrder() {
      const data = {
        guest_service_point: this.$route.query.gsp_id,
        cart: this.cart
      }

      const params_data = {
        gsp_id: this.$route.query.gsp_id
      }

      OrdersDataService.create(data, params_data).then(res => {
        if (res) {
          localStorage.setItem('cart', JSON.stringify([]))
          this.cart = []
          this.closeDrawerWithCar()
        }
      })
    },

    // Открывает drawer работы с корзиной заказа.
    openDrawerWithCart() {
      this.drawer = true
    },

    // Закрыть корзину покупок (drawer).
    closeDrawerWithCar() {
      this.drawer = false
    }
  }
}
</script>
