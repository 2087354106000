import exe_axios from '@/_helpers/axios'

class DishDataService {
  getList(prepared_query_params) {
    return exe_axios.get('v1/dishes/' + prepared_query_params)
  }

  create(data) {
    return exe_axios.post('v1/dishes/', data)
  }

  update(data, id) {
    return exe_axios.patch(`v1/dishes/${id}/`, data)
  }

  getListForAddToMenu(prepared_query_params, params_data) {
    return exe_axios.get(
      'v1/dishes-for-add-to-menu/' + prepared_query_params,
      params_data
    )
  }
}

export default new DishDataService()
