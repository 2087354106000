import exe_axios from '@/_helpers/axios'

class OrdersDataService {
  get(prepared_query_params) {
    return exe_axios.get('v1/orders/' + prepared_query_params)
  }

  create(data, params_data) {
    return exe_axios({
      method: 'post',
      url: 'v1/order-create/',
      data: data,
      params: params_data,
      skipAuth: true
    })
  }
}

export default new OrdersDataService()
