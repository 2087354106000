<template>
  <div class="page-header">
    <div class="page-header-title">{{ currentActiveMenuItem }}</div>
  </div>
</template>

<script>
export default {
  props: ['currentActiveMenuItem'],

  data() {
    return {}
  }
}
</script>

<style scoped>
.page-header {
  padding: 17px 30px 0 30px;
}
.page-header-title {
  font-size: 26px;
  font-weight: 700;
}
</style>
