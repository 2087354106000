<template>
  <div class="main-block-wrapper">
    <div class="main-block">
      <!-- Основной блок поиска и фильтрации -->
      <MainSearchFilteringBlock
        :isMainSearchFilteringBlock="true"
        :selectWithoutIconPlaceholderIsActive="true"
        :selectAnIconPlaceholderIsActive="true"
        :selectWithoutIconPlaceholderWithRightmostPositionIsActive="false"
        :selectAnIconPlaceholderWithRightmostPositionIsActive="false"
        :blockViewChangeIsActive="true"
        :mainBlockInputPlaceholder="
          localizationData.dishesBlock.mainBlockInput[current_language]
            .placeholder
        "
        :mainBlockSelectWithoutIconPlaceholder="
          localizationData.dishesBlock.mainBlockSelectWithoutIcon[
            current_language
          ].placeholder
        "
        :mainBlockSelectAnIconPlaceholder="
          localizationData.dishesBlock.mainBlockSelectAnIcon[current_language]
            .placeholder
        "
        :selectWithoutIconOptions="creatingLocalizedOptionsForDishesStatus"
        :selectAnIconOptions="creatingLocalizedOptionsForDishesGeneralFilter"
        @changeDataView="changeDishesView"
        @changingFilterSearchValue="getDishes"
      />

      <!-- Отображение контента в виде таблицы -->
      <el-table
        v-if="activeDishesView === 'active_list_view'"
        :data="dishesData"
      >
        <el-table-column
          prop="name"
          :label="
            this.localizationData.dishesBlock.labelsOfDishesTable[
              this.current_language
            ][this.dishesTableItems[0]]
          "
          width="360"
          sortable
        >
          <template #default="scope">
            <div class="wpapper-custom-table-cell">
              <img
                v-if="scope.row.image !== null"
                :src="baseURL + scope.row.image"
                class="custom-table-cell-item-image"
              />
              <img
                v-else
                :src="require(`@/assets/images/no_photo.jpeg`)"
                class="custom-table-cell-item-image"
              />
              <div>
                <div class="table-row-name">
                  {{ scope.row.name }}
                </div>
                <div class="table-row-description">
                  {{ ingredientCount(scope.row.ingredient_count) }}
                  {{ getWeightOfDish(scope.row.weight_of_dish) }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="roasting_time_user_format"
          :label="
            this.localizationData.dishesBlock.labelsOfDishesTable[
              this.current_language
            ][this.dishesTableItems[1]]
          "
          width="300"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ getRoastingTimeInUserFormat(scope.row.roasting_time) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          :label="
            this.localizationData.dishesBlock.labelsOfDishesTable[
              this.current_language
            ][this.dishesTableItems[3]]
          "
          width="180"
          sortable
        >
          <template #default="scope">
            <div
              class="wpapper-custom-table-cell table-cell-justify-content-center"
            >
              <el-tag
                v-if="scope.row.initial_status === 'idea'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-2"
              >
                {{
                  localizationData.dishesBlock.dishesActitvityTags[
                    current_language
                  ].idea
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.initial_status === 'development'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-3"
              >
                {{
                  localizationData.dishesBlock.dishesActitvityTags[
                    current_language
                  ].development
                }}
              </el-tag>
              <el-tag
                v-if="scope.row.initial_status === 'ready'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-5"
              >
                {{
                  localizationData.dishesBlock.dishesActitvityTags[
                    current_language
                  ].ready
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="validity"
          :label="
            this.localizationData.dishesBlock.labelsOfDishesTable[
              this.current_language
            ][this.dishesTableItems[2]]
          "
          width="190"
          sortable
        >
          <template #default="scope">
            <div
              class="wpapper-custom-table-cell table-cell-justify-content-center"
            >
              <el-icon
                v-if="scope.row.validity === 'valid'"
                class="table-tag-icon"
                :size="26"
              >
                <CircleCheckFilled />
              </el-icon>
              <el-tag
                v-if="scope.row.validity === 'draft'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag stage-1"
              >
                {{
                  localizationData.ingredientsBlock.ingredientActitvityTags[
                    current_language
                  ].draft
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="id" align="right">
          <template #default="scope">
            <el-dropdown
              trigger="click"
              placement="bottom-end"
              @visible-change="
                dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
              "
            >
              <el-button
                class="table-more-filled-button"
                :class="{
                  active:
                    dropdownMoreFilledIsVisible === true &&
                    scope.row.id === currentDishId
                }"
                icon="MoreFilled"
                circle
                @click="writeCurrentTableRowId(scope.row.id)"
              />
              <template #dropdown>
                <el-dropdown-item @click="openEditBlock(scope.row)">
                  Редактировать
                </el-dropdown-item>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <!-- Отображение контента в виде карточек -->
      <div
        v-if="activeDishesView === 'active_blocks_view'"
        class="wrapper-box-card"
      >
        <el-card v-for="dish in dishesData" :key="dish.id" class="box-card">
          <div
            v-if="dish.image !== null"
            class="box-card-with-background-fill"
            :style="{
              backgroundImage: 'url(' + baseURL + dish.image + ')'
            }"
          >
            <div class="box-card-with-background-fill-mask">
              <div class="box-card-with-background-fill-title">
                {{ dish.name }}
              </div>
              <div class="box-card-with-background-fill-tag-wrapper">
                <div class="box-card-with-background-fill-tag-block">
                  <el-tag
                    v-if="dish.initial_status === 'idea'"
                    effect="dark"
                    round
                    disable-transitions
                    class="base-size-tag stage-2"
                  >
                    {{
                      localizationData.dishesBlock.dishesActitvityTags[
                        current_language
                      ].idea
                    }}
                  </el-tag>
                  <el-tag
                    v-if="dish.initial_status === 'development'"
                    effect="dark"
                    round
                    disable-transitions
                    class="base-size-tag stage-3"
                  >
                    {{
                      localizationData.dishesBlock.dishesActitvityTags[
                        current_language
                      ].development
                    }}
                  </el-tag>
                  <el-tag
                    v-if="dish.initial_status === 'ready'"
                    effect="dark"
                    round
                    disable-transitions
                    class="base-size-tag stage-5"
                  >
                    {{
                      localizationData.dishesBlock.dishesActitvityTags[
                        current_language
                      ].ready
                    }}
                  </el-tag>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="box-card-with-background-fill"
            :style="{
              backgroundImage:
                'url(' + require(`@/assets/images/no_photo.jpeg`) + ')'
            }"
          >
            <div class="box-card-with-background-fill-mask">
              <div class="box-card-with-background-fill-title">
                {{ dish.name }}
              </div>
              <div class="box-card-with-background-fill-tag-wrapper">
                <div class="box-card-with-background-fill-tag-block">
                  <el-tag
                    v-if="dish.initial_status === 'idea'"
                    effect="dark"
                    round
                    disable-transitions
                    class="base-size-tag stage-2"
                  >
                    {{
                      localizationData.dishesBlock.dishesActitvityTags[
                        current_language
                      ].idea
                    }}
                  </el-tag>
                  <el-tag
                    v-if="dish.initial_status === 'development'"
                    effect="dark"
                    round
                    disable-transitions
                    class="base-size-tag stage-3"
                  >
                    {{
                      localizationData.dishesBlock.dishesActitvityTags[
                        current_language
                      ].development
                    }}
                  </el-tag>
                  <el-tag
                    v-if="dish.initial_status === 'ready'"
                    effect="dark"
                    round
                    disable-transitions
                    class="base-size-tag stage-5"
                  >
                    {{
                      localizationData.dishesBlock.dishesActitvityTags[
                        current_language
                      ].ready
                    }}
                  </el-tag>
                </div>
              </div>
            </div>
          </div>

          <div class="box-card-header-button">
            <el-dropdown
              trigger="click"
              placement="bottom"
              @visible-change="
                dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
              "
            >
              <el-button
                class="card-more-filled-button"
                :class="{
                  active:
                    dropdownMoreFilledIsVisible === true &&
                    dish.id === currentDishId
                }"
                circle
                @click="writeCurrentTableRowId(dish.id)"
              >
                <el-icon :size="18">
                  <MoreFilled />
                </el-icon>
              </el-button>

              <template #dropdown>
                <el-dropdown-item @click="openEditBlock(dish)">
                  Редактировать
                </el-dropdown-item>
              </template>
            </el-dropdown>
          </div>
        </el-card>
      </div>

      <!-- Блок пагинации -->
      <PaginationBlock
        :total="dishesCount"
        @changeCurrentPaginationPage="getDishes"
        :key="keyPaginationBlock"
      />
    </div>
  </div>
</template>

<script>
import {
  nameActiveTheme,
  dishesTableItems,
  fieldsOfDishForLocalization,
  valueOfOptionsForDishesStatus,
  valueOfOptionsForDishesGeneralFilter
} from '@/_config'
import {
  creatingLocalizedOptionsForSelect,
  creatingLocalizedOptionsForTreeSelect
} from '@/utils/creating_localized_options_for_selects'
import { creatingLocalizedDataFields } from '@/utils/creating_localized_data_fields'
import { preparedGeneralFilterEndpoint } from '@/utils/prepared_endpoints'
import DishDataService from '@/services/DishDataService'
import MainSearchFilteringBlock from '@/components/MainSearchFilteringBlock'
import PaginationBlock from '@/components/PaginationBlock'
import { getNoun } from '@/utils/get_noun'
import { convertingSecondsToTimeInCustomFormat } from '@/utils/converting_seconds_to_time_in_custom_format'

export default {
  props: [
    'current_language',
    'localizationData',
    'defaultActiveTab',
    'signalAboutCreationOfDish',
    'signalAboutUpdatedOfDish'
  ],

  emits: ['openEditBlock', 'afterGetDishesData'],

  components: {
    MainSearchFilteringBlock: MainSearchFilteringBlock,
    PaginationBlock: PaginationBlock
  },

  computed: {
    // Получаем options для фильтра Статус блюд.
    creatingLocalizedOptionsForDishesStatus() {
      const localization_data_for_select =
        this.localizationData.dishesBlock.mainBlockSelectWithoutIcon[
          this.current_language
        ]
      const config_options = this.valueOfOptionsForDishesStatus
      const options = creatingLocalizedOptionsForSelect(
        localization_data_for_select,
        config_options
      )
      return options
    },

    // Получаем options для общего фильтра блюд.
    creatingLocalizedOptionsForDishesGeneralFilter() {
      const localization_data_for_select =
        this.localizationData.dishesBlock.mainBlockSelectAnIcon[
          this.current_language
        ]
      const config_options = this.valueOfOptionsForDishesGeneralFilter
      const options = creatingLocalizedOptionsForTreeSelect(
        localization_data_for_select,
        config_options
      )
      return options
    }
  },

  watch: {
    // Если активен таб блюд, получаем список блюд.
    defaultActiveTab() {
      if (this.defaultActiveTab === 'dishes') {
        this.getDishes()
      }
    },
    // Если получен сигнал о создании блюда, получаем обновленный список блюд.
    signalAboutCreationOfDish() {
      this.getDishes()
    },
    // Если получен сигнал о изменении блюда, получаем обновленный список блюд.
    signalAboutUpdatedOfDish() {
      this.getDishes()
    },
    // Отслеживаем какое-либо изменение в dishesData.
    dishesData: {
      handler: function () {
        this.$emit('afterGetDishesData', this.dishesData)
      },
      deep: true
    }
  },

  created() {
    if (this.defaultActiveTab === 'dishes') {
      this.getDishes()
    }
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      activeDishesView: 'active_list_view',
      nameActiveTheme: nameActiveTheme,
      dishesTableItems: dishesTableItems,
      fieldsOfDishForLocalization: fieldsOfDishForLocalization,
      valueOfOptionsForDishesStatus: valueOfOptionsForDishesStatus,
      valueOfOptionsForDishesGeneralFilter:
        valueOfOptionsForDishesGeneralFilter,
      searchTableInput: '',
      selectWithoutIconValue: '',
      finalGeneralFilterEndpoint: '',
      dishesData: [],
      dishesCount: 0,
      currentPage: 1,
      keyPaginationBlock: 0,
      dropdownMoreFilledIsVisible: false,
      currentDishId: ''
    }
  },

  methods: {
    // Запишем в data id текущего блюда.
    writeCurrentTableRowId(currentDishId) {
      this.currentDishId = currentDishId
    },

    // Изменяет вид отображения блюд.
    changeDishesView(activeDataView) {
      this.activeDishesView = activeDataView
      this.dropdownMoreFilledIsVisible = false
    },

    // Получаем список блюд.
    getDishes(data) {
      let prepared_query_params

      if (data && data.signal === 'changeCurrentPaginationPage') {
        this.currentPage = data.updatePage
      }

      if (data && data.signal === 'changeFilterSearchValue') {
        // Если в метод переданы параметры общего фильтра.
        // Сбрасываем текущую страницу пагинации на 1.
        this.currentPage = 1

        // Сохраняем текущие параметры поиска и фильтра Статус в data.
        this.searchTableInput = data.searchTableInput.toLowerCase()
        this.selectWithoutIconValue = data.selectWithoutIconValue

        // Подготавливаем query params для общего фильтра.
        const config_options = this.valueOfOptionsForDishesGeneralFilter
        const final_general_filter_endpoint = preparedGeneralFilterEndpoint(
          data,
          config_options
        )
        this.finalGeneralFilterEndpoint = final_general_filter_endpoint
      }

      // Формируем итоговый query params для страницы пагинации,
      // поиска, фильтра Статус, общего фильтра.
      prepared_query_params =
        '?page=' +
        this.currentPage +
        '&search=' +
        this.searchTableInput +
        '&status=' +
        this.selectWithoutIconValue +
        this.finalGeneralFilterEndpoint

      DishDataService.getList(prepared_query_params)
        .then(res => {
          if (res) {
            this.dishesData = creatingLocalizedDataFields(
              res.data.results,
              fieldsOfDishForLocalization,
              this.localizationData.dishesBlock.fieldsOfDish,
              this.current_language,
              ['status']
            )
            this.dishesCount = res.data.count

            if (data && data.signal === 'changeFilterSearchValue') {
              this.forcePaginationBlock()
            }
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Получаем дескриптор к названию блюда.
    ingredientCount(ingredient_count) {
      if (ingredient_count > 0) {
        const ingredient_word = getNoun(
          ingredient_count,
          this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
            this.current_language
          ].initial_ingredient_word.one,
          this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
            this.current_language
          ].initial_ingredient_word.two,
          this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
            this.current_language
          ].initial_ingredient_word.five
        )
        return `${ingredient_count} ${ingredient_word}`
      } else {
        return this.localizationData.dishesBlock.descriptionOfIngredientsAmount[
          this.current_language
        ].if_ingredients_are_not_selected
      }
    },

    // Получаем вес блюда.
    getWeightOfDish(weight_of_dish) {
      if (weight_of_dish !== null) {
        return '| ' + weight_of_dish + ' г'
      }
    },

    // Получаем время приготовления блюда в пользовательском формате.
    getRoastingTimeInUserFormat(roasting_time) {
      const roastingTimeInUserFormat =
        convertingSecondsToTimeInCustomFormat(roasting_time)
      return roastingTimeInUserFormat
    },

    // Открываем блок редактирования блюда.
    openEditBlock(dish_object) {
      this.$emit('openEditBlock', dish_object)
    },

    // Делаем перерендеринг блока пагинации.
    forcePaginationBlock() {
      this.keyPaginationBlock += 1
    }
  }
}
</script>
