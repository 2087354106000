export default {
  // [Страница входа] Сообщение при попытке попасть неавторизованным на страницу, где требуется авторизация.
  login: {
    message: 'Укажите email и пароль для входа в систему',
    type: 'warning'
  },

  // [Страница входа] Сообщение при вводе неверных email и/или пароля.
  no_active_account_found_with_the_given_credentials: {
    message: 'Проверьте правильность введенных email и пароля'
  },

  //Сообщение при выходе из системы.
  logout: {
    message: 'Вы вышли из системы',
    type: 'warning'
  },

  // При загрузке изображения с размером выше 5Mb.
  size_of_uploaded_image_should_not_exceed_5_mb: {
    message: 'Размер загружаемого изображения не должен превышать 5 мегабайт',
    type: 'error'
  }
}
