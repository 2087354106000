<template>
  <div class="main-block-wrapper">
    <div class="main-block">
      <!-- Основной блок поиска и фильтрации -->
      <MainSearchFilteringBlock
        :isMainSearchFilteringBlock="true"
        :selectWithoutIconPlaceholderIsActive="true"
        :selectAnIconPlaceholderIsActive="true"
        :selectWithoutIconPlaceholderWithRightmostPositionIsActive="false"
        :selectAnIconPlaceholderWithRightmostPositionIsActive="false"
        :blockViewChangeIsActive="true"
        :mainBlockInputPlaceholder="
          localizationData.ingredientsBlock.mainBlockInput[current_language]
            .placeholder
        "
        :mainBlockSelectWithoutIconPlaceholder="
          localizationData.ingredientsBlock.mainBlockSelectWithoutIcon[
            current_language
          ].placeholder
        "
        :mainBlockSelectAnIconPlaceholder="
          localizationData.ingredientsBlock.mainBlockSelectAnIcon[
            current_language
          ].placeholder
        "
        :selectWithoutIconOptions="creatingLocalizedOptionsForIngredientTypes"
        :selectAnIconOptions="
          creatingLocalizedOptionsForIngredientsGeneralFilter
        "
        @changeDataView="changeIngridientsView"
        @changingFilterSearchValue="getIngredients"
      />

      <!-- Отображение контента в виде таблицы -->
      <el-table
        v-if="activeIngridientsView === 'active_list_view'"
        :data="ingredientsData"
      >
        <el-table-column
          prop="name"
          :label="
            this.localizationData.ingredientsBlock.labelsOfIngredientsTable[
              this.current_language
            ][this.ingredientsTableItems[0]]
          "
          width="360"
          sortable
        >
          <template #default="scope">
            <div class="wpapper-custom-table-cell">
              <img
                v-if="scope.row.image !== null"
                :src="baseURL + scope.row.image"
                class="custom-table-cell-item-image"
              />
              <img
                v-else
                :src="require(`@/assets/images/no_photo.jpeg`)"
                class="custom-table-cell-item-image"
              />

              <div>
                <div class="table-row-name">
                  {{ scope.row.name }}
                </div>
                <div class="table-row-description">
                  {{ dishCount(scope.row.dishes_count) }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="measurement_unit"
          :label="
            this.localizationData.ingredientsBlock.labelsOfIngredientsTable[
              this.current_language
            ][this.ingredientsTableItems[1]]
          "
          width="150"
          align="center"
          sortable
        />

        <el-table-column
          prop="form"
          :label="
            this.localizationData.ingredientsBlock.labelsOfIngredientsTable[
              this.current_language
            ][this.ingredientsTableItems[2]]
          "
          width="180"
          sortable
        />

        <el-table-column
          prop="state"
          :label="
            this.localizationData.ingredientsBlock.labelsOfIngredientsTable[
              this.current_language
            ][this.ingredientsTableItems[3]]
          "
          width="180"
          sortable
        />

        <el-table-column
          prop="validity"
          :label="
            this.localizationData.ingredientsBlock.labelsOfIngredientsTable[
              this.current_language
            ][this.ingredientsTableItems[4]]
          "
          width="180"
          sortable
        >
          <template #default="scope">
            <div
              class="wpapper-custom-table-cell table-cell-justify-content-center"
            >
              <el-icon
                v-if="scope.row.validity === 'valid'"
                class="table-tag-icon"
                :size="26"
              >
                <CircleCheckFilled />
              </el-icon>
              <el-tag
                v-if="scope.row.validity === 'draft'"
                effect="dark"
                round
                disable-transitions
                class="base-size-tag base-size-tag-shadow stage-1"
              >
                {{
                  localizationData.ingredientsBlock.ingredientActitvityTags[
                    current_language
                  ].draft
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="right">
          <template #default="scope">
            <el-dropdown
              trigger="click"
              placement="bottom-end"
              @visible-change="
                dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
              "
            >
              <el-button
                class="table-more-filled-button"
                :class="{
                  active:
                    dropdownMoreFilledIsVisible === true &&
                    scope.row.id === currentIngredientId
                }"
                icon="MoreFilled"
                circle
                @click="writeCurrentTableRowId(scope.row.id)"
              />
              <template #dropdown>
                <el-dropdown-item @click="openEditBlock(scope.row)">
                  Редактировать
                </el-dropdown-item>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <!-- Отображение контента в виде карточек -->
      <div
        v-if="activeIngridientsView === 'active_blocks_view'"
        class="wrapper-box-card"
      >
        <el-card
          v-for="ingredient in ingredientsData"
          :key="ingredient.id"
          class="box-card"
        >
          <div
            v-if="ingredient.image !== null"
            class="box-card-header"
            :style="{
              backgroundImage: 'url(' + baseURL + ingredient.image + ')'
            }"
          >
            <div class="box-card-header-mask">
              <div class="box-card-header-button">
                <el-dropdown
                  trigger="click"
                  placement="bottom"
                  @visible-change="
                    dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
                  "
                >
                  <el-button
                    class="card-more-filled-button"
                    :class="{
                      active:
                        dropdownMoreFilledIsVisible === true &&
                        ingredient.id === currentIngredientId
                    }"
                    circle
                    @click="writeCurrentTableRowId(ingredient.id)"
                  >
                    <el-icon :size="18">
                      <MoreFilled />
                    </el-icon>
                  </el-button>

                  <template #dropdown>
                    <el-dropdown-item @click="openEditBlock(ingredient)">
                      Редактировать
                    </el-dropdown-item>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>
          <div
            v-else
            class="box-card-header"
            :style="{
              backgroundImage:
                'url(' + require(`@/assets/images/no_photo.jpeg`) + ')'
            }"
          >
            <div class="box-card-header-mask">
              <div class="box-card-header-button">
                <el-dropdown
                  trigger="click"
                  placement="bottom"
                  @visible-change="
                    dropdownMoreFilledIsVisible = !dropdownMoreFilledIsVisible
                  "
                >
                  <el-button
                    class="card-more-filled-button"
                    :class="{
                      active:
                        dropdownMoreFilledIsVisible === true &&
                        ingredient.id === currentIngredientId
                    }"
                    circle
                    @click="writeCurrentTableRowId(ingredient.id)"
                  >
                    <el-icon :size="18">
                      <MoreFilled />
                    </el-icon>
                  </el-button>

                  <template #dropdown>
                    <el-dropdown-item @click="openEditBlock(ingredient)">
                      Редактировать
                    </el-dropdown-item>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>

          <div class="box-card-body">
            <div class="box-card-body-title">
              {{ ingredient.name }}
            </div>
            <el-icon
              v-if="ingredient.validity === 'valid'"
              class="card-tag-or-icon"
              :size="26"
            >
              <CircleCheckFilled />
            </el-icon>
            <el-tag
              v-if="ingredient.validity === 'draft'"
              effect="dark"
              round
              disable-transitions
              class="base-size-tag base-size-tag-shadow stage-1"
            >
              {{
                localizationData.ingredientsBlock.ingredientActitvityTags[
                  current_language
                ].draft
              }}
            </el-tag>
          </div>
        </el-card>
      </div>

      <!-- Блок пагинации -->
      <PaginationBlock
        :total="ingredientsCount"
        @changeCurrentPaginationPage="getIngredients"
        :key="keyPaginationBlock"
      />
    </div>
  </div>
</template>

<script>
import {
  nameActiveTheme,
  ingredientsTableItems,
  fieldsOfIngredientForLocalization,
  valueOfOptionsForIngredientTypes,
  valueOfOptionsForIngredientsGeneralFilter
} from '@/_config'
import {
  creatingLocalizedOptionsForSelect,
  creatingLocalizedOptionsForTreeSelect
} from '@/utils/creating_localized_options_for_selects'
import { creatingLocalizedDataFields } from '@/utils/creating_localized_data_fields'
import { preparedGeneralFilterEndpoint } from '@/utils/prepared_endpoints'
import IngredientDataService from '@/services/IngredientDataService'
import MainSearchFilteringBlock from '@/components/MainSearchFilteringBlock'
import PaginationBlock from '@/components/PaginationBlock'
import { getNoun } from '@/utils/get_noun'

export default {
  props: ['current_language', 'localizationData', 'defaultActiveTab'],

  components: {
    MainSearchFilteringBlock: MainSearchFilteringBlock,
    PaginationBlock: PaginationBlock
  },

  computed: {
    // Получаем options для фильтра Типы ингредиентов.
    creatingLocalizedOptionsForIngredientTypes() {
      const localization_data_for_select =
        this.localizationData.ingredientsBlock.mainBlockSelectWithoutIcon[
          this.current_language
        ]
      const config_options = this.valueOfOptionsForIngredientTypes
      const options = creatingLocalizedOptionsForSelect(
        localization_data_for_select,
        config_options
      )
      return options
    },

    // Получаем options для общего фильтра ингредиентов.
    creatingLocalizedOptionsForIngredientsGeneralFilter() {
      const localization_data_for_select =
        this.localizationData.ingredientsBlock.mainBlockSelectAnIcon[
          this.current_language
        ]
      const config_options = this.valueOfOptionsForIngredientsGeneralFilter
      const options = creatingLocalizedOptionsForTreeSelect(
        localization_data_for_select,
        config_options
      )
      return options
    }
  },

  watch: {
    defaultActiveTab() {
      if (this.defaultActiveTab === 'ingredients') {
        this.getIngredients()
      }
    }
  },

  created() {
    if (this.defaultActiveTab === 'ingredients') {
      this.getIngredients()
    }
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      activeIngridientsView: 'active_list_view',
      nameActiveTheme: nameActiveTheme,
      ingredientsTableItems: ingredientsTableItems,
      fieldsOfIngredientForLocalization: fieldsOfIngredientForLocalization,
      valueOfOptionsForIngredientTypes: valueOfOptionsForIngredientTypes,
      valueOfOptionsForIngredientsGeneralFilter:
        valueOfOptionsForIngredientsGeneralFilter,
      searchTableInput: '',
      selectWithoutIconValue: '',
      finalGeneralFilterEndpoint: '',
      ingredientsData: [],
      ingredientsCount: 0,
      currentPage: 1,
      keyPaginationBlock: 0,
      dropdownMoreFilledIsVisible: false,
      currentIngredientId: ''
    }
  },

  methods: {
    // Запишем в data id текущего ингредиента.
    writeCurrentTableRowId(currentIngredientId) {
      this.currentIngredientId = currentIngredientId
    },

    // Изменяет вид отображения ингредиентов.
    changeIngridientsView(activeDataView) {
      this.activeIngridientsView = activeDataView
      this.dropdownMoreFilledIsVisible = false
    },

    // Получаем список ингредиентов.
    getIngredients(data) {
      let prepared_query_params

      if (data && data.signal === 'changeCurrentPaginationPage') {
        this.currentPage = data.updatePage
      }

      if (data && data.signal === 'changeFilterSearchValue') {
        // Если в метод переданы параметры общего фильтра.
        // Сбрасываем текущую страницу пагинации на 1.
        this.currentPage = 1

        // Сохраняем текущие параметры поиска и фильтра Тип в data.
        this.searchTableInput = data.searchTableInput.toLowerCase()
        this.selectWithoutIconValue = data.selectWithoutIconValue

        // Подготавливаем query params для общего фильтра.
        const config_options = this.valueOfOptionsForIngredientsGeneralFilter
        const final_general_filter_endpoint = preparedGeneralFilterEndpoint(
          data,
          config_options
        )
        this.finalGeneralFilterEndpoint = final_general_filter_endpoint
      }

      // Формируем итоговый endpoint с учетом query params для страницы пагинации,
      // поиска, фильтра Тип, общего фильтра.
      prepared_query_params =
        '?page=' +
        this.currentPage +
        '&search=' +
        this.searchTableInput +
        '&type=' +
        this.selectWithoutIconValue +
        this.finalGeneralFilterEndpoint

      IngredientDataService.getList(prepared_query_params)
        .then(res => {
          if (res) {
            this.ingredientsData = creatingLocalizedDataFields(
              res.data.results,
              fieldsOfIngredientForLocalization,
              this.localizationData.ingredientsBlock.fieldsOfIngredient,
              this.current_language
            )
            this.ingredientsCount = res.data.count

            if (data && data.signal === 'changeFilterSearchValue') {
              this.forcePaginationBlock()
            }
          }
        })
        .catch(error => {
          if (error) {
            this.$error('Что-то пошло не так')
          }
        })
    },

    // Получаем дескриптор к названию ингредиента.
    dishCount(dishes_count) {
      if (dishes_count > 0) {
        const dish_word = getNoun(
          dishes_count,
          this.localizationData.ingredientsBlock
            .descriptionOfHowManyDishesAreUsed[this.current_language]
            .initial_dish_word.one,
          this.localizationData.ingredientsBlock
            .descriptionOfHowManyDishesAreUsed[this.current_language]
            .initial_dish_word.two,
          this.localizationData.ingredientsBlock
            .descriptionOfHowManyDishesAreUsed[this.current_language]
            .initial_dish_word.five
        )
        return `${
          this.localizationData.ingredientsBlock
            .descriptionOfHowManyDishesAreUsed[this.current_language]
            .description_path_one
        } ${dishes_count} ${dish_word}`
      } else {
        return this.localizationData.ingredientsBlock
          .descriptionOfHowManyDishesAreUsed[this.current_language].not_used
      }
    },

    // Делаем перерендеринг блока пагинации.
    forcePaginationBlock() {
      this.keyPaginationBlock += 1
    }
  }
}
</script>
