import exe_axios from '@/_helpers/axios'

class MenusDataService {
  get(prepared_query_params) {
    return exe_axios.get('v1/menus/' + prepared_query_params)
  }

  createMenu(data) {
    return exe_axios.post('v1/menus/', data)
  }

  update(data, menu_id) {
    return exe_axios.patch(`v1/menus/${menu_id}/`, data)
  }

  async getListForMenuEdit(data) {
    const response = await exe_axios.get('v1/menu-sections/', {
      params: data
    })
    return response.data
  }

  updateSection(data, menu_section_id) {
    return exe_axios.patch(`v1/menu-sections/${menu_section_id}/`, data)
  }

  createSection(data) {
    return exe_axios.post('v1/menu-dash-menu-sections/', data)
  }

  updateMenuDashSection(data, menu_dash_menu_section_id, params_data) {
    return exe_axios.patch(
      `v1/menu-dash-menu-sections/${menu_dash_menu_section_id}/`,
      data,
      params_data
    )
  }

  deleteSection(id) {
    return exe_axios.delete(`v1/menu-dash-menu-sections/${id}/`)
  }

  createMenuSectionDish(data) {
    return exe_axios.post('v1/menu-section-dish/', data)
  }

  updateMenuSectionDish(data, menu_section_dish_id, params_data) {
    return exe_axios.patch(
      `v1/menu-section-dish/${menu_section_dish_id}/`,
      data,
      params_data
    )
  }

  deleteMenuSectionDish(id) {
    return exe_axios.delete(`v1/menu-section-dish/${id}/`)
  }
}

export default new MenusDataService()
